.dropdown-input {
    position: relative;
    display: inline-block;
    width: 100%;

    input {
        width: 100%;
    }

    button.add {
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        font-size: 1rem;
        color: #212529;
    }

    button.show {
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        font-size: 1rem;
        color: #212529;
    }

    .dropdown {
        opacity: 0;
        position: absolute;
        // top: 100%;
        top: -10000000px;
        // left: 0;
        left: -10000000px;
        width: 100%;
        min-width: 150px;
        background-color: #fff;
        border: 1px solid #e9e8ed;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 3px;

        // display: none;
        z-index: -1;
        transition: opacity 272ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 181ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        padding: 0;

        &.show {
            // display: block;
            opacity: 1;
            pointer-events: all;
            z-index: 1001;
            top: 100%;
            left: 0;
        }

        .all-options {
            // padding: 10px 10px;
            // margin-bottom: 10px;
            background: #f0f0f0;
            border-top: 1px solid #e9e8ed;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 3px 5px;

            button {
                // margin-right: 3px;
                // border-radius: 0;
                // padding: 0.15rem;
                padding: 0;
                font-size: 0.8rem;
                font-weight: 500;
                color: #000;
                text-decoration: none;

                &.btn-secondary {
                    // background-color: #eee;
                    // border: none;
                    // color: #212529;
                    // &:hover {
                    //     background-color: #bbb;
                    // }
                }
            }
        }

        ul {
            width: 100%;
            margin: 0;
            padding: 0;
            max-height: 300px;
            overflow-y: auto;

            li {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                padding: 8px 10px !important;
                font-size: 0.9rem;
                border-bottom: 1px solid #e9e8ed;
                color: #2c404a;
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                    opacity: 0;
                    fill: #0088ff;
                    width: 16px;
                    height: 16px;
                    font-size: 1.5rem;
                    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    flex-shrink: 0;
                    user-select: none;
                    // width: 24px;
                    margin-right: 6px;
                    pointer-events: none;
                    z-index: 0;
                    display: none;
                }

                svg.not-selected {
                    display: inline-block;
                    opacity: 1;
                }
            }

            li:last-of-type {
                border-bottom: none;
            }

            li:hover,
            li.active {
                // background: rgba(0, 0, 0, 0.08);
                background: rgba(0, 0, 0, 0.2);
            }

            li.active {
                svg.selected {
                    display: inline-block;
                    opacity: 1;
                }

                svg.not-selected {
                    display: none;
                    opacity: 0;
                }
            }

            li.focus {
                // border: 1px solid #5f50a4 !important;
                background: rgba(0, 0, 0, 0.08);
            }
        }


        &::-webkit-scrollbar-track {
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
        }
    }

    .multiple-selected-label {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 10px;
        padding: 0px 7px;
        font-weight: 600;
        border-radius: 12px;
        color: #fff;
        font-size: 0.6rem;
        box-shadow: 0 0 5px #f0f0f0;
        background: #0088ff;
        display: inline-block;
        white-space: nowrap;
    }
}