#accounts {
  margin: -25px;
  padding: 10px 0;
  margin-top: 0;
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  .row .panel,
  .panel {
    margin-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    table {
      tr {
        th,
        td {
          // font-size: 13px;
        }

        th:nth-child(1),
        td:nth-child(1) {
          width: 200px;
          min-width: 200px;
        }
      }
    }
  }

  .chart {
    height: 380px;
    display: flex;
    flex-direction: column;

    .head {
      display: flex;
      align-items: flex-start;

      .title {
        color: #4a4a55;
        font-weight: 600;
      }

      .subtitle {
        color: #888888;
      }

      .dates {
        margin-left: auto;
        display: flex;

        .btn {
          background: #c7e5ff;
          color: #0e61dd;
          font-weight: 600;
          font-size: 14px;
          margin-left: 10px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          position: relative;
          .col {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            .period-new {
              padding-left: 0 !important;
              width: 100% !important;
              height: 100% !important;
              position: absolute;
              left: 0;
              top: 0;
              background: initial;
              z-index: 91;
              .option {
                opacity: 0;
              }
              .period-options {
                left: auto;
                right: 0;
              }
            }
          }
          img {
            width: 16px;
          }

          &.active,
          &:hover {
            background: #0e61dd;
            color: #fff;
            >img{
              filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(261deg) brightness(105%) contrast(102%);
            }
          }
        }
      }
    }

    .legend {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;

      .item {
        margin-left: 30px;

        .label {
          display: flex;
          align-items: center;

          .color {
            width: 10px;
            height: 10px;
            display: inline-block;
            background: #eee;
            border-radius: 50%;
            margin-right: 5px;
          }

          .text {
            color: #d5dadf;
            font-size: 14px;
          }
        }

        .value {
          text-align: right;
        }
      }
    }

    .filter {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;

      .category {
        width: 250px !important;
      }
    }

    .graph {
      position: relative;
      margin-top: auto;
      margin-bottom: 20px;

      height: 250px;

      .cancel {
        padding: 3px 5px;
        height: auto;
        position: absolute;
        top: -3px;
        z-index: 1;

        .arrow {
          color: #42424e !important;
          font-size: 10px !important;
        }
      }
    }
  }

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/img/accounts/no-data-bg.jpg");;
    background-size: cover;
    background-position: bottom center;
    padding-bottom: 230px;
    margin: 0 -20px;
    margin-bottom: -30px;
    width: calc(100% + 40px);
    flex: 1;
    img {
      display: block;
      margin: 0 auto;
      height: 170px;
    }
    b {
      color: #42424e;
      font-size: 34px;
      display: block;
      text-align: center;
      margin-bottom: 5px;
      font-weight: normal;
    }
    p {
      text-align: center;
      color: #42424e;
      font-size: 18px;
      margin-bottom: 25px;
    }
    button {
      display: block;
      margin: 0 auto;
    }
  }
}
