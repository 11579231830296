$primaryHeadingColor: #42424e;
$primaryParagraphColor: #777777;
$primaryParagraphFontSize: 15px;
$primaryFontFamily: "SourceSansPro SemiBold", sans-serif;

.Mui-disabled:not(.simple-select) {
  pointer-events: all !important;
  opacity: 1 !important;
}

.related-number-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  span {
    color: #1fc66c;
    margin-left: 5px;
  }
}

.new-accounting {
  p {
    margin-bottom: 2px;
    color: #b4bfca;
    font-size: 11px;
  }
  b {
    display: flex;
    align-items: center;
    color: #3bc870;
    font-size: 14px;
    font-weight: normal;
    img {
      margin-right: 5px;
    }
  }
}

.repeat-options {
  max-width: 1200px;
  .body {
    padding-top: 0 !important;
    display: flex;
    position: relative;
    .head {
      padding-left: 0;
    }
    .close-icon {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 2;
      cursor: pointer;
    }
    .left {
      width: 370px;
      flex-shrink: 0;
    }
    .right {
      width: 100%;
      padding-left: 15px;
      margin-left: 15px;
      border-left: 1px solid #dde6ed;
      flex: 1;
      margin-top: 15px;
      .email-input-wrapper {
        .email-input {
          .input {
            input{
              display: block;
            }
          }
        }
      }
      .head {
        padding-top: 5px;
      }
      .ck.ck-editor{
        border: 1px solid #dde6ed;
        border-radius: 3px;
      }
    }
    .MuiFormControlLabel-root {
      b,
      p {
        font-family: "SourceSansPro SemiBold", sans-serif;
      }
      span {
        line-height: 1.2;
        b {
          color: #0e61dd;
          font-size: 16px;
        }
        p {
          color: #42424e;
          font-size: 14px;
        }
      }
    }
  }
  &.small {
    .body {
      .left {
        width: 100%;
      }
    }
  }
}

.accounting-list-holder:not(.new-accounting) {
  display: flex;
  align-items: center;
  > img {
    flex-shrink: 0;
    margin-right: 5px;
    max-width: 23px;
  }
  p {
    color: #42424e;
    font-size: 14px;
  }
  .right {
    span {
      margin-bottom: 2px;
      color: #b4bfca;
      font-size: 11px;
    }
  }
}

.create-documents-menu {
  ul {
    padding: 0;
    li {
      display: flex;
      align-items: center;
      padding-left: 5px;
      padding-right: 10px;
      border-bottom: 1px solid #e6eef4;
      span.image {
        background-image: url("../../img/documents/icons/document.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 56px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: #d6dce8;
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 5px;
        transition: 0.2s;
        &::before {
          content: "";
          width: 16px;
          height: 19px;
          background-image: url("../../img/documents/icons/document-hover.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          left: calc(50% - 14px / 2);
          bottom: -1px;
          transform: scale(0);
          transition: inherit;
        }
      }
      &:hover {
        background-color: #fff;
        color: #38bd6a;
        span {
          color: inherit;
          &::before {
            transform: scale(1);
          }
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.doc {
  overflow-x: auto;
}

.popover-payment {
  .MuiPopover-paper {
    border: 1px solid #dedfe1;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    min-width: 250px;
    ul {
      padding: 10px;
      li {
        cursor: pointer;
        padding: 10px;
        border-radius: 3px;
        border: 1px solid #dde7f2;
        background-color: #f8f8fb;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        img {
          max-height: 31px;
          max-width: 120px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.popover-preview {
  border: 1px solid #dedfe1;
  border-radius: 5px;
  background-color: #fff;
  width: 300px;
  min-height: 400px;
  padding: 15px;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 9;
  p {
    font-size: 15px;
    color: $primaryParagraphColor;
    text-align: left;
    line-height: 1.2;
  }
  &::after {
    content: "";
    width: 100%;
    height: 11px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
}

.popover-info-holder {
  .row {
    margin-bottom: 0;
    .col {
      flex: initial;
      font-size: 11px;
      &:nth-child(1) {
        width: 35px;
      }
      &:nth-child(2) {
        font-family: "SourceSansPro SemiBold", sans-serif;
      }
    }
  }
}

.popover-bank-details {
  .MuiPopover-paper {
    width: 370px;
    border: 1px solid #dedfe1;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    ul {
      max-height: 260px;
      overflow: auto;
      margin-bottom: 10px;
      li {
        cursor: pointer;
        padding: 10px;
        border-radius: 3px;
        border: 1px solid #dde7f2;
        background-color: #f8f8fb;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .row {
          margin-bottom: 2px;
          .col {
            font-size: 13px;
            flex: initial;
            color: #b4bfca;
            &:nth-child(1) {
              width: 40px;
              flex-shrink: 0;
            }
            &:nth-child(2) {
              color: $primaryHeadingColor;
            }
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    button {
      &.add {
        flex: 1;
      }
      &.config {
        margin-left: 10px;
      }
    }
  }
}

#menu-vat {
  .MuiListItem-gutters {
    padding: 7px 10px;
    margin: 3px 0;
  }
}

.doc-iframe {
  width: 100%;
  display: block;
  width: 100%;
  height: 100%;
}
.frame-wrapper {
  position: relative;
  max-width: 760px;
  min-width: 760px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid whitesmoke;
  margin-top: 10px;
  //височината не е сигурна
  height: 700px;
  .upload {
    position: absolute;
    top: 30px;
    left: 10px;
    background-color: #fff;
    opacity: 1;
    transition: 0.2s;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 90px;
    img {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .right {
      h6 {
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
  }
  .upload-logo {
    position: absolute;
    top: 23px;
    left: 4px;
    width: 260px;
    height: 95px;
    // background-color: #EDEEF6;
    border: 1px dashed #b4bfca;
    border-radius: 5px;
    opacity: 0;
    .options {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 10px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 50%;
        width: 31px;
        height: 31px;
        cursor: pointer;
        margin: 5px 0;
        -webkit-box-shadow: 5px 5px 15px 5px rgba(149, 161, 173, 0.2);
        box-shadow: 5px 5px 15px 5px rgba(149, 161, 173, 0.2);
        min-width: auto;
        border: 1px solid #fff;
        transition: 0s;
        &.btn-upload:hover {
          border-color: #95a1ad;
        }
        &.btn-delete:hover {
          border-color: #ff4e77;
        }
      }
    }
    &:hover {
      opacity: 1;
    }
  }
}

.options-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    flex-shrink: 0;
    &.options-menu-button {
      min-width: 40px;
      border-radius: 50%;
      padding: 0;
    }
  }
}

#menu-payment_method_id {
  .MuiButtonBase-root {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
      width: 30px;
      object-fit: scale-down;
    }
  }
}
#mui-component-select-payment_method_id {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
    height: 20px;
    &:nth-child(2) {
      display: none;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    #documents-home {
      display: flex;
      flex-direction: column;
      flex: 1;
      .settings-panel {
        flex: 1;
        margin-bottom: -25px;
      }
    }
  }
}

#documents {
  margin: -25px;
  padding: 10px 0;
  margin-top: 0;
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  .document-options {
    width: 100%;
    padding: 15px;
    background-color: #edeef7;
    border-radius: 4px;
    .buttons {
      display: flex;
      align-items: center;
      width: 100%;
      .print-button-layout {
        width: fit-content;
        padding-right: 5px;
      }
      .send {
        margin-right: auto;
      }
    }
  }
  .custom-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    cursor: pointer;
    > div {
      width: 18px;
      height: 18px;
      border-radius: 3px;
    }
    > div:not(.empty) {
      background-color: #f50057;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
    > .empty {
      border: 2px solid rgba(0, 0, 0, 0.54);
    }
  }
  .panel {
    border: 1px solid #dfe0eb;
    flex: 1;
    display: flex;
    flex-direction: column;
    &.table-panel {
      border: none;
      padding-top: 0;
    }
  }
  #providers {
    .panel {
      .heading {
        margin-bottom: 5px;
      }
    }
    .no-row {
      position: relative;
      max-width: 170px;
      display: flex;
      .no-label {
        font-size: 25px;
        color: #1a6eb7;
        font-family: "SourceSansPro SemiBold", sans-serif;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }
      input.MuiInputBase-input {
        max-width: 100%;
        padding-left: 32px !important;
        &:not(:focus) {
          direction: rtl;
        }
      }
    }
    > .row {
      .col {
        flex: 1;
        &.small {
          flex: 1;
        }
        &.central-styled {
          .panel {
            border-color: #388ad0;
            background-image: url("../../img/documents/center-styled-bg.jpg");
            background-position: center;
            background-size: cover;
            position: relative;
            padding-top: 40px;
            span.sign {
              width: 160px;
              height: 38px;
              background-color: #388ad0;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              color: #fff;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              position: absolute;
              top: -8px;
              left: calc(50% - 160px / 2);
              font-family: "SourceSansPro SemiBold", sans-serif;
              &::before,
              &::after {
                content: "";
                width: 5px;
                height: 7px;
                background-size: contain;
                position: absolute;
                background-repeat: no-repeat;
                top: 0;
              }
              &::before {
                content: "";
                background-position: center right;
                background-image: url("../../img/documents/central-styled-left-shape.png");
                right: 100%;
              }
              &::after {
                content: "";
                background-position: center left;
                background-image: url("../../img/documents/central-styled-right-shape.png");
                left: 100%;
              }
            }
            h3.heading {
              color: #1a6eb7;
              text-transform: uppercase;
              font-size: 45px;
              font-family: "SourceSansPro SemiBold", sans-serif;
              margin-bottom: 5px;
              line-height: 1;
            }
            .MuiInputBase-root {
              border: none;
              background: none;
              box-shadow: none !important;
            }
            .light-border {
              input {
                border: 1px solid #cfe4ff;
              }
            }
            input {
              padding: 5px;
              background: none;
              border: none;
              font-size: 25px;
              color: #1a6eb7;
              font-family: "SourceSansPro SemiBold", sans-serif;
              border-radius: 5px;
              width: 160px;
              text-align: center;
              &:hover {
                background-color: #fff;
              }
              &[type="date"] {
                padding-left: 5px;
                text-align: left;
              }
              &[type="text"] {
                text-align: right;
              }
              &[name="related_no"] {
                width: 100%;
              }
            }
            .fillable-input {
              border: 1px solid transparent;
              &.validate {
                border-color: #f44336 !important;
                border-radius: 4px;
              }
              .Mui-focused {
                position: relative;
                // input{
                //   padding-left: 33.5px;
                //   max-width: 140px;
                //   width: 100%;
                //   flex-grow: 0;
                // }
                // &::before{
                //   content: '№';
                //   position: absolute;
                //   top: 3.1px;
                //   left: 5px;
                //   font-size: 25px;
                //   color: #1a6eb7;
                //   font-family: "SourceSansPro SemiBold", sans-serif;
                //   z-index: 2;
                //   line-height: 1.4;
                //   pointer-events: none;
                // }
              }
            }
            .row {
              margin-bottom: 25px;
            }
            .col {
              margin-right: 0;
              position: relative;
              span.label {
                color: #6ea1ec;
                font-size: 13px;
                position: absolute;
                top: calc(100% + 2px);
                left: 10px;
              }
            }
            .MuiInput-underline:before,
            .MuiInput-underline::after {
              display: none;
            }
            .MuiAutocomplete-inputRoot,
            .MuiAutocomplete-inputRoot {
              padding-right: 0;
            }
          }
          .number-suggestion {
            background-color: #fff;
            color: $primaryHeadingColor;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid #dfe0eb;
            width: 300px;
            font-size: 13px;
            position: absolute;
            left: 0;
            top: calc(100% + 10px);
            z-index: 2;
            box-shadow: 0px 0px 15px 0px rgba(138, 138, 138, 0.47);
            -webkit-box-shadow: 0px 0px 15px 0px rgba(138, 138, 138, 0.47);
            -moz-box-shadow: 0px 0px 15px 0px rgba(138, 138, 138, 0.47);
            .buttons {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 10px;
              button {
                height: 30px;
                padding: 0;
                margin: 0 5px;
              }
            }
            &::before {
              content: "";
              width: 35px;
              height: 16px;
              background-image: url("../../img/documents/icons/pointer-top.png");
              background-position: bottom center;
              background-repeat: no-repeat;
              background-size: contain;
              position: absolute;
              left: 10px;
              bottom: 100%;
            }
            animation: show-number 0.2s;
          }
          @keyframes show-number {
            0% {
              opacity: 0;
              transform: translateY(10px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
      .separator {
        font-size: 30px;
        color: $primaryHeadingColor;
        color: #1a6eb7;
        font-family: "SourceSansPro SemiBold", sans-serif;
        margin: 0 2px;
      }
    }
    .deliverer {
      padding: 10px;
      .col {
        > .row {
          padding: 5px;
          margin-bottom: 0;
          .col {
            font-size: 13px;
            &:nth-child(odd) {
              color: $primaryParagraphColor;
              flex: 0.12;
            }
            &:nth-child(even) {
              color: $primaryHeadingColor;
              flex: 0.88;
            }
          }
          &.double {
            > .col {
              flex: 1;
              margin-right: 0;
              .row {
                padding: 0;
                .col {
                  &:nth-child(odd) {
                    flex: 0.45 !important;
                  }
                  &:nth-child(even) {
                    flex: 0.55 !important;
                  }
                }
              }
              &:nth-child(1) {
                .col {
                  &:nth-child(1) {
                    flex: 0.3 !important;
                    min-width: 32px;
                  }
                  &:nth-child(2) {
                    border-right: 1px solid $primaryParagraphColor;
                    padding-right: 10px;
                  }
                }
              }
              &:nth-child(2) {
                .col {
                  &:nth-child(1) {
                    flex: initial !important;
                  }
                  &:nth-child(2) {
                    flex: initial !important;
                  }
                }
              }
            }
          }
          &:nth-child(even) {
            background-color: #f8f8f8;
          }
        }
      }
      .show-guide {
        position: relative;
        &::after {
          content: "";
          width: 22px;
          height: 22px;
          background-image: url("../../img/documents/icons/check.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          right: 5px;
          top: calc(50% - 22px / 2);
          -webkit-animation: rotate-in-ver 0.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: rotate-in-ver 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
        }
        @-webkit-keyframes rotate-in-ver {
          0% {
            -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
            opacity: 0;
          }
          100% {
            -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
            opacity: 1;
          }
        }
        @keyframes rotate-in-ver {
          0% {
            -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
            opacity: 0;
          }
          100% {
            -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
            opacity: 1;
          }
        }
      }
      .green {
        background-color: #c7ffe3;
      }
      .clickable {
        .col {
          pointer-events: none;
        }
      }
    }
    .client {
      .panel {
        padding: 10px 20px;
        .initial-client {
          font-size: 13px;
          color: $primaryParagraphColor;
          align-items: center;
          max-width: 310px;
          margin: auto;
        }
      }
      &.fields-shown {
        .panel {
          padding: 10px;
          > .col {
            width: 100%;
            > .row {
              align-items: center;
              padding: 0 5px;
              margin-bottom: 0;
              .MuiInputBase-root {
                padding: 0;
                background-color: transparent;
                height: 27px;
                input {
                  padding: 1px 5px;
                  border: none;
                  height: 100%;
                  font-size: 13px;
                }
                &,
                fieldset {
                  border: 1px solid transparent;
                  box-shadow: none;
                }
                &.Mui-focused,
                &:hover {
                  // border: 1px solid lightgray;
                }
              }
              .col {
                font-size: 13px;
                &:nth-child(odd) {
                  color: $primaryParagraphColor;
                  margin-right: 0;
                  flex: 0.12;
                }
                &:nth-child(even) {
                  color: $primaryHeadingColor;
                  flex: 0.85;
                }
              }
              &.selected {
                background-color: #e4f2ff !important;
                .col {
                  color: #000;
                }
              }

              &.double {
                div:nth-child(1)
                  > div
                  > div:nth-child(2)
                  > div
                  > div.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense.MuiFormControl-fullWidth
                  > div {
                  padding-right: 7px;
                }
                .MuiFormControl-root .error-info {
                  right: 4px;
                }
                > .col {
                  flex: 1;
                  .row {
                    .col {
                      &:nth-child(odd) {
                        flex: 0.8 !important;
                        margin-right: 0;
                      }
                      &:nth-child(even) {
                        flex: 0.58 !important;
                        max-width: 196px;
                        input {
                          width: 70px;
                          padding-left: 0;
                        }
                      }
                    }
                    .eu,
                    img {
                      right: inherit;
                      left: calc(100% + 5px);
                      width: 20px;
                      height: 20px;
                      border-radius: 3px;
                    }
                  }
                  &:nth-child(1) {
                    margin-right: 0;
                    flex: 0.8;
                    .col:not(.companies-col) {
                      &:nth-child(1) {
                        flex: 0.32 !important;
                        min-width: 38px;
                        justify-content: center;
                      }
                      &:nth-child(2) {
                        // border-right: 1px solid $primaryParagraphColor;
                        // padding-right: 10px;
                        padding-right: 0;
                        margin-left: -5px;
                        position: relative;
                        &::after {
                          content: "";
                          right: 0;
                          width: 1px;
                          height: calc(100% - 10px);
                          background-color: $primaryParagraphColor;
                          position: absolute;
                          top: 5px;
                        }
                      }
                    }
                  }
                  &:nth-child(2) {
                    .col {
                      justify-content: center;
                      &:nth-child(1) {
                        flex: initial !important;
                        width: calc(100% - 78px);
                        margin-right: 0;
                        margin-left: -10px;
                      }
                      &:nth-child(2) {
                        width: calc(100% + 5px);
                        flex: initial !important;
                        .customer-input {
                          width: calc(100% - 35px);
                        }
                      }
                    }
                  }
                }
              }
              &:nth-child(even) {
                background-color: #f8f8f8;
              }
            }
          }
        }
      }
    }
  }
  #intro {
    margin-top: 10px;
  }

  #articles {
    margin-top: 10px;
    .panel {
      padding-top: 0;
    }
    button.add {
      margin-top: 10px;
    }
    button.options-menu-button {
      width: 40px;
      padding: 0;
      min-width: initial;
      margin-left: 5px;
    }
  }

  #outro {
    margin-top: 10px;
  }

  #payments {
    margin-top: 10px;
    > .row {
      // .col:nth-child(1) {
      //     margin-right: 8px;
      // }

      // .col:nth-child(2) {
      //     margin-left: 8px;
      // }
    }
    button.add:hover {
      .popover-preview {
        opacity: 1;
        pointer-events: all;
        cursor: default;
      }
    }
    .selected-buttons {
      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
          margin-right: 10px;
          position: relative;
          min-height: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 120px;
            max-height: 31px;
          }
          span.close {
            position: absolute;
            top: -5px;
            right: -5px;
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #ff4e77;
            cursor: pointer;
            background-image: url("../../img/app/icons/close-small.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
            opacity: 0;
            pointer-events: none;
          }
          &:hover {
            span.close {
              opacity: 1;
              pointer-events: all;
            }
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  #signs {
    margin-top: 10px;
  }

  #customer-add {
    padding: 20px;
  }

  .product-input {
    width: 100%;
    position: relative;
    min-height: 40px;
    .selected-product {
      position: absolute;
      // left: 14px;
      left: 0;
      // top: 50%;
      top: 0;
      // transform: translateY(-50%);
      width: 100%;
      height: 100%;
      background-color: #e4f2ff;
      border-radius: 3px;
      padding: 5px 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1a6eb7;
      font-size: 15px;
      z-index: 2;
      font-family: "SourceSansPro SemiBold", sans-serif;
      span {
        margin-left: 5px;
        height: 16px;
        width: 16px;
        cursor: pointer;
      }
    }
    .dropdown {
      position: absolute;
      top: 45px;
      left: 0;
      width: 800px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 0 10px #ccc;
      padding: 20px 15px;
      z-index: 10;
      max-height: 480px;
      overflow-y: auto;
      display: none;
      &.show {
        display: block;
      }

      .row {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .col {
          margin-right: 30px;
          .heading {
            padding: 0 5px;
            .current-path {
              display: block;
              font-size: 10px;
            }
          }

          .ul {
            margin-top: 10px;
            max-height: 145px;
            // min-height: 140px;
            overflow-y: auto;
            padding-right: 10px;

            &.no-results {
              display: flex;
              justify-content: center;
              height: 100vh;
              align-items: center;
              p {
                margin: 40px 0;
                font-size: 13px;
              }
            }
            ul {
              li {
                font-size: 15px;
                padding: 4px 5px;
                padding-right: 20px;
                margin-bottom: 2px;
                border-radius: 4px;
                cursor: pointer;
                position: relative;
                h6 {
                  color: $primaryHeadingColor;
                  font-size: 15px;
                  font-family: "SourceSansPro SemiBold";
                  font-weight: normal;
                }
                span {
                  color: #aeaeae;
                  font-size: 13px;
                  font-family: "SourceSansPro Regular";
                }
                &:last-child {
                  margin-bottom: 0;
                }

                &:hover,
                &.selected {
                  background-color: #e0edf6;
                }
                .direct-add {
                  display: flex;
                  align-items: center;
                }
                .arrow {
                  display: none;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translate(0, -50%);
                }

                &:hover {
                  .arrow {
                    display: block;
                  }
                }
              }
            }
            &.categories {
              ul {
                li {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0;
                  img.folder {
                    margin: 0 2px;
                  }
                }
              }
            }
          }
        }

        .col-2 {
          width: calc(100% / 2 - 30px);
          .head {
            h6 {
              display: flex;
              align-items: center;
              font-family: "SourceSansPro Bold", sans-serif;
              font-size: 15px;
              color: $primaryHeadingColor;
              img {
                margin-right: 5px;
              }
            }
            span {
              color: $primaryHeadingColor;
              font-size: 10px;
            }
          }
          &:nth-child(2) {
            margin-right: 0;
            width: calc(100% / 2);
          }
          &:last-of-type {
            margin-right: 0;
          }
        }

        .col-3 {
          width: calc(100% / 3 - 30px);
        }

        .load-more {
          font-size: 12px;
          background: #eee;
          padding: 2px 5px;
          margin-top: 5px;
        }

        .back-button {
          margin-top: 10px;
          background-color: $primaryHeadingColor;
          padding: 2px 5px;
          padding-left: 30px;
          border-radius: 4px;
          color: #fff;
          font-size: 12px;
          cursor: pointer;
          position: relative;

          .arrow {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translate(0, -50%);
          }

          &:hover {
            background-color: #555;
          }
        }
      }

      .divider {
        border: 1px solid #eee;
        margin: 15px 0;
        &.big {
          border-width: 2px;
          margin-left: -15px;
          margin-right: -15px;
          width: calc(100% + 30px);
          border-color: #dde6ed;
        }
      }

      .products-found {
        ul {
          max-height: 150px;
          overflow: auto;
          li {
            padding: 4px 5px;
            padding-right: 20px;
            border-radius: 4px;
            cursor: pointer;
            h6 {
              color: $primaryHeadingColor;
              font-size: 15px;
              font-family: "SourceSansPro SemiBold";
              font-weight: normal;
            }
            span {
              color: #aeaeae;
              font-size: 13px;
              font-family: "SourceSansPro Regular";
            }
            &:hover,
            &.selected,
            &.category-found-selected {
              background-color: #e0edf6;
            }
            &.category-found-selected {
              min-height: 47px;
              padding-right: 40px;
              position: relative;
              &::after {
                content: "";
                background-image: url("../../img/documents/icons/enter.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                position: absolute;
                top: calc(50% - 20px / 2);
                right: 10px;
                -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
                animation: heartbeat 1.5s ease-in-out infinite both;
              }
              @-webkit-keyframes heartbeat {
                from {
                  -webkit-transform: scale(1);
                  transform: scale(1);
                  -webkit-transform-origin: center center;
                  transform-origin: center center;
                  -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
                }
                10% {
                  -webkit-transform: scale(0.91);
                  transform: scale(0.91);
                  -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
                }
                17% {
                  -webkit-transform: scale(0.98);
                  transform: scale(0.98);
                  -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
                }
                33% {
                  -webkit-transform: scale(0.87);
                  transform: scale(0.87);
                  -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
                }
                45% {
                  -webkit-transform: scale(1);
                  transform: scale(1);
                  -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
                }
              }
              @keyframes heartbeat {
                from {
                  -webkit-transform: scale(1);
                  transform: scale(1);
                  -webkit-transform-origin: center center;
                  transform-origin: center center;
                  -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
                }
                10% {
                  -webkit-transform: scale(0.91);
                  transform: scale(0.91);
                  -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
                }
                17% {
                  -webkit-transform: scale(0.98);
                  transform: scale(0.98);
                  -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
                }
                33% {
                  -webkit-transform: scale(0.87);
                  transform: scale(0.87);
                  -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
                }
                45% {
                  -webkit-transform: scale(1);
                  transform: scale(1);
                  -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
                }
              }
            }
          }
        }
      }
      .add-to-categories {
        // padding: 15px 10px;
        h3 {
          color: $primaryHeadingColor;
          font-size: 15px;
          font-family: "SourceSansPro SemiBold";
          font-weight: normal;
          margin-bottom: 10px;
        }
        input {
          border: 1px solid #dde6ed;
          border-radius: 3px;
          width: 100%;
          height: 40px;
          color: #b4bfca;
          padding: 0 15px;
          padding-left: 30px;
          margin-bottom: 10px;
          background-image: url("../../img/documents/icons/search.png");
          background-position: 10px center;
          background-repeat: no-repeat;
          &::placeholder {
            color: #b4bfca;
          }
        }
        ul {
          max-height: 150px;
          overflow: auto;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            font-size: 15px;
            padding: 0px 5px;
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            color: $primaryHeadingColor;
            img {
              flex-shrink: 0;
            }
            .direct-add {
              display: flex;
              align-items: center;
              flex: 1;
              min-height: 37px;
            }
            img.folder {
              margin: 0 2px;
            }
            button.nested-add {
              opacity: 0;
              pointer-events: none;
              flex-shrink: 0;
              border-radius: 50%;
              padding: 0;
              min-width: auto !important;
              width: 29px;
              height: 29px;
              margin-left: 5px;
              img {
                max-width: 100%;
              }
            }
            &:hover {
              button.nested-add {
                opacity: 1;
                pointer-events: all;
              }
            }
            &:hover,
            &.selected {
              background-color: #e0edf6;
            }
          }
        }
        .no-data {
          margin: 40px 0;
          font-size: 15px;
          text-align: center;
        }

        button.add-category {
          span {
            color: $primaryHeadingColor;
            font-size: 15px;
            display: flex;
            align-items: center;
            img {
              margin-right: 5px;
            }
          }
        }
      }

      /* width */
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #dde6ed;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #e0edf6;
      }
    }
    .initial-add {
      position: absolute;
      top: 45px;
      left: 0;
      width: 100%;
      border: 1px solid #dde6ed;
      border-radius: 4px;
      // box-shadow: 0 0 10px #ccc;
      padding: 20px 15px;
      z-index: 10;
      max-height: 480px;
      overflow-y: auto;
      display: none;
      align-items: center;
      background-color: #f8faff;
      cursor: pointer;
      img {
        flex-shrink: 0;
        margin-right: 10px;
      }
      .right {
        h3 {
          color: $primaryHeadingColor;
          font-size: 15px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          margin-bottom: 10px;
        }
        p {
          font-size: 13px;
          color: $primaryParagraphColor;
        }
      }
      &:hover {
        background-color: #f1f4fb;
      }
      &.show {
        display: flex;
      }
    }
  }

  form.wrapperd {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    .panel {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .readonly {
      .row {
        // margin-bottom: 15px;
        flex-direction: column;
        margin-bottom: 0;
        .col {
          width: 100% !important;
          margin-right: 0 !important;
          margin-bottom: 10px !important;
          .Mui-disabled {
            color: #2c2c2c;
          }
          .MuiFormLabel-root {
            color: rgba(0, 0, 0, 0.54);
          }
          &.small {
            flex: initial;
          }
          .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
            transform: initial !important;
            top: calc(50% - 11px / 2) !important;
            left: 0 !important;
            width: 100px !important;
            font-size: 13px !important;
            height: 15px !important;
          }
          .MuiInputBase-root.Mui-disabled .MuiAutocomplete-fullWidth {
            height: 15px !important;
          }
          .MuiFormControl-fullWidth {
            padding-left: 120px;
          }
          .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]
            .MuiFormControl-marginDense {
            padding-top: 0 !important;
            // padding-left: 120px;
            border: none !important;
          }

          input {
            padding: 0 5px !important;
            font-size: 13px;
            height: 15px;
          }
          .MuiInputBase-root {
            border: none !important;
            padding: 0 !important;
            padding-left: 0 !important;
          }
          &:last-of-type {
            // margin-bottom: 10px;
            .col {
              &:last-of-type {
                margin-bottom: 0 !important;
              }
            }
          }
          .eu {
            display: none;
          }
        }
        .triggered {
          margin-bottom: 15px;
          // border: 1px solid #388ad0;
          box-shadow: rgba(63, 146, 255, 0.25) 0 0 0 2px;
          border-color: #388ad0;
          padding: 10px;
          border-radius: 2px;
          margin-left: -10px;
          margin-right: -10px !important;
          width: calc(100% + 20px) !important;
          .MuiInputBase-root {
            box-shadow: initial !important;
            // input{
            //     height: 25px;
            // }
          }
        }
      }
    }
    .sum {
      td {
        padding: 0 7px;
        &.with-input {
          // padding-top: 13px;
        }
        &:last-of-type {
          min-width: 150px;
        }
        &:first-of-type {
          padding-right: 10px;
          width: 100%;
        }
        .MuiSelect-root {
          text-align: left;
        }
      }
      .select-vat {
        display: flex;
        align-items: center;
        margin: auto 0;
        .custom-vat {
          display: flex;
          align-items: center;
          .MuiFormControl-root {
            width: 80px;
            input {
              height: 33px;
              padding: 0 10px;
            }
          }
          i.close {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            color: #fff;
            background-color: #ff4e77;
            cursor: pointer;
            margin-left: 10px;
            flex-shrink: 0;
          }
        }
      }
      .vat-field {
        margin-top: 3px;
        margin-bottom: 3px;
        width: 80px;
        text-align: left;
        > div {
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
      input[name="vat_reason"] {
        font-size: 13px;
      }
      .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
        padding-right: 0;
      }
    }
  }

  &.preview {
    .document-options {
      max-width: 760px;
      margin: 0 auto;
    }
  }
}
#documents .panel,
.store-loads,
.store-moves,
.preview-storage-load-modal {
  .additional-options {
    border: 1px solid #dde6ed;
    border-radius: 1px;
    td {
      background-color: #fafafa !important;
      cursor: default;
      *{
        font-family: inherit;
      }
      ul.graphs {
        display: flex;
        flex-direction: column;
        width: 70px;
        margin: 0 auto;
        li {
          margin-bottom: 15px;
          height: 11px;
          background-color: #e2e7ea;
          border-radius: 10px;
          div {
            background-image: linear-gradient(to right, #bfe2d1, #3bc46e);
            min-width: 10px;
            width: fit-content;
            max-width: 100%;
            height: 100%;
            border-radius: inherit;
            transition: 1s;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      ul.prices {
        li {
          margin-bottom: 12px;
          line-height: 1;
          font-size: 15px;
          b {
            font-weight: normal;
            font-family: "Roboto Bold", sans-serif;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
#documents-home {
  margin: 0 -25px;
  .settings-panel {
    background-color: #fff;
    border-radius: 3px;
    width: 100%;
    display: flex;
    hr {
      margin: 30px 0;
    }
    .panel {
      padding: 0;
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .menu {
      padding: 20px 0;
      border-right: 1px solid #edeef7;
      b {
        color: $primaryHeadingColor;
        font-size: 20px;
        font-family: "SourceSansPro SemiBold", sans-serif;
        font-weight: normal;
        margin-bottom: 15px;
        display: block;
        text-align: center;
      }
      ul {
        width: 225px;
        display: flex;
        flex-direction: column;
        li {
          cursor: pointer;
          position: relative;
          overflow: hidden;
          font-size: $primaryParagraphFontSize;
          color: $primaryHeadingColor;
          display: flex;
          align-items: center;
          padding: 5px 0;
          z-index: 1;
          span.image {
            background-image: url("../../img/documents/icons/document-neutral.jpg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 56px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            color: #d6dce8;
            font-size: 15px;
            font-weight: 600;
            padding-bottom: 5px;
            transition: 0.2s;
            &::before {
              content: "";
              width: 16px;
              height: 19px;
              background-image: url("../../img/documents/icons/document-hover.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              left: calc(50% - 14px / 2);
              bottom: -1px;
              transform: scale(0);
              transition: inherit;
            }
          }
          span.text {
            position: relative;
            z-index: 1;
          }
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 100%;
            bottom: 0;
            background-image: linear-gradient(to right, #fff, #fff, #e6effb);
            position: absolute;
            pointer-events: none;
            z-index: 0;
            transition: 0.5s;
          }
          &:hover {
            // span.image {
            //   background-color: #fff;
            //   color: #38bd6a;
            //   &::before {
            //     transform: scale(1);
            //   }
            // }
            &::after {
              left: 0;
            }
          }
        }
      }
    }
    .content {
      width: calc(100% - 225px);
      padding: 30px;
      max-width: 1700px;
      margin: 0 auto;
      background-color: #fff;
      background-image: url("../../img/documents/home-bg.jpg");
      background-size: cover;
      background-position: center;
      h2 {
        text-align: center;
        color: $primaryHeadingColor;
        font-size: 40px;
        font-family: "SourceSansPro SemiBold", sans-serif;
        font-weight: normal;
        margin-bottom: 10px;
      }
      p {
        text-align: center;
        font-size: $primaryParagraphFontSize;
        color: $primaryParagraphColor;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 40px;
      }
      b {
        display: block;
        color: #388ad0;
        font-family: "SourceSansPro SemiBold", sans-serif;
        font-size: 18px;
        margin-bottom: 15px;
      }
      ul.main {
        width: calc(100% + 15px);
        margin-right: -15px;
        display: flex;
        flex-wrap: wrap;
        li {
          width: calc(100% / 4 - 15px);
          margin-right: 15px;
          margin-bottom: 15px;
          background-color: #fff;
          border: 1px solid #dde6ed;
          border-radius: 5px;
          padding: 20px;
          position: relative;
          transition: 0.2s;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          span.image {
            background-image: url("../../img/documents/icons/document-big.jpg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 85px;
            height: 89px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            color: #d6dce8;
            font-size: 30px;
            font-weight: 600;
            padding-bottom: 5px;
            transition: 0.2s;
            font-family: "SourceSansPro Bold", sans-serif;
          }
          span.text {
            position: relative;
            z-index: 1;
            display: block;
            text-align: center;
            font-size: 20px;
            margin-bottom: 15px;
          }
          p {
            margin-bottom: 0;
          }
          &::before {
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            border-bottom: 5px solid #388ad0;
            pointer-events: none;
            border-radius: inherit;
            opacity: 0;
            transition: 0.2s;
          }
          &:hover {
            transform: translateY(-5px);
            span.text {
              color: #388ad0;
            }
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

#providers
  > div
  > div.col.client.fields-shown
  > div
  > div.col
  > div.row.double
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > input {
  width: 100% !important;
}

#preview {
  min-height: 100vh;
  background: #525659;
  .MuiMenu-list {
    .print {
      padding: 0;
    }
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #2e3234;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    height: 55px;

    .buttons {
      display: flex;
      align-items: center;
      .print-button-layout,
      > button {
        margin: 0 5px;
      }
      button.normal {
        background-color: #f0f1f3;
        color: #000;
      }
    }

    button {
      box-shadow: none;
      height: 37px;
      font-size: 13px;
      &:hover {
        opacity: 0.8;
      }
    }

    button.login-button {
      background-image: url("../../img/app/icons/file-white.png");
      background-position: 15px center;
      background-color: #2bbc79;
      color: #fff;
      padding: 10px 40px;
      padding-right: 20px;
    }
  }

  .doc {
    margin-top: 70px;
    .iframe {
      // max-width: 760px;
      // min-width: 760px;
      width: 210mm;
      height: 297mm;
      margin: 20px auto;
      padding: 30px;
      display: block;
      min-height: 700px;
      border: 1px solid whitesmoke;
      margin-top: 10px;
      background: #fff;
      box-shadow: 0 0 10px rgb(32, 32, 32);
    }
  }

  .auth-menu {
    height: 100vh;
    width: 460px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url("../../img/documents/document-preview-bg.png");
    background-size: cover;
    background-position: top right;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-right: -460px;
    transition: 0.1s;
    overflow: auto;
    .head-part {
      display: flex;
      align-items: center;
      width: 100%;
      flex-shrink: 0;
      margin-bottom: 35px;
      div {
        width: calc(100% / 3);
        display: flex;
        justify-content: center;
        .back {
          margin-right: auto;
          display: flex;
          align-items: center;
          color: #cbe3ff;
          font-size: 15px;
          text-transform: uppercase;
          font-family: "SourceSansPro SemiBold", sans-serif;
          cursor: pointer;
          img {
            margin-right: 10px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        .brand {
          margin: 0 auto;
          width: 155px;
        }
        .close {
          margin-left: auto;
          background-color: #cbe3ff;
          width: 29px;
          height: 29px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            background-color: #bed6f1;
          }
        }
      }
    }
    .body-part {
      flex: 1;
      -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      img.main {
        margin: 0 auto;
        display: block;
        height: 90px;
      }
      h2 {
        text-align: center;
        font-family: "Exo2 Bold", sans-serif;
        font-size: 30px;
        margin-bottom: 5px;
        color: #fff;
      }
      button {
        height: 50px;
        box-shadow: 0;
        &.add {
          padding: 0 10px;
          width: 100%;
          background-color: #10cea9;
          margin-top: 23px;
          span {
            display: flex;
            align-items: center;
            font-size: 16px;
            img {
              margin-left: 5px;
            }
          }
          &::before {
            display: none;
          }
          &:hover {
            background-color: #11c5a1;
          }
        }
        &.edit {
          background-color: #559fff;
          padding: 0 20px;
          height: 33px;
          border-radius: 20px;
          background-image: none;
          margin: 0 auto;
          display: block;
          span {
            color: #fff;
          }
        }
        &.change-screen {
          border: 1px solid #fff;
          width: 100%;
          color: #fff;
          span {
            font-size: 16px;
          }
        }
        // &.done{
        //   box-shadow: 0px 0px 33px 0px #10cea9;
        // }
      }
      &.welcome {
        ul.company-data {
          margin-bottom: 15px;
          li {
            text-align: center;
            font-size: 16px;
            color: #fff;
            margin-bottom: 5px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        hr {
          background-color: #559fff;
          margin: 20px 0;
        }
        ul.info {
          padding: 0 55px;
          li {
            margin-bottom: 13px;
            color: #9bcaff;
            font-size: 16px;
            position: relative;
            padding-left: 30px;
            &:last-of-type {
              margin-bottom: 0;
            }
            &::before {
              content: "";
              width: 22px;
              height: 22px;
              position: absolute;
              left: 0;
              top: calc(50% - 22px / 2);
              background-image: url("../../img/documents/icons/check-new.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
        img.main {
          margin-bottom: -15px;
        }
      }
      &.register {
        img.main {
          margin-bottom: 13px;
        }
        h2 {
          font-family: "Exo2 Medium", sans-serif;
          font-size: 25px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        ul.company-data {
          margin-bottom: 60px;
          li {
            text-align: center;
            font-size: 16px;
            color: #9bcaff;
            margin-bottom: 1px;
            &:last-of-type {
              margin-bottom: 0;
            }
            b {
              font-family: "SourceSansPro Medium", sans-serif;
              color: #d4e8ff;
            }
          }
        }
        .row {
          .col {
            position: relative;
            border: 1px solid transparent;
            border-radius: 5px;
            input {
              background-color: #fff;
              border: none;
              height: 53px;
              padding-left: 35px;
              font-size: 15px;
              color: $primaryHeadingColor;
              border-radius: 5px;
              &::placeholder {
                color: #b3b3c9;
                font-size: 15px;
              }
            }
            .view-pw {
              position: absolute;
              top: calc(50% - 15px / 2);
              right: 20px;
              width: 30px;
              height: 15px;
              cursor: pointer;
              z-index: 2;
              &::before {
                content: "";
                width: 26px;
                height: 2px;
                background-color: #d7d7e7;
                position: absolute;
                left: 2px;
                top: 7px;
                transform: rotate(-45deg);
              }
              &.on::before {
                display: none;
              }
            }
            &::before {
              content: "";
              width: 16px;
              height: 16px;
              position: absolute;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              top: calc(50% - 16px / 2);
              left: 10px;
              z-index: 2;
            }
            &[data-holder="email"]::before {
              background-image: url("../../img/documents/icons/at.png");
            }
            &[data-holder="password"]::before {
              background-image: url("../../img/documents/icons/password.png");
            }
            &.validate {
              border-color: #f44336;
              &::before {
                filter: invert(47%) sepia(88%) saturate(5603%)
                  hue-rotate(344deg) brightness(104%) contrast(91%);
              }
              input::placeholder {
                color: #f44336;
              }
            }
            i {
              color: #f44336;
              font-size: 11px;
              position: absolute;
              bottom: 2px;
              left: 35px;
              font-style: normal;
              pointer-events: none;
            }
            .mol {
              position: absolute;
              bottom: 100%;
              left: 0;
              padding-bottom: 10px;
              color: #9bcaff;
              font-size: 15px;
              display: flex;
              align-items: center;
              cursor: pointer;
              img {
                margin-left: 10px;
                transition: 0.2s;
              }
              &:hover {
                img {
                  transform: scale(0.9);
                }
              }
            }
          }
          &.checkbox-holder {
            padding-left: 10px;
            margin-bottom: 0;
            .MuiCheckbox-root,
            .MuiTypography-root {
              color: #fff;
              font-size: 15px;
              line-height: 1.2;
              a {
                color: inherit;
                text-decoration: underline;
              }
            }
          }
        }
      }
      &.login {
        img.main {
          margin-bottom: -10px;
          height: 150px;
        }
        h2 {
          font-family: "Exo2 Medium", sans-serif;
          font-size: 25px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        ul.company-data {
          margin-bottom: 25px;
          li {
            text-align: center;
            font-size: 16px;
            color: #9bcaff;
            margin-bottom: 1px;
            &:last-of-type {
              margin-bottom: 0;
            }
            b {
              font-family: "SourceSansPro Medium", sans-serif;
              color: #d4e8ff;
            }
          }
        }
        .row {
          .col {
            position: relative;
            border: 1px solid transparent;
            border-radius: 5px;
            input {
              background-color: #fff;
              border: none;
              height: 53px;
              padding-left: 35px;
              font-size: 15px;
              color: $primaryHeadingColor;
              border-radius: 5px;
              &::placeholder {
                color: #b3b3c9;
                font-size: 15px;
              }
            }
            .view-pw {
              position: absolute;
              top: calc(50% - 15px / 2);
              right: 20px;
              width: 30px;
              height: 15px;
              cursor: pointer;
              z-index: 2;
              &::before {
                content: "";
                width: 26px;
                height: 2px;
                background-color: #d7d7e7;
                position: absolute;
                left: 2px;
                top: 7px;
                transform: rotate(-45deg);
              }
              &.on::before {
                display: none;
              }
            }
            &::before {
              content: "";
              width: 16px;
              height: 16px;
              position: absolute;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              top: calc(50% - 16px / 2);
              left: 10px;
              z-index: 2;
            }
            &[data-holder="email"]::before {
              background-image: url("../../img/documents/icons/user.png");
            }
            &[data-holder="password"]::before {
              background-image: url("../../img/documents/icons/password.png");
            }
            &.validate {
              border-color: #f44336;
              &::before {
                filter: invert(47%) sepia(88%) saturate(5603%)
                  hue-rotate(344deg) brightness(104%) contrast(91%);
              }
              input::placeholder {
                color: #f44336;
              }
            }
            i {
              color: #f44336;
              font-size: 11px;
              position: absolute;
              bottom: 2px;
              left: 35px;
              font-style: normal;
              pointer-events: none;
            }
            .mol {
              position: absolute;
              bottom: 100%;
              left: 0;
              padding-bottom: 10px;
              color: #9bcaff;
              font-size: 15px;
              display: flex;
              align-items: center;
              cursor: pointer;
              img {
                margin-left: 10px;
                transition: 0.2s;
              }
              &:hover {
                img {
                  transform: scale(0.9);
                }
              }
            }
          }
          &.checkbox-holder {
            padding-left: 10px;
            margin-bottom: 0;
            .MuiCheckbox-root,
            .MuiTypography-root {
              color: #fff;
              font-size: 15px;
              line-height: 1.2;
              a {
                color: inherit;
                text-decoration: underline;
              }
            }
          }
        }
        .link {
          margin-left: auto;
          color: #fff;
          text-decoration: underline;
        }
      }
      .small {
        width: 100%;
        position: relative;
        padding-bottom: 0 !important;
        margin-bottom: 10px;
        input {
          height: 25px !important;
          background-color: #4392ee !important;
          color: #9cc6f8 !important;
          font-size: 15px !important;
          background-image: url("../../img//documents/icons/profile.png");
          background-repeat: no-repeat;
          background-size: 15px 14px;
          background-position: 10px center;
          width: 100%;
          cursor: pointer;
          &:focus {
            color: #fff !important;
            cursor: initial;
          }
        }
        img {
          position: absolute;
          top: calc(50% - 14px / 2);
          right: 10px;
          pointer-events: none;
        }
      }
      .profile-question {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin: 20px 0;
        span {
          position: relative;
          padding: 0 10px;
          font-size: 18px;
          font-family: "Exo2 Bold", sans-serif;
          color: #fff;
          &::before {
            content: "";
            background-color: #fff;
            height: 1px;
            width: 100%;
            position: absolute;
            right: 100%;
            top: calc(50% - 1px / 2);
          }
          &::after {
            content: "";
            background-color: #fff;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 100%;
            top: calc(50% - 1px / 2);
          }
        }
      }
    }
    @-webkit-keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .footer-part {
      text-align: center;
      font-size: 14px;
      color: #9bcaff;
      padding: 10px;
      padding-bottom: 0;
    }
    &.show {
      margin-right: 0;
    }
  }
}

.document-print {
  display: none;
}

@media print {
  .document-print {
    display: block;
  }
  header,
  main {
    display: none;
  }
}
