.import {
    .import-options {
        display: flex;
        align-items: center;

        div.import-type {
            display: flex;
            align-items: center;

            label {
                height: 100%;
                padding: 4px 6px;
                border-radius: 3px;
                display: inline-flex;
                align-items: center;
                color: #fff;
                font-size: 0.8rem;
                margin-left: 5px;
                cursor: pointer;

                input {
                    margin-right: 5px;
                }

                &.create {
                    background: #d27a2b;
                }

                &.update {
                    background: #198754;
                }
            }
        }

        button {
            margin-left: 5px;
        }
    }

    .import-settings {
        display: flex;
        align-items: center;

        .group {
            display: flex;
            align-items: center;
            margin-right: 10px;
            overflow-x: auto;

            label {
                font-size: 0.8rem;
                white-space: nowrap;
                margin-right: 5px;
            }
        }
    }

}