$primaryHeadingColor: #42424e;
$primaryParagraphColor: #777777;
$primaryFontFamily: "SourceSansPro SemiBold", sans-serif;

.popover-send-history {
  .MuiPopover-paper {
    padding: 20px;
    border: 1px solid #dedfe1;
    border-radius: 5px;
    background-color: #fff;
    min-width: 700px;
    b {
      color: $primaryHeadingColor;
      font-size: 18px;
      margin-bottom: 5px;
      font-family: "SourceSansPro SemiBold", sans-serif;
    }
    p {
      color: $primaryParagraphColor;
      font-size: 15px;
      margin-bottom: 30px;
    }
    table {
      thead {
        tr {
          th {
            padding-bottom: 10px;
            font-size: 15px;
          }
        }
        &::before {
          display: none;
        }
      }
      tbody {
        tr {
          td {
            background-color: #f8f8f8 !important;
            border-bottom: 5px solid #fff;
            font-size: 15px;
            .person {
              .heading {
                display: flex;
                align-items: center;
                color: $primaryHeadingColor;
                font-family: $primaryFontFamily;
                font-size: 15px;
                // margin-bottom: 5px;
                picture {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  position: relative;
                  margin-right: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #dbe4eb;
                  font-family: $primaryFontFamily;
                  font-size: 13px;
                  letter-spacing: 0px;
                  text-transform: uppercase;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                  &::after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 6px);
                    height: calc(100% - 6px);
                    top: 2px;
                    left: 2px;
                    border: 1px solid #fff;
                    border-radius: inherit;
                  }
                }
              }
              .email {
                font-size: 13px;
              }
            }
            button {
              border: 1px solid #388ad0;
              margin-left: auto;
              display: flex;
              align-items: center;
              background-color: transparent;
              background-image: url("../../assets/img/app/icons/send-blue.png");
              span {
                color: $primaryHeadingColor;
                font-size: 15px;
              }
              &:hover {
                background-color: #f6fbff;
              }
            }
          }
          &:last-of-type {
            td {
              border: none;
            }
          }
        }
      }
    }
  }
}
.send-modal {
  .body{
    .val {
      border: 1px solid #f44336 !important;
    }
    .head {
      padding: 0;
    }
    h3 {
      font-weight: normal;
    }
    p {
      padding-right: 50px;
      margin-bottom: 20px;
    }
  }

  .footer {
    .notice {
      margin-right: auto;
      line-height: 1;
      color: $primaryHeadingColor;
      font-size: 13px;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 5px;
      img {
        margin-right: 5px;
      }
      &:hover {
        background-color: #f1f2f3;
      }
    }
  }
}
.send-modal,
.repeat-options {
  overflow: hidden;
  .ck-content {
    height: 200px;
  }
  .ck.ck-editor__main > .ck-editor__editable {
    border-color: #fff !important;
    border-top-color: #ccced1 !important;
    box-shadow: none !important;
  }
  .body {
    .active-template {
      position: relative;
      .dropdown {
        position: absolute;
        right: calc(100% + 20px);
        top: -10px;
        background: #fff;
        box-shadow: 0px 3px 20px #ccc;
        width: 501px;
        z-index: 3;
        opacity: 0;
        pointer-events: none;
        border-radius: 3px;
        transform: translateX(-10px);
        transition: 0.2s;
        &.show {
          opacity: 1;
          pointer-events: all;
          transform: translateX(0);
        }
  
        .heading {
          background: #388ad0;
          padding: 15px 20px;
          display: flex;
          align-items: center;
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
          .title {
            color: #fff;
            margin-right: 10px;
            font-size: 16px;
          }
          select {
            color: #fff;
            border-radius: 3px;
            background-color: #45a9ff;
            font-size: 15px;
            padding: 3px 5px;
            border: none;
            text-transform: uppercase;
          }
        }
  
        .body {
          padding: 0;
          max-height: 210px;
          overflow: auto;
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
          flex-direction: column;
          .template {
            width: 100%;
            border-bottom: 1px solid #eaedf5;
            padding: 10px 20px;
            cursor: pointer;
            .name {
              font-size: 18px;
              font-family: $primaryFontFamily;
              color: $primaryHeadingColor;
            }
  
            .text {
              margin-top: 5px;
              color: #aaaaaa;
              font-size: 15px;
            }
  
            &:hover {
              background-color: #fafafa;
            }
            &.active {
              background-color: #eff0f4 !important;
            }
  
            &:last-child {
              border-bottom: none;
            }
          }
        }
  
        &::after {
          content: "";
          width: 6px;
          height: 13px;
          background-image: url("../img/documents/icons/pointer-right.png");
          background-size: contain;
          background-position: center;
          left: 100%;
          top: 15px;
          position: absolute;
        }
      }
      .link {
        font-family: $primaryFontFamily;
        text-decoration: underline;
      }
    }
    .email-input-wrapper {
      .email-input {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border: 1px solid #dde6ed;
        border-radius: 3px;
        padding: 5px;
        background: #fff;
  
        .email-chips {
          position: relative;
          top: 0;
          left: 0;
          // bottom: 0;
          display: flex;
          flex-wrap: wrap;
  
          .chip {
            background-color: #d4ebff;
            border-radius: 3px;
            margin-right: 3px;
            margin: 0 5px;
            padding: 5px 7px;
            display: flex;
            align-items: center;
            color: #388ad0;
            font-size: 15px;
            position: relative;
            .remove {
              margin-left: 5px;
              cursor: pointer;
              height: 16px;
              width: 16px;
              flex-shrink: 0;
            }
            &:hover {
              .card {
                opacity: 1;
                // pointer-events: all;
              }
            }
          }
        }
  
        .input {
          position: relative;
          width: 100%;
          input {
            border: none;
            height: 100%;
            background: transparent;
            width: 100%;
          }
          .dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 5px;
            background: #fff;
            z-index: 2;
            min-width: 300px;
            box-shadow: 0 5px 10px #ccc;
            display: none;
  
            &.show {
              display: initial;
            }
  
            .loader {
              margin: 10px;
              text-align: center;
            }
  
            ul {
              border: 1px solid #dde6ed;
              max-height: 190px;
              overflow: auto;
              li {
                cursor: pointer;
                padding: 10px;
                border-bottom: 1px solid #dde6ed;
                border-radius: 2px;
                .heading {
                  display: flex;
                  align-items: center;
                  color: $primaryHeadingColor;
                  font-family: $primaryFontFamily;
                  font-size: 15px;
                  margin-bottom: 5px;
                  picture {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    position: relative;
                    margin-right: 5px;
                    display: block;
                    flex-shrink: 0;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      display: block;
                      border-radius: inherit;
                    }
                    &::after {
                      content: "";
                      position: absolute;
                      width: calc(100% - 6px);
                      height: calc(100% - 6px);
                      top: 2px;
                      left: 2px;
                      border: 1px solid #fff;
                      border-radius: inherit;
                    }
                  }
                }
                .text {
                  color: $primaryHeadingColor;
                  font-size: 13px;
                  //   margin-bottom: 5px;
                }
                .email {
                  font-size: 13px;
                }
                &:hover {
                  background: #eee;
                }
                &:last-of-type {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
  
      .MuiFormControl-root{
        position: absolute;
        right: 5px;
        width: 40px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: default;
        input{
          display: none;
        }
        .MuiInputBase-root{
          border: none;
          width: inherit;
          height: inherit;
        }
        .error-info{
          right: 0;
        }
      }
  
      .card {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        // margin-top: 10px;
        background: #fff;
        z-index: 100;
        padding: 10px;
        border-bottom: 1px solid #dde6ed;
        border-radius: 2px;
        align-items: flex-start;
        box-shadow: 0 5px 10px #ccc;
        top: 100%;
        left: 0;
        display: flex;
        picture {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          position: relative;
          margin-right: 5px;
          display: block;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: inherit;
          }
          &::after {
            content: "";
            position: absolute;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            top: 2px;
            left: 2px;
            border: 1px solid #fff;
            border-radius: inherit;
          }
        }
        .right {
          .heading {
            color: $primaryHeadingColor;
            font-family: $primaryFontFamily;
            font-size: 15px;
          }
          .text {
            color: $primaryHeadingColor;
            font-size: 13px;
            margin-bottom: 10px;
          }
          .email {
            font-size: 13px;
          }
        }
        &.show {
          display: flex;
        }
      }
    }

    .label-holder {
      align-items: center;
      .col.label {
        flex: 0.05;
        flex-shrink: 0;
        font-size: 15px;
        color: $primaryHeadingColor;
        font-family: $primaryFontFamily;
      }
    }
    .message-holder {
      border: 1px solid #dde6ed;
      border-radius: 3px;
      .MuiFilledInput-multiline.MuiFilledInput-marginDense {
        border: none;
        box-shadow: none;
        padding: 15px;
        font-size: 15px;
      }
      .documents-holder {
        padding: 0 15px;
        margin-top: 10px;
        p {
          position: relative;
          margin-bottom: 10px;
          span {
            background-color: #fff;
            position: relative;
            z-index: 2;
            padding-right: 10px;
            font-size: 15px;
            color: $primaryHeadingColor;
            font-family: $primaryFontFamily;
          }
          &::after {
            content: "";
            width: 100%;
            height: 1px;
            top: calc(50% + 2px / 2);
            left: 0;
            right: 0;
            position: absolute;
            background-color: #dde6ed;
            z-index: 1;
          }
        }
        .with-tabs{
          background-color: #f4f7fa;
          margin-bottom: 10px;
          border-radius: 4px;
          border-top-left-radius: 0;
        }
        ul.types-tabs {
          display: flex;
          background-color: #fff;
          width: 100%;
          li {
            padding: 5px 10px;
            width: fit-content;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            cursor: pointer;
            margin-right: 5px;
            border-bottom: none;
            font-size: 15px;
            color: #42424e;
            &.active,
            &:hover {
              background-color: #f4f7fa;
            }
            &.active{
              cursor: default;
            }
          }
        }
        ul.mini-documents {
          margin-right: -15px;
          width: calc(100% + 15px);
          padding: 10px;
          padding-bottom: 0;
          li {
            width: calc(100% / 3 - 15px);
            border: 1px solid #dfe6f5;
            font-size: 15px;
            padding: 10px 5px;
            border-radius: 3px;
            text-align: left;
            font-family: $primaryFontFamily;
            margin: 0;
            margin-right: 15px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            transition: 0.2s;
            &.active {
              border-color: #388ad0;
            }
            &:hover:not(.active) {
              background-color: #f7faff;
            }
            &.add {
              justify-content: center;
              background-color: #eef4fd;
              img {
                margin-right: 5px;
              }
              &:hover {
                background-color: #e3eaf5;
              }
            }
            img.loader {
              width: 18px;
              height: 18px;
              display: block;
              margin: 0 auto;
            }
          }
          //   li {
          //     height: auto;
          //     height: 79px;
          //     width: 56px;
          //     #my-pdf {
          //       width: 100%;
          //       height: 100%;
          //       canvas {
          //         max-width: 100%;
          //         max-height: 100%;
          //       }
          //     }
          //     &:first-of-type {
          //       margin-left: 0;
          //     }
          //     &:last-of-type {
          //       margin-right: 0;
          //     }
          //     &.active {
          //       canvas {
          //         border-color: #388ad0;
          //       }
          //     }
          //   }
        }
      }
      //   textarea {
      //     height: 200px;
      //     resize: none;
      //     border: none;
      //     padding: 5px;
      //     width: 100%;
      //   }
    }
  }
}

.send-success {
  max-width: 750px !important;
  .body {
    h3 {
      margin-bottom: 20px !important;
    }
    h4 {
      color: $primaryHeadingColor;
      font-size: 18px;
      margin-bottom: 5px;
      font-family: $primaryFontFamily;
      font-weight: normal;
      text-align: center;
    }
    .save-holder {
      padding: 15px;
      border-radius: 3px;
      border: 1px solid #dde6ed;
      background-color: #f4f7fa;
      ul {
        max-height: 190px;
        overflow: auto;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        li.contact {
          border: 1px solid #dde6ed;
          border-radius: 3px;
          padding: 15px 0;
          margin: 0 7.5px;
          margin-bottom: 15px;
          width: calc(50% - 15px);
          align-items: center;
          background-color: #fff;
          .col {
            width: fit-content;
            flex: initial;
            &.person {
              width: calc(100% - 44px);
              .heading {
                display: flex;
                align-items: center;
                color: $primaryHeadingColor;
                font-family: $primaryFontFamily;
                font-size: 15px;
                margin-bottom: 5px;
                picture {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  position: relative;
                  margin-right: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #dbe4eb;
                  font-family: $primaryFontFamily;
                  font-size: 13px;
                  letter-spacing: 0px;
                  text-transform: uppercase;
                  flex-shrink: 0;
                  &::after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 6px);
                    height: calc(100% - 6px);
                    top: 2px;
                    left: 2px;
                    border: 1px solid #fff;
                    border-radius: inherit;
                  }
                }
                input {
                  display: flex;
                  align-items: center;
                  color: #42424e;
                  font-family: "SourceSansPro SemiBold", sans-serif;
                  font-size: 15px;
                  // margin-bottom: 5px;
                  // width: fit-content;
                  // width: 110px;

                  width: calc(100% - 60px);
                  border: 1px solid lightgray;
                  padding: 0 5px;
                }
                img {
                  margin-left: 5px;
                  cursor: pointer;
                }
              }
              .text {
                color: $primaryHeadingColor;
                font-size: 13px;
                //   margin-bottom: 5px;
              }
              .email {
                font-size: 13px;
              }
            }
          }
          .MuiCheckbox-colorPrimary {
            padding: 0 10px;
          }
          .MuiCheckbox-colorPrimary.Mui-checked {
            color: #3bc76d;
          }
          &.validate {
            border: 1px solid #f44336;
          }
        }
        &.custom-input {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          flex-wrap: nowrap;
          li {
            width: 100%;
            margin: 0;
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            .row {
              align-items: center;
              &.top-row {
                .col {
                  margin-right: 0;
                  &:first-of-type {
                    span {
                      padding-right: 2px;
                    }
                  }
                }
                .accountant {
                  min-width: initial;
                  width: 30px;
                  height: 30px;
                  border-radius: 5px;
                  margin-left: auto;
                  margin-right: 15px;
                  flex-shrink: 0;
                  &.active {
                    background-color: #0e61dd;
                  }
                }
              }
              &:not(.top-row) {
                padding: 0 15px;
                .col {
                  flex: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
