.new-btn-primary {
  background: linear-gradient(269.83deg, #2a9395 -1.16%, #115578 101.78%);
  padding: 0 15px;
  span {
    color: #fff;
    font-size: 15px;
  }
}

section#no-documents {
  background-image: url("../../img/dashboard/no-docs-bg.jpg");
  background-size: cover;
  background-position: bottom center;
  border-radius: 20px;
  padding: 40px;
  flex: 1;
  min-height: 580px;
  position: relative;
  .welcome {
    button.create-invoice {
      border-radius: 7px;
      height: 65px;
      min-width: 270px;
      font-family: "SourceSansPro SemiBold", sans-serif;
      margin: 0 auto;
      display: block;
      span {
        font-size: 19px;
        display: flex;
        align-items: center;
      }
    }
    h1 {
      text-align: center;
      color: #5c7497;
      margin-bottom: 27px;
      font-size: 34px;
      font-family: "SourceSansPro SemiBold", sans-serif;
    }
    p {
      text-align: center;
      color: #5c7497;
      line-height: 1.6;
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 60px;
      font-size: 16px;
    }
    img {
      margin-right: 5px;
    }
  }
  .stamp {
    position: absolute;
    left: 30px;
    bottom: 30px;
    color: #fff;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
    img {
      margin-bottom: 5px;
    }
  }
}
section#with-documents {
  background-image: url("../../img/dashboard/with-docs-bg.jpg");
  background-size: cover;
  background-position: bottom center;
  margin: -25px;
  padding: 25px;
  flex: 1;
  min-height: 580px;
  padding-bottom: 220px;
  .documents-holder,
  .transactions-holder,
  .statistics-holder {
    background: #ffffff;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    img[alt="loading"] {
      margin: auto;
    }
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      .left {
        max-width: calc(100% - 130px);
        padding-right: 10px;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          flex-shrink: 0;
          height: 59px;
        }
        .r {
          b {
            color: #42424e;
            font-size: 16px;
            display: block;
            margin-bottom: 2px;
          }
          p {
            color: #777777;
            font-size: 14px;
            line-height: 1;
          }
        }
      }
    }
    table.type-outer {
      thead {
        tr {
          border-bottom: 10px solid #fff;
        }
      }
      tbody{
        tr{
          .options-menu-button{
            min-width: 40px;
            border-radius: 50%;
            padding: 0;
          }
        }
      }
    }
  }
  .top {
    display: flex;
    margin-bottom: 20px;
    .documents-holder {
      flex: 0.6;
    }
    .transactions-holder {
      flex: 0.4;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      .buttons {
        display: flex;
        align-items: center;
        button {
          margin-left: 10px;
        }
      }
      table.type-outer {
        tbody {
          tr {
            &.income {
              td {
                background-color: #e5ffc5;
              }
            }
            &.expense {
              td {
                background-color: #ffe2d9;
              }
            }
          }
        }
      }
      .no-data {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 1;
        b {
          color: #42424e;
          font-size: 16px;
          display: block;
          text-align: center;
          line-height: 1;
          margin-bottom: 10px;
        }
        p {
          color: #777777;
          font-size: 14px;
          line-height: 1;
          text-align: center;
          margin-bottom: 20px;
        }
        .buttons {
          justify-content: center;
        }
      }
    }
  }
  .transactions-holder {
    table.type-outer {
      tbody {
        tr {
          td {
            border-bottom: 1px solid #ebebeb;
            cursor: default;
          }
        }
      }
    }
  }
  &.with-chart {
    background-image: url("../../img/dashboard/with-chart-bg.jpg");
    padding-bottom: 55px;
  }
}

section#with-documents,
.right-form-holder{
  .statistics-holder {
    position: relative;
    z-index: 1;
    .chart {
      height: 340px;
      display: flex;
      flex-direction: column;

      .head {
        display: flex;
        align-items: flex-start;
        > div {
          max-width: calc(100% - 400px);
        }
        .title {
          color: #4a4a55;
          font-weight: 600;
          font-size: 16px;
        }

        .subtitle {
          color: #888888;
          font-size: 14px;
        }

        .dates {
          margin-left: auto;
          display: flex;

          .btn {
            background: #c7e5ff;
            color: #0e61dd;
            font-weight: 600;
            font-size: 14px;
            margin-left: 10px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            .col {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              .period-new {
                padding-left: 0 !important;
                width: 100% !important;
                height: 100% !important;
                position: absolute;
                left: 0;
                top: 0;
                background: initial;
                z-index: 91;
                .option {
                  opacity: 0;
                }
                .period-options {
                  left: auto;
                  right: calc(100% + 5px);
                  top: -80px;
                }
              }
            }
            img {
              width: 16px;
            }

            &.active,
            &:hover {
              background: #0e61dd;
              color: #fff;
              >img{
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(261deg) brightness(105%) contrast(102%);
              }
            }
          }
        }
      }

      .legend {
        display: flex;
        justify-content: flex-end;
        margin-top: -60px;
        padding-right: 200px;
        .item {
          margin-left: 30px;

          .label {
            display: flex;
            align-items: center;

            .color {
              width: 10px;
              height: 10px;
              display: inline-block;
              background: #eee;
              border-radius: 50%;
              margin-right: 5px;
            }

            .text {
              color: #d5dadf;
              font-size: 14px;
            }
          }

          .value {
            text-align: right;
          }
        }
      }

      .filter {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        .category {
          width: 250px !important;
        }
      }

      .graph {
        position: relative;
        margin-top: auto;
        margin-bottom: 20px;

        height: 250px;

        .cancel {
          padding: 3px 5px;
          height: auto;
          position: absolute;
          top: -3px;
          z-index: 1;

          .arrow {
            color: #42424e !important;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}
