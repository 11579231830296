$primaryColor: #374470;
$primaryParagraphColor: #777777;
$primaryColorDark: #42424e;

.ck-body-wrapper {
  position: relative;
  z-index: 9999;
}
.popup-primary {
  width: 95%;
  // max-height: 90vh;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 50px;
  opacity: 0;
  transition: 0.3s;
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  // background-image: url("../img/modals/bg.png");
  // background-position: top right;
  // background-repeat: no-repeat;
  // background-size: 300px auto;
  &.show {
    opacity: 1;
    margin-top: 0;
  }
  >.head {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: $primaryColorDark;
    font-family: "SourceSansPro Semibold", sans-serif;
    padding: 20px;
    .logo {
      margin-right: 5px;
    }
    .document-icon {
      margin-right: 10px;
    }
    .close-icon {
      margin-left: auto;
      padding-left: 10px;
      cursor: pointer;
    }
  }

  .body {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    h3 {
      color: $primaryColorDark;
      font-size: 20px;
      margin-bottom: 5px;
      font-family: "SourceSansPro Semibold", sans-serif;
      font-weight: 600;
      &.head{
        display: flex;
        align-items: center;
        .close-icon {
          margin-left: auto;
          padding-left: 10px;
          cursor: pointer;
        }
      }
    }

    .functionality-access {
      .company {
        color: $primaryColorDark;
        font-size: 15px;
        margin-bottom: 10px;
        b {
          font-family: "SourceSansPro Bold", sans-serif;
        }
      }
      .col {
        p {
          font-size: 15px;
          color: $primaryColorDark;
          b {
            font-family: "SourceSansPro Bold", sans-serif;
          }
        }
      }
      .access {
        padding-left: 15px;
      }
    }

    ul.mini-documents {
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(100% / 6 - 20px);
        // aspect-ratio: 1/1.4;
        margin: 5px 10px;
        cursor: pointer;
        canvas {
          border: 1px solid #eee;
        }
        &.active {
          cursor: default;
          canvas {
            border: 1px solid $primaryColorDark;
          }
        }
      }
    }
    hr.doc-separator {
      margin: 10px;
      margin-top: 15px;
    }
    .col {
      > #my-pdf {
        height: 537px;
        width: 380px;
        background-color: rgb(235, 235, 235);
        canvas {
          border: 1px solid #eee;
          min-height: 537px;
          min-width: 380px;
        }
      }
    }
    &:not(.custom) {
      .validate {
        border: none;
        canvas {
          border: 1px solid #f44336 !important;
        }
      }
      p {
        color: #777777;
        font-size: 15px;
        &.docs-heading {
          margin: 10px;
          margin-top: 0;
          color: $primaryColorDark;
        }
      }
    }
    .ck-content {
      p {
        color: #2c2c2c;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 15px;
    background-color: #f4f7fa;
    button {
      margin: 0 5px;
      box-shadow: none;
      &.cancel {
        padding: 0 10px;
        background-color: #fff;
        border: 1px solid #aeb7c9;
        span {
          color: $primaryColorDark;
        }
      }
      &.remove {
        background-image: none;
        padding-left: 20px;
      }
    }
  }

  &.view-license {
    max-width: 520px;
    position: relative;
    .body {
      padding: 35px;
      h6 {
        font-family: "SourceSansPro Regular", sans-serif;
        font-weight: 400;
        font-size: 16px;
        margin-top: 10px;
        color: #404d63;
      }
      p {
        font-size: 12px;
        font-family: "SourceSansPro Regular", sans-serif;
        color: #404d63;
      }
      hr {
        margin: 15px 0 !important;
      }
      span.label {
        font-family: "SourceSansPro Regular", sans-serif;
        font-size: 14px;      
      }
      b {
        font-family: "SourceSansPro SemiBold", sans-serif;
        font-weight: 500;
        font-size: 18px;
      }
      span.label,
      b {
        color: #404d63;
      }
    }
  }

  &.add-edit-company {
    > .head {
      background: linear-gradient(
        180deg,
        #82a3ca 0%,
        #86a7cc 48.44%,
        #98b8d5 100%
      );
      border-radius: 4px;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      h2 {
        font-size: 24px;
        color: #fff;
        margin-bottom: 5px;
        line-height: 1;
        text-align: left;
        width: 100%;
        padding-right: 40px;
      }
      p {
        font-size: 14px;
        color: #d4e6fa;
      }
      img.close-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        background: #c8def9;
        width: 25px;
        height: 25px;
        object-fit: scale-down;
        object-position: center;
        padding: 5px;
        border-radius: 4px;
      }
    }
    .body {
      position: relative;
      min-height: 400px;
      padding-top: 30px;
      h3 {
        text-align: center;
        line-height: 1;
        margin-bottom: 10px;
      }
      p {
        color: #7b83aa;
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 15px;
      }
      .holder {
        position: relative;
        z-index: 3;
        &.disabled {
          filter: blur(3px);
        }
      }
      .eik-input {
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 15px;
        position: relative;
        img.loader,
        img.check {
          position: absolute;
          z-index: 3;
        }
        img.loader {
          left: 5px;
          width: 30px;
          height: 30px;
          top: calc(50% - 30px / 2);
        }
        img.check {
          right: 10px;
          width: 18px;
          height: 13px;
          top: calc(50% - 13px / 2);
        }
        .MuiInputLabel-filled.MuiInputLabel-marginDense {
          transform: translate(105px, 17px) scale(1);
          transition: 0.2s;
        }
        .MuiInputLabel-shrink {
          transform: translate(115px, 7px) scale(0.75) !important;
          text-align: center;
        }
        input {
          text-align: center;
        }
        .dropdown {
          width: 430px;
        }
      }
      .company-field-container {
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      ul.data-list {
        position: relative;
        z-index: 3;
        width: 100%;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 15px;
        li {
          font-size: 15px;
          display: flex;
          align-items: center;
          color: $primaryColor;
          width: 100%;
          margin-bottom: 5px;
          span {
            display: block;
            width: 120px;
            flex-shrink: 0;
            color: $primaryParagraphColor;
            text-align: right;
            padding-right: 10px;
          }
          i {
            display: inline-block;
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: normal;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      button.edit-mode {
        position: relative;
        z-index: 3;
        background-color: #fff;
        border-radius: 20px;
        color: #0e61dd;
        font-size: 15px;
        height: 40px;
        padding: 0 25px;
        display: block;
        margin: 0 auto;
        &:hover {
          color: #0270d1;
        }
      }
      > .row {
        position: relative;
        z-index: 1;
      }
      &:not(.filled)::before {
        content: "";
        width: 100%;
        height: 300px;
        background-image: url("../img/login/register-bottom-shape.png");
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        pointer-events: none;
      }
    }
  }

  &.sign {
    .ck-content {
      height: 300px;
      border: 1px solid #e2e2e1;
      border-radius: 4px;
      background-color: #fff;
    }
    .upload {
      padding: 0;
      position: relative;
      picture {
        width: 100%;
        height: 100%;
        display: block;
        max-height: 100%;
        cursor: pointer;
        min-height: 50px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          max-height: 300px;
          object-fit: cover;
          object-position: center;
        }
      }
      h6 {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.add-edit-user {
    max-width: 800px;
    .body {
      .row {
        .col {
          b {
            display: block;
            margin-bottom: 10px;
            color: #0e61dd;
            font-size: 16px;
            font-family: "SourceSansPro Semibold", sans-serif;
          }
          .holder {
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            padding: 15px;
          }
        }
      }
    }
  }

  &.question,
  &.send-success {
    img.main-image {
      margin: 0 auto;
      display: block;
      transform: translateX(-10px);
    }
    .body {
      h3,
      p {
        text-align: center;
      }
    }
    .footer {
      justify-content: center;
    }
  }

  &.update {
    background-image: url("../img/modals/update-bg.jpg");
    background-size: cover;
    background-position: center;
    .body {
      padding: 40px 0;
      .top {
        display: flex;
        padding: 0 95px;
        margin-bottom: 30px;
        .right {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          padding-top: 40px;
          h2,
          b,
          p {
            color: $primaryColorDark;
            font-family: "SourceSansPro Bold", sans-serif;
            line-height: 1;
          }
          h2 {
            font-size: 36px;
          }
          b {
            font-size: 21px;
          }
          p {
            font-size: 14px;
            margin-top: auto;
          }
        }
      }
      ul.bottom {
        padding: 0 60px;
        li {
          margin-bottom: 15px;
          list-style: disc;
          b {
            color: $primaryColorDark;
            display: block;
            font-size: 16px;
            margin-bottom: 2px;
          }
          p {
            color: #777777;
            font-size: 14px;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.change-password,
  &.view-license {
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      z-index: 2;
    }
  }

  &.change-password {
    position: relative;
    p {
      margin-bottom: 20px;
    }
    .row {
      .MuiFormControl-root {
        width: 100%;
      }
    }
    .footer {
      button {
        width: fit-content;
      }
    }
  }

  &.add-product-modal {
    overflow: hidden;
    .body {
      .head {
        padding: 0;
      }
      p {
        padding-right: 50px;
        margin-bottom: 20px;
      }
      ul.categories {
        li {
          position: relative;
          margin-bottom: 10px;
          min-height: 40px;
          .MuiAutocomplete-root,
          .MuiTextField-root {
            margin-left: 12px;
            width: calc(100% - 12px);
            .MuiInputBase-root {
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
              box-shadow: none;
              border-color: none;
              padding-top: 9px;
            }
            .MuiInputLabel-filled.MuiInputLabel-marginDense {
              transform: translate(12px, 12px) scale(1) !important;
            }
            .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
              transform: translate(12px, 3px) scale(0.75) !important;
            }
            input {
              height: 19px;
              padding-bottom: 1px;
              padding-top: 8px;
              font-size: 13px;
            }
          }
          span.color {
            background-color: #7979ff;
            width: 12px;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 2;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            input {
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
          &:not(.product):after {
            content: "";
            width: 15px;
            height: 17px;
            background-image: url("../img/documents/icons/level-down-arrow.png");
            position: absolute;
            top: calc(100% + 19px);
            left: 6px;
          }
          &.add {
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              background-color: #d8f4e2;
              width: 100%;
              span {
                color: #3bc870;
                display: flex;
                align-items: center;
                img {
                  margin-right: 5px;
                }
              }
            }
          }
          &.product {
            display: flex;
            align-items: center;
            color: $primaryColorDark;
            font-family: "SourceSansPro SemiBold", sans-serif;
            font-size: 18px;
          }
          &.with-vat {
            display: flex;
            button {
              background-color: #f0f7fc;
              border-radius: 4px;
              font-size: 15px;
              color: $primaryColorDark;
              min-width: 75px;
              padding: 0 10px;
              text-align: center;
              flex-shrink: 0;
              margin-left: 10px;
            }
          }
        }
      }
      ul.bradcrumbs {
        display: flex;
        flex-wrap: wrap;
        li {
          font-size: 13px;
          color: #777777;
          margin-right: 5px;
          display: flex;
          align-items: center;
          img {
            margin-left: 5px;
          }
        }
      }
    }
    &.settings-category-modal {
      .body {
        b {
          color: $primaryColorDark;
          font-size: 22px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          display: block;
          margin-bottom: 10px;
        }
        ul.categories {
          li {
            .MuiTextField-root {
              height: 40px;
            }
            span.color {
              height: calc(100% - 1px);
            }

            &:not(.product) {
              &:after {
                top: 50%;
                transform: translateY(-50%);
                left: -25px;
              }
            }
            .unfocused-field {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: #fafafa;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-left: 20px;
              padding-right: 35px;
              border-radius: 4px;
              p {
                margin-bottom: 0;
                color: $primaryColorDark;
              }
              i {
                color: #b4bfca;
                font-style: normal;
                font-size: 14px;
                padding-left: 10px;
              }
              img {
                position: absolute;
                right: 10px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &.add-accountant-modal {
    .head {
      padding: 0;
    }
    .fields {
      padding-top: 20px;
      .col {
        .with-create {
          padding-left: 8px;
          margin-bottom: auto;
          p {
            margin-left: -8px;
            width: calc(100% + 8px);
            padding-top: 5px;
          }
        }
        b {
          font-size: 16px;
          color: #0e61dd;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }

  &.send-to-accountant-modal {
  }

  &.export-modal {
    .head {
      padding: 0;
    }
    p {
      margin-bottom: 30px;
    }
    .filters {
      background: #f0f7fc;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 30px;
      b {
        color: $primaryColorDark;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
        font-family: "SourceSansPro SemiBold", sans-serif;
      }
      table {
        td {
          color: #a1b1bf;
          font-size: 14px;
          padding-bottom: 5px;
          span {
            color: #3e3e49;
            margin-left: 5px;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        tr:last-of-type {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
    .total-export {
      color: #0e61dd;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      span {
        margin-left: 5px;
        padding: 2px 5px;
        background: #0e61dd;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        font-family: "SourceSansPro SemiBold", sans-serif;
      }
    }
    ul.documents-list {
      padding: 20px 0;
      li {
        color: $primaryColorDark;
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
    }
    .footer {
      button.back {
        border: none;
        background-color: transparent;
        color: $primaryColorDark;
        margin-right: auto;
        img {
          margin-right: 5px;
        }
      }
      button.save {
        img {
          margin-right: 5px;
        }
        &.d,
        &.s {
          max-width: 120px;
        }
      }
    }
  }

  .align-right {
    justify-content: flex-end;
  }
  .align-center {
    justify-content: center;
  }

  .table-scroller {
    max-height: 400px;
  }
  .scrollable {
    max-height: 400px;
    overflow-y: scroll;
    // overflow-y: overlay;
    overflow-x: hidden;
    padding: 10px;
  }
}

.popup-primary.show {
  opacity: 1;
}

.large {
  max-width: 805px;
}
.extra-large {
  max-width: 1300px;
}
.medium-large {
  max-width: 1000px;
}
.medium {
  max-width: 600px;
}
.small {
  max-width: 460px;
}
.col.no-border {
  .MuiInputBase-root:not(.Mui-focused) {
    border-color: transparent;
  }
}

.complex-modal {
  border-radius: 4px;
  .header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 25px;
    min-height: 132px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    b {
      display: block;
      font-size: 25px;
      font-family: "SourceSansPro Semibold", sans-serif;
      font-weight: 600;
      margin-bottom: 7px;
    }
    p {
      font-size: 15px;
      line-height: 1.1;
    }
    &.purple {
      background-color: #dfd7ff;
      b {
        color: #71699e;
      }
      p {
        color: #8b7bb5;
      }
    }
    &.sky-blue {
      background-color: #d1e8ff;
      b {
        color: #4d6a8c;
      }
      p {
        color: #798fb0;
      }
    }
    &.light-brown {
      background-color: #e8dfc9;
      b {
        color: #b08b60;
      }
      p {
        color: #c09868;
      }
    }
  }
  .footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &.price-modal {
    .header {
      &::after {
        content: "";
        width: 145px;
        height: 145px;
        background-image: url("../img/articles/price-tag.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 31px;
        top: 7px;
      }
    }
  }
  &.subscription-modal {
    .header {
      &::after {
        content: "";
        width: 185px;
        height: 129px;
        background-image: url("../img/subscriptions/list.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        top: 7px;
      }
    }
  }
  &.bill-modal {
    .header {
      &::after {
        content: "";
        width: 195px;
        height: 134px;
        background-image: url("../img/accounts/cart.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 31px;
        top: 15px;
      }
    }
  }
  &.cargo-modal {
    .header {
      &::after {
        content: "";
        width: 352px;
        height: 155px;
        background-image: url("../img/storage/cargo.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 31px;
        top: 15px;
      }
    }
  }
  &.cargo-2-modal {
    .header {
      &::after {
        content: "";
        width: 371px;
        height: 125px;
        background-image: url("../img/storage/cargo-2.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 31px;
        top: 18px;
      }
    }
  }
  &.revision-modal {
    .header {
      &::after {
        content: "";
        width: 97px;
        height: 130px;
        background-image: url("../img/storage/search.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
  &.customer-modal {
    .header {
      &::after {
        content: "";
        width: 149px;
        height: 149px;
        background-image: url("../img/customers/customer.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        top: 5px;
      }
    }
  }
  &.campaign-modal {
    .header {
      &::after {
        content: "";
        width: 140px;
        height: 145px;
        background-image: url("../img/customers/campaign.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        top: 5px;
      }
    }
    b {
      color: $primaryColorDark;
      font-family: "SourceSansPro Semibold", sans-serif;
      display: block;
      margin-bottom: 5px;
    }
    button.date-and-time {
      margin: 0 5px;
      height: 30px;
      flex: 1;
      position: relative;
      border: 1px solid #dde6ed;
      img {
        margin-right: 5px;
      }
      .MuiFormControl-root {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        button {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
    ul.campaingn-clients {
      li {
        position: relative;
        button.remove-client {
          min-width: initial;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #ff4e77;
          position: absolute;
          right: 2px;
          top: -2px;
          border-radius: 50%;
          cursor: pointer;
          padding: 0;
          opacity: 0;
          pointer-events: none;
          img {
            width: 8px;
            height: 8px;
            object-fit: contain;
          }
        }
        &:hover {
          button.remove-client {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
    button.add-client {
      width: 32px;
      height: 32px;
      min-width: initial;
      border-radius: 50%;
      border: 1px solid #aeb7c9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      &.no-margin {
        margin-left: 0;
      }
    }
    button.remove-all-clients {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      height: 32px;
      img {
        flex-shrink: 0;
        margin-right: 5px;
      }
      span {
        color: #ff4e77;
      }
    }
    .row {
      .col {
        .template-holder {
          flex: 1;
        }
      }
    }
    .campaign-form-template {
      display: flex;
      flex-direction: column;
      background-color: #f8f8fb;
      padding: 15px;
      textarea.heading,
      input.heading {
        border: none;
        resize: none;
        text-align: center;
        border: 2px dashed #b4bfca;
        border-radius: 2px;
        width: 100%;
        font-size: 15px;
        padding: 10px;
        &.heading-1,
        &.heading-3 {
          font-family: "SourceSansPro Semibold", sans-serif;
        }
        &.heading-1 {
          font-size: 24px;
        }
        &.heading-3 {
          font-size: 20px;
        }
      }
      img.event-type-icon {
        filter: brightness(0) saturate(100%) invert(25%) sepia(96%)
          saturate(1560%) hue-rotate(203deg) brightness(98%) contrast(104%);
      }
      .simple-select {
        border-radius: 2px;
        background-color: #edf4fa;
        height: 49px;
        width: 30px;
        min-width: initial;
        flex-shrink: 0;
        margin-left: 10px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        cursor: pointer;
        position: relative;
        span {
          text-transform: capitalize;
          color: $primaryColorDark;
        }
        &.margin-left {
          margin-left: 10px;
        }
        &.margin-right {
          margin-right: 10px;
        }
        .MuiFormControl-root {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
            height: 100%;
          }
          button {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
          }
        }
      }
      .answer-holder {
        position: relative;
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .type {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 30px;
          background-color: #dde6ed;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        input {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          border: 1px solid #dde6ed;
          padding-left: 25px;
          color: $primaryColorDark;
          &::placeholder {
            color: #b4bfca;
            font-size: 15px;
          }
        }
      }
      button.add {
        width: fit-content;
        .options-menu-button {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      button.remove-group,
      button.remove-question,
      button.remove-answer {
        position: absolute;
        right: -7px;
        top: -7px;
        background-color: #ff4e77;
        width: 20px;
        height: 20px;
        min-width: initial;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        img {
          width: 11px;
          object-fit: contain;
        }
      }

      button.remove-answer {
        position: static;
        margin-left: 10px;
        border-radius: 4px;
        height: 40px;
        width: 30px;
        img {
          width: 15px;
        }
      }
      .campaign-form-template-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px solid #9b9b9b;
        border-left: 5px solid #9b9b9b;
        border-radius: 4px;
        padding: 15px;
        position: relative;
        .campaign-form-template-question {
          width: 100%;
          border: 1px solid #9b9b9b;
          border-left: 5px solid #9b9b9b;
          border-radius: 4px;
          padding: 15px;
          position: relative;
          .row {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &.complaint-modal {
    .header {
      &::after {
        content: "";
        width: 123px;
        height: 148px;
        background-image: url("../img/customers/complaint.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        top: 5px;
      }
    }
    .body {
      flex-direction: column;
      .multiline-holder {
        flex: 1;
        .col {
          flex: 1;
          .MuiFormControl-root {
            flex: 1;
            .MuiInputBase-root {
              flex: 1;
            }
          }
        }
      }
    }
  }

  &.trade-object-form-modal {
    .header {
      &::after {
        content: "";
        width: 157px;
        height: 115px;
        background-image: url("../img/customers/store.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 20px;
        top: 30px;
      }
    }
  }
}

.add-client-popover {
  .MuiPaper-root {
    width: 250px;
    padding: 10px;
    .MuiFormControl-root {
      margin-bottom: 10px !important;
    }
    ul {
      max-height: 200px;
      overflow: auto;
      flex: 1;
      li {
        padding: 5px;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

.calendar-note {
  .body {
    > p {
      margin-bottom: 20px;
    }
    h6 {
      color: #0e61dd;
      font-size: 17px;
      font-family: "SourceSansPro Semibold", sans-serif;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .field {
      padding: 10px;
      border: 1px solid #dde6ed;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 10px;
      img.event-type-icon {
        filter: brightness(0) saturate(100%) invert(25%) sepia(96%)
          saturate(1560%) hue-rotate(203deg) brightness(98%) contrast(104%);
      }
      .simple-select {
        border-radius: 2px;
        background-color: #edf4fa;
        height: 26px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        cursor: pointer;
        width: fit-content;
        min-width: initial;
        position: relative;
        span {
          text-transform: capitalize;
          color: $primaryColorDark;
        }
        &.margin-left {
          margin-left: 10px;
        }
        &.margin-right {
          margin-right: 10px;
        }
        .MuiFormControl-root {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
            height: 100%;
          }
          button {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
          }
        }
      }
      .name-and-type-row {
        border-bottom: 1px solid $primaryColorDark;
        align-items: center;
        margin-top: -5px;
        .MuiInput-underline {
          &::before,
          &::after {
            display: none;
          }
        }
        .MuiInputLabel-formControl {
          transform: translate(0, 17px) scale(1);
        }
        .MuiInputLabel-shrink {
          transform: translate(0, 9.5px) scale(0.75);
        }
      }
    }
    .row {
      align-items: center;
      .MuiAutocomplete-root {
        width: 100%;
        .MuiFilledInput-root {
          background-color: #fff;
          border: 1px solid #e2e2e1;
          // overflow: hidden;
          border-radius: 4px;
          &::before,
          &::after {
            display: none;
          }
        }
        // .chip-holder {
        //   display: flex;
        //   flex-wrap: wrap;
        .chip {
          padding: 5px 10px;
          background-color: #f9f9f9;
          border: 1px solid #e6e6e6;
          border-radius: 20px;
          color: #00a8ff;
          display: flex;
          align-items: center;
          margin-right: 5px;
          margin-bottom: 5px;
          img.remove {
            cursor: pointer;
            margin-left: 2px;
            flex-shrink: 0;
            width: 18px;
            object-fit: contain;
          }
        }
        // }
      }
      button.remove-note {
        display: flex;
        align-items: center;
        width: fit-content;
        img {
          flex-shrink: 0;
          margin-right: 5px;
        }
        span {
          color: #ff4e77;
        }
      }
    }
  }
}

.complaints {
  table {
    tbody {
      tr {
        td {
          span.status {
            width: 8px;
            height: 20px;
            border-radius: 2px;
            display: block;
            margin-right: 5px;
            background-color: $primaryColorDark;
          }
        }
      }
    }
  }
}

.pipelines-item {
  .body {
    .status-row {
      .col {
        position: relative;
        padding-left: 5px;
        span.color-indicator {
          height: 100%;
          width: 12px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
  }
}

.marketing-archive,
.pipelines-item {
  .body {
    > p {
      margin-bottom: 20px;
    }
    .unchangable-row {
      span.label {
        color: #b4bfca;
        font-size: 13px;
      }
      b {
        color: $primaryColorDark;
        font-family: "SourceSansPro Semibold", sans-serif;
        display: block;
        text-transform: capitalize;
      }
    }
  }
}

.preview-article-modal,
.article-form-modal,
.bill-modal,
.new-bill,
.preview-bill,
.storage-load-form-modal,
.preview-storage-load-modal,
.customer-modal,
.campaign-modal,
.complaint-modal,
.subscription-modal .trade-object-form-modal {
  h6 {
    color: #0e61dd;
    font-size: 17px;
    font-family: "SourceSansPro Semibold", sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .field {
    padding: 10px;
    border: 1px solid #dde6ed;
    border-radius: 4px;
    width: 100%;
  }
  ul.list-of-links {
    li {
      color: #0189ff;
      padding: 5px;
      padding-right: 20px;
      border-bottom: 1px solid #dde6ed;
      font-size: 15px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #fafafa;
      }
      &::after {
        content: "";
        width: 8px;
        height: 14px;
        background-image: url("../img/customers/icons/right-arrow.png");
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  > .body {
    padding-left: 10px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    // flex-direction: column;
    .right-form-holder {
      padding: 23px 0;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      flex: 1;
      .options-bar {
        background-color: #edeef7;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        button:not(.add) {
          margin-right: 10px;
          border: none;
          padding: 10px 15px;
          img {
            margin-right: 5px;
          }
        }
        button.add {
          margin-left: auto;
        }
      }
      .preview-article {
        .top {
          margin-bottom: 30px;
          .images {
            flex-shrink: 0;
            ul.gallery {
              flex: 1;
              display: flex;
              margin-right: -5px;
              width: calc(100% + 5px);
              overflow: auto;
              li {
                width: calc(100% / 3 - 5px);
                margin-right: 5px;
                margin-bottom: 5px;
                height: 55px;
                flex-shrink: 0;
              }
            }
          }
          .data {
            padding-left: 35px;
            flex: 1;
            h3 {
              font-size: 26px;
              margin-bottom: 1px;
              font-family: "SourceSansPro Semibold", sans-serif;
              font-weight: 600;
            }
            h4 {
              font-size: 16px;
              color: $primaryColorDark;
              font-family: "SourceSansPro Semibold", sans-serif;
              font-weight: 600;
              margin-bottom: 10px;
            }
            > .row {
              justify-content: space-between;
            }
          }
        }
        table {
          width: fit-content;
          tr {
            td {
              color: $primaryColorDark;
              font-size: 15px;
              padding-bottom: 3px;
              &:nth-child(odd) {
                color: #b4bfca;
                padding-right: 5px;
                font-size: 13px;
              }
            }
            &:last-of-type {
              td {
                padding-bottom: 0;
              }
            }
          }
        }
        p {
          color: $primaryColorDark;
          font-size: 15px;
        }
      }
      .article-categories {
        padding: 15px 0;
        .category-row-holder {
          display: flex;
          align-items: center;
          padding: 5px 0;
          position: relative;
          padding-right: 35px;
          width: fit-content;
          .category-folder-indicator {
            margin-right: 3px;
            margin-bottom: 3px;
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 49px;
            flex-shrink: 0;
            span.sign-indicator {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 2px solid $primaryColorDark;
              width: 18px;
              height: 18px;
              border-radius: 2px;
              margin-right: 5px;
              font-weight: 600;
              font-size: 18px;
              flex-shrink: 0;
              position: relative;
              &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: $primaryColorDark;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
              }
              &.minus::before {
                content: "-";
                padding-bottom: 4px;
                font-size: 24px;
              }
              &.plus::before {
                content: "+";
                padding-bottom: 1px;
              }
            }
          }
          .MuiFormControlLabel-root {
            margin-right: 0;
          }
          .master {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: 1px solid #0189ff;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .preview-article-home {
        table:not(.type-doc) {
          &,
          * {
            border: none;
          }
          td {
            text-align: left;
            font-size: 15px;
            padding: 3px 10px;
            padding-right: 40px;
            padding-left: 0;
            &.th {
              background: transparent;
              text-align: left;
              padding-right: 5px;
              color: #b4bfca;
              font-size: 13px;
            }
          }
        }
        table.type-doc {
          &,
          * {
            border: none;
          }
          th {
            padding-bottom: 25px;
          }
        }
      }
      .preview-bill-history {
        table {
          tr.total {
            border: 1px solid #dde6ed;
            border-radius: 1px;
            td {
              background-color: #fafafa !important;
            }
          }
        }
      }
      .preview-bill-documents {
      }
    }
  }
}

.storage-load-form-modal,
.trade-object-form-modal {
  .body {
    padding: 20px;
  }
}

.customer-modal {
  .incomes-stats {
    position: absolute;
    bottom: 15px;
    right: 0;
    z-index: 2;
    display: flex;
    .incomes-badge,
    .unpaid-badge {
      height: 52px;
      min-width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 0 8px;
      img {
        margin-right: 5px;
      }
      b,
      div,
      span {
        font-family: "SourceSansPro Semibold", sans-serif;
        font-weight: 600;
      }
      b {
        font-size: 15px;
      }
      div {
        font-size: 24px;
        line-height: 1;
        span {
          font-size: 14px;
        }
      }
      * {
        color: #fff;
      }
    }
    .incomes-badge {
      background: linear-gradient(90deg, #3cd09e 0%, #63e184 100%);
    }
    .unpaid-badge {
      background: linear-gradient(90deg, #ff4b77 0%, #ff9a7b 100%);
      margin-left: 10px;
    }
  }
}

.trade-object-form-modal {
  .body {
    > .col {
      height: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .popup-primary {
    .head {
      position: relative;
      .close-icon {
        position: absolute;
        bottom: 100%;
        left: 100%;
        padding-left: 0;
      }
    }
  }
}
