#category {
    .categories-tree {
       
        position: relative;
        overflow-y: auto;
        // margin-right: 10px;
        // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.45);

        background-image:

            /* Shadows */
            linear-gradient(to right, white, white),
            linear-gradient(to right, white, white),
            /* Shadow covers */
            linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;

        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .selectable {
            .rst__moveHandle {
                min-width: 44px;
                // min-width: 32px;
                // width: 32px;
                // height: 32px;
            }

            .rst__rowContents {
                cursor: pointer;
                margin: 0;
                padding: 0;
                // min-width: 120px;
                min-width: auto;
                background-color: initial;

                .rst__rowLabel {
                    padding-right: 0px;
                    margin: 0;
                    height: 100%;
                    width: 100%;

                    .row {
                        align-items: center;
                        margin-right: 0px;
                        margin-left: 0px;
                        height: 100%;
                        width: 100%;

                        a {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
        
    }

    .middle-card {
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .buttons {
                .sort-button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    min-width: 130px;
                }
            }
        }

        .category-card-list {
            &.delete {
                background: rgb(245, 218, 218);
            }

            .alert {
                margin-bottom: 0;
            }

            .category-article-list {
                display: flex;
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;
                padding: 0;
                margin: 0;

                .file-container {
                    // width: calc(100% / 8 - 5px);
                    margin-left: 5px;
                    margin-bottom: 5px;
                    margin-bottom: 5px;
                    width: calc(100% / 8 - 5px);

                    li {
                        // margin-left: 5px;
                        // margin-bottom: 5px;
                        // width: 100%;
                        height: 100%;
                        list-style: none;
                        background-color: rgba(233, 235, 255, .6);
                        border: 1px solid rgb(187, 193, 245);
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        flex-direction: column;

                        &.delete-checked {
                            background: linear-gradient(rgb(241, 170, 170) 0%, rgb(255, 99, 99) 100%);
                        }

                        .picture {
                            width: 100%;
                            flex-shrink: 0;
                            display: block;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                display: block;
                                aspect-ratio: 1/1;
                            }
                        }

                        p {
                            background-color: rgba(255, 255, 255, 0.6);
                            text-align: center;
                            // position: absolute;
                            bottom: 0;
                            color: black;
                            font-weight: 900;
                            font-size: 10px;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            flex: 1;
                        }
                    }
                }
            }

            .middle-col-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    .articles-list {
        height: 600px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        ul {
            list-style: none;
            padding: 0;
            margin-right: 10px;

            li {
                padding: 0 10px;
                margin: 2px 0;
                cursor: pointer;
                border-radius: 2px;

                &:hover {
                    background-color: rgba(187, 193, 245, 0.6);
                }
            }
        }
    }
}