$primaryHeadingColor: #42424e;
$primaryParagraphColor: #777777;
$primaryParagraphFontSize: 15px;

ul.user-permissions {
  max-height: 200px;
  overflow: auto;
  padding-right: 15px;
  margin-right: -15px;
  width: calc(100% + 15px);
  li {
    .row,
    .MuiTypography-root {
      color: #42424e;
      font-size: 15px;
      font-family: "SourceSansPro Semibold", sans-serif;
    }
    ul.offices {
      padding-left: 20px;
      li {
        display: flex;
        flex-direction: column;
        background: #f8f8fb;
        border: 1px solid #dde7f2;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;
        .top {
          display: flex;
          align-items: center;
          position: relative;
          padding-right: 30px;
          &::after {
            content: "";
            width: 10px;
            height: 4.5px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("../../img/settings/icons/pointer.png");
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.2s;
          }
          &.active::after {
            transform: rotate(-90deg);
          }
        }
        .permissions {
          padding: 15px;
          padding-top: 0;
          align-items: flex-start;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

#settings {
  .no-data {
    margin: 40px 0;
    font-size: 15px;
    text-align: center;
  }
  #head {
    align-items: flex-start;
    align-items: center;
    .right {
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: normal;
      }
      ul {
        flex-direction: row;
        align-items: flex-start;
        li {
          a {
            display: flex;
            align-items: center;
            color: #aeb7c9;
            font-size: $primaryParagraphFontSize;
            margin-bottom: 5px;
            img {
              margin-right: 5px;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .settings-panel {
    background-color: #fff;
    border-radius: 3px;
    width: 100%;
    display: flex;
    hr {
      margin: 30px 0;
    }
    .heading {
      color: $primaryHeadingColor;
      font-size: 16px;
      font-family: "SourceSansPro SemiBold", sans-serif;
      margin-bottom: 15px;
    }
    #head {
      padding: 0;
    }
    .panel {
      padding: 0;
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .settings-body {
      > .row {
        > .col {
          margin-right: 30px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      .my-companies {
        display: flex;
        button.license {
          height: 26px;
          border: 1px solid #C6DAF8;
          background-color: #DCEAFF;
          min-width: 90px;
          span{
            color: #0E61DD;
            font-family: "SourceSansPro SemiBold", sans-serif;
            img{
              margin-right: 5px;
            }
          }
        }
        button.simple-add {
          margin-left: auto;
          background: rgba(255, 255, 255, 0);
          color: #3bc870;
          padding: 0;
          height: fit-content;
          padding-right: 5px;
          margin-right: -5px;
          padding-left: 35px;
          &::before {
            background-image: url("../../img/app/icons/add.png");
            width: 26px;
            height: 26px;
            transform: translateY(-50%);
            top: 50%;
            left: 5px;
          }
        }
        b {
          display: flex;
          align-items: center;
          color: #0e61dd;
          font-size: 15px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          margin-bottom: 20px;
          width: 100%;
        }
        input[type="text"]:not(.MuiInputBase-input) {
          color: $primaryHeadingColor;
          font-size: 20px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          border-top: none !important;
          border-left: none !important;
          border-right: none !important;
          border-radius: 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0);
          background-color: rgba(255, 255, 255, 0);
          min-height: 20px;
          width: 100%;
          &:hover,
          &:focus {
            border-color: $primaryHeadingColor;
          }
        }
        table {
          tr {
            td {
              padding-bottom: 2px;
              &:first-of-type {
                color: #b4bfca;
                font-size: 15px;
                flex-shrink: 0;
                width: fit-content;
                width: 1%;
                white-space: nowrap;
              }
              &:last-of-type {
                padding-left: 5px;
                input {
                  font-size: 15px !important;
                  font-family: "SourceSansPro Regular", sans-serif !important;
                }
              }
            }
            &:last-of-type {
              td {
                padding-bottom: 0;
              }
            }
          }
        }
        .left {
          width: calc(100% - 330px);
          padding-right: 60px;
          .main-data {
            display: flex;
            margin-bottom: 30px;
            picture {
              width: 135px;
              height: 135px;
              border-radius: 4px;
              position: relative;
              margin-right: 30px;
              background: #f9fcff;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
              flex-shrink: 0;
              display: block;
              cursor: pointer;
              p {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                text-align: center;
                color: #848484;
                line-height: 1;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: inherit;
              }
              &::after {
                content: "";
                position: absolute;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                top: 4px;
                left: 4px;
                border: 1px solid #fff;
                border-radius: inherit;
              }
              &::before {
                content: "";
                width: 37px;
                height: 37px;
                background-image: url("../../img/settings/icons/photo-with-shadow.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                bottom: -7px;
                right: -7px;
                z-index: 2;
                transition: 0.2s;
              }
              &:hover::before {
                transform: scale(0.8);
              }
            }
            .right-part {
              width: 100%;
              > input:not(.MuiInputBase-input) {
                margin-bottom: 15px;
                width: 100%;
              }
            }
          }
          ul.offices-list {
            margin-bottom: 20px;
            > li {
              background: #f8f8fb;
              border: 1px solid #dde7f2;
              border-radius: 4px;
              padding: 15px;
              margin-bottom: 15px;
              position: relative;
              b {
                color: #42424e;
                font-size: 20px;
                font-family: "SourceSansPro SemiBold", sans-serif;
                margin-bottom: 10px;
              }
              table {
                tr {
                  td {
                    font-size: 15px;
                    font-family: "SourceSansPro Regular", sans-serif;
                    span {
                      color: #42424e;
                    }
                  }
                }
              }
              button.remove,
              button.edit {
                width: 25px;
                height: 25px;

                top: 15px;
                position: absolute;
                padding: 0;
              }
              button.edit {
                background-image: url("../../img/settings/icons/edit.png");
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
                right: 45px;
                min-width: initial;
                background-size: 14px;
                &.is-last {
                  right: 15px;
                }
              }
              button.remove {
                background-size: 17px;
                right: 15px;
              }
              > input {
                margin-bottom: 15px;
                width: calc(100% - 70px);
              }
              .MuiInputBase-root,
              .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
                .MuiAutocomplete-input:first-child {
                padding: 0;
                width: fit-content;
              }
              .MuiOutlinedInput-notchedOutline {
                padding: 0;
                border: none;
                height: auto;
                min-height: initial;
              }
              .office-data {
                display: flex;
                align-items: flex-start;
                .col {
                  flex: 1;
                  padding-right: 10px;
                  ul.fields-list {
                    li {
                      margin-bottom: 5px;
                    }
                  }
                }
              }
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
          .row.delete {
            align-items: center;
            padding-top: 15px;
            .right {
              h6 {
                color: $primaryHeadingColor;
                font-size: $primaryParagraphFontSize;
                font-family: "SourceSansPro SemiBold", sans-serif;
              }
              p {
                color: $primaryParagraphColor;
                font-size: 13px;
              }
              padding-left: 30px;
            }
          }
        }
        > .right {
          width: 330px;
          padding-left: 20px;
          border-left: 1px solid #dce7f5;
          h3 {
            color: #b4bfca;
            font-size: 13px;
            text-transform: uppercase;
            margin-bottom: 5px;
            font-weight: normal;
            font-family: "SourceSansPro Regular", sans-serif;
            display: flex;
            align-items: center;
          }
          .bank-data {
            margin-bottom: 30px;
            ul {
              li {
                background: #f8f8fb;
                border: 1px solid #dde7f2;
                border-radius: 4px;
                padding: 15px;
                margin-bottom: 5px;
                cursor: pointer;
                .options {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;
                  color: #b4bfca;
                  font-size: 15px;
                  img {
                    flex-shrink: 0;
                    margin-right: 5px;
                    &.default {
                      background: #3bc870;
                      width: 20px;
                      height: 20px;
                      object-fit: scale-down;
                      border-radius: 50%;
                      padding: 4px;
                    }
                  }
                }
                table {
                  tbody {
                    tr {
                      td {
                        vertical-align: top;
                        font-size: 15px;
                        &:last-of-type {
                          color: #42424e;
                        }
                      }
                    }
                  }
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
          .MuiTypography-root {
            color: $primaryHeadingColor;
            font-size: 15px;
          }
        }
      }
    }
    ul.menu {
      width: 225px;
      border-right: 1px solid #edeef7;
      padding: 10px;
      display: flex;
      flex-direction: column;
      li {
        cursor: pointer;
        display: flex;
        font-size: $primaryParagraphFontSize;
        color: #aeb7c9;
        flex-direction: column;
        span {
          display: flex;
          align-items: center;
          position: relative;
          padding: 10px;
          border-radius: 2px;
          img {
            margin-right: 5px;
            width: 19px;
            object-fit: none;
          }
          &::before,
          &::after {
            content: "";
            height: 0px;
            width: 2px;
            transition: 0.2s;
            position: absolute;
            left: 3px;
            background-color: #388ad0;
          }
          &::before {
            top: 50%;
          }
          &::after {
            bottom: 50%;
          }
        }

        &.active {
          span {
            background-color: #fff !important;
            cursor: default;
            color: $primaryHeadingColor;
            img {
              filter: invert(24%) sepia(21%) saturate(1990%) hue-rotate(202deg)
                brightness(50%) contrast(88%);
            }
            &::before,
            &::after {
              height: calc(50% - 10px);
            }
          }
        }
        &:hover {
          span {
            background-color: #f8f8fb;
          }
        }
        ul.submenu {
          padding: 0;
          width: calc(100% + 10px);
          border: none;
          margin-right: -10px;
          li {
            padding: 0;
            padding-left: 21px;
            width: 100%;
            span {
              cursor: pointer;
              color: #aeb7c9;
              &::before,
              &::after {
                display: none;
              }
            }
            &.active,
            &:hover {
              span {
                color: $primaryHeadingColor;
              }
            }
            &.active {
              span {
                cursor: default;
              }
            }
          }
        }
      }
    }
    .content {
      width: calc(100% - 225px);
      padding: 30px;
    }
    #users {
      ul.users-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        > li {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 20px;
          background: #f3f3f7;
          border-radius: 4px;
          cursor: pointer;
          position: relative;
          margin-bottom: 15px;
          box-shadow: 0px 0px 71px rgba(53, 60, 87, 0);
          transition: 0.2s;
          picture {
            width: 87px;
            height: 87px;
            border-radius: 50%;
            position: relative;
            margin-right: 15px;
            background-color: #dde6ed;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
              border-radius: inherit;
            }
            &::after {
              content: "";
              position: absolute;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              top: 4px;
              left: 4px;
              border: 1px solid #fff;
              border-radius: inherit;
            }
          }
          .main {
            display: flex;
            flex-direction: column;
            margin-right: 15px;
            width: 250px;
            span.role {
              background: #bdccde;
              border-radius: 17px;
              height: 20px;
              padding: 0 10px;
              color: #fff;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              line-height: 1;
              width: fit-content;
            }
            h6 {
              color: $primaryHeadingColor;
              font-size: 20px;
              font-family: "SourceSansPro SemiBold", sans-serif;
              font-weight: normal;
              padding-right: 10px;
            }
          }
          .mail,
          .companies {
            margin-left: auto;
            width: 250px;
            span {
              display: flex;
              align-items: center;
              color: $primaryParagraphColor;
              font-size: $primaryParagraphFontSize;
              img {
                margin-right: 5px;
              }
            }
            p {
              color: #0e61dd;
              font-size: $primaryParagraphFontSize;
            }
          }
          .companies {
            transform: translateY(calc(50% - 20px));
            margin-left: 0;
            margin-right: 15px;
            width: 300px;
            span {
              margin-bottom: 2px;
            }
            ul {
              margin-left: 15px;
              padding-bottom: 15px;
              li {
                color: $primaryHeadingColor;
                font-size: 13px;
                list-style: disc;
                margin-bottom: 2px;
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
          > button.MuiButtonBase-root {
            position: absolute;
            top: 5px;
            right: 5px;
            min-width: initial;
            width: 40px;
            background-color: transparent;
            border-radius: 50%;

            .MuiButton-label {
              pointer-events: none;
            }
            &:hover {
              background-color: rgb(248, 248, 248);
            }
          }
          &:hover {
            background: #ffffff;
            box-shadow: 0px 0px 71px rgba(53, 60, 87, 0.33);
          }
        }
      }
    }
    #profile,
    #users {
      .functionality-access {
        width: 100%;
        h3.heading {
          background-color: #f3f3f3;
          padding: 5px 10px;
          border-radius: 1px;
          margin-bottom: 10px;
        }
        .col {
          padding-left: 10px;
        }
        p {
          color: $primaryParagraphColor;
          b {
            font-family: "SourceSansPro Bold", sans-serif;
          }
        }
      }
    }

    #user,
    #profile {
      form {
        .content {
          display: flex;
          flex-direction: column;
          width: 100%;
          > b {
            color: #0e61dd;
            font-size: $primaryParagraphFontSize;
            font-family: "SourceSansPro SemiBold", sans-serif;
            margin-bottom: 20px;
          }
          .top {
            display: flex;
            margin-bottom: 100px;
            .left {
              padding-right: 30px;
              width: calc(100% - 330px);
              .field {
                display: flex;
                align-items: center;
                position: relative;
                width: 100%;
                img {
                  position: absolute;
                  left: 5px;
                  top: 50%;
                  transform: translateY(-50%);
                  pointer-events: none;
                }
                input {
                  color: $primaryHeadingColor;
                  font-size: 20px;
                  font-family: "SourceSansPro SemiBold", sans-serif;
                  border: none;
                  border-radius: 0;
                  border: 1px solid #fff;
                  padding-left: 25px;
                  &:hover {
                    border-bottom: 1px solid $primaryHeadingColor;
                  }
                }
              }

              .top-part {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                picture {
                  width: 117px;
                  height: 117px;
                  border-radius: 50%;
                  position: relative;
                  margin-right: 15px;
                  display: block;
                  background-color: #eee;
                  cursor: pointer;
                  flex-shrink: 0;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    border-radius: inherit;
                  }
                  &::after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 12px);
                    height: calc(100% - 12px);
                    top: 5px;
                    left: 5px;
                    border: 1px solid #fff;
                    border-radius: inherit;
                  }
                  &::before {
                    content: "";
                    width: 37px;
                    height: 37px;
                    background-image: url("../../img/settings/icons/photo-with-shadow.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    transition: 0.2s;
                  }
                  &:hover::before {
                    transform: scale(0.8);
                  }
                }
                .right-part {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  span.role {
                    background: #bdccde;
                    border-radius: 17px;
                    height: 20px;
                    padding: 0 10px;
                    color: #fff;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    line-height: 1;
                    width: fit-content;
                    margin-bottom: 5px;
                  }
                  .field {
                    width: 100%;
                    input {
                      width: 100%;
                    }
                  }
                }
              }
              .bottom-part {
                p {
                  color: #b4bfca;
                  font-size: $primaryParagraphFontSize;
                  text-transform: uppercase;
                  margin-bottom: 20px;
                }
                ul {
                  li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    width: 100%;
                    span {
                      display: block;
                      width: 100px;
                      color: #b4bfca;
                      font-size: $primaryParagraphFontSize;
                    }
                    .field {
                      input {
                        font-size: 15px;
                        font-family: "SourceSansPro Regular", sans-serif;
                        width: 100%;
                      }
                    }
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            .right {
              border-left: 1px solid #dce7f5;
              padding-left: 30px;
              width: 330px;
              h6 {
                color: $primaryHeadingColor;
                font-size: $primaryParagraphFontSize;
                font-family: "SourceSansPro SemiBold", sans-serif;
              }
              p {
                color: $primaryParagraphColor;
                font-size: 13px;
                margin-bottom: 15px;
              }
              button {
                width: fit-content;
              }
            }
          }
          .bottom {
            ul.user-permissions {
              max-height: initial;
              li {
                ul.offices {
                  li {
                    .top {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            .delete {
              align-items: center;
              .right {
                h6 {
                  color: $primaryHeadingColor;
                  font-size: $primaryParagraphFontSize;
                  font-family: "SourceSansPro SemiBold", sans-serif;
                }
                p {
                  color: $primaryParagraphColor;
                  font-size: 13px;
                }
                padding-left: 30px;
              }
            }
          }
        }
      }
    }

    #profile {
      form {
        .content {
          padding: 0;
          .top {
            padding: 15px;
            border: 1px solid #e2e2e1;
            border-radius: 3px;
            .left {
              width: 100%;
            }
            .right {
              padding-left: 0;
              border-left: none;
              width: 100%;
              border-top: 1px solid #dce7f5;
              padding-top: 30px;
              margin-top: 30px;
            }
          }
        }
      }
      .col {
        .main-info-holder {
          padding: 15px;
          border: 1px solid #e2e2e1;
          border-radius: 3px;
          .main-info {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            picture {
              width: 117px;
              height: 117px;
              border-radius: 50%;
              position: relative;
              margin-right: 15px;
              display: block;
              background-color: #eee;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: inherit;
              }
              &::after {
                content: "";
                position: absolute;
                width: calc(100% - 12px);
                height: calc(100% - 12px);
                top: 5px;
                left: 5px;
                border: 1px solid #fff;
                border-radius: inherit;
              }
              &::before {
                content: "";
                width: 37px;
                height: 37px;
                background-image: url("../../img/settings/icons/photo-with-shadow.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 2;
                transition: 0.2s;
              }
              &:hover::before {
                transform: scale(0.8);
              }
            }
            .col {
              span.label {
                padding: 5px 10px;
                border-radius: 20px;
                width: fit-content;
                background-color: #bdccde;
                display: block;
                margin-bottom: 10px;
                color: #fff;
                font-size: 15px;
              }
              .row {
                .MuiFormControl-root {
                  width: 100%;
                }
              }
            }
          }
          .action-button {
            margin-bottom: 40px;
            .heading {
              margin-bottom: 0;
            }
            p {
              margin-bottom: 15px;
            }
            .change-password {
              width: fit-content;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
          table.type-outer {
            tbody {
              tr {
                td {
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }

    #companies {
      &.extended-settings {
        .row {
          .col {
            &.logo-holder {
              max-width: 280px;
              .logo {
                width: 100%;
                height: 280px;
                object-fit: contain;
                display: block;
                background-color: rgb(243, 243, 243);
                margin-bottom: 10px;
                padding: 10px;
              }
            }
            &.bank-data {
              max-width: 330px;
              margin-left: auto;
              ul {
                // margin-bottom: 20px;
                // padding-bottom: 20px;
                // border-bottom: 1px solid lightgray;
                max-height: 240px;
                overflow: auto;
                li {
                  margin-bottom: 10px;
                  padding: 5px;
                  position: relative;
                  cursor: pointer;
                  .row {
                    margin-bottom: 0;
                    span {
                      display: block;
                      width: 55px;
                      font-size: 15px;
                      color: $primaryParagraphColor;
                    }
                    b {
                      color: $primaryHeadingColor;
                      font-family: "SourceSansPro SemiBold", sans-serif;
                    }
                  }
                  &:nth-child(even) {
                    background-image: linear-gradient(
                      to top,
                      #f9f9f9,
                      #fff,
                      #f9f9f9
                    );
                  }
                  &:hover {
                    background-image: linear-gradient(
                      to top,
                      #f4f4f4,
                      #fff,
                      #f4f4f4
                    );
                  }
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                  .options {
                    height: 15px;
                    position: absolute;
                    top: calc(50% - 15px / 2);
                    right: 5px;
                    display: flex;
                    align-items: center;
                    img.default {
                      filter: grayscale(100%);
                      height: 100%;
                      cursor: pointer;
                      transition: 0.2s;
                      &:hover {
                        filter: grayscale(0);
                      }
                    }
                  }
                  &.current {
                    .options {
                      img.default {
                        filter: grayscale(0);
                        cursor: default;
                      }
                    }
                  }
                }
              }
              button {
                width: fit-content;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
    #numbers {
      #head {
        padding: 0;
        margin-bottom: 10px;
        .title {
          font-size: 18px;
        }
        .add {
          min-width: 30px;
          width: 30px;
          height: 30px;
        }
      }
      .row {
        .col {
          position: relative;
          margin-right: 22px;
          flex: 1;
          &::before {
            content: "";
            width: 2px;
            height: 100%;
            background-color: #bdccde;
            position: absolute;
            left: calc(100% + 10px);
            top: 0;
            bottom: 0;
          }
          &:last-of-type {
            margin-right: 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    #payments {
      ul.list {
        width: calc(100% + 23px);
        margin-right: -23px;
        display: flex;
        flex-wrap: wrap;
        > li {
          background-color: #f8f8fb;
          border: 1px solid #dde7f2;
          width: calc(100% / 3 - 23px);
          margin-right: 23px;
          margin-bottom: 23px;
          padding: 20px;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.2s;
          .top {
            align-items: center;
            img {
              flex-shrink: 0;
              &:nth-child(1) {
                margin-right: 10px;
              }
            }
            button {
              margin-left: auto;
              margin-bottom: auto;
            }
          }
          p.row {
            color: $primaryParagraphColor;
            font-size: $primaryParagraphFontSize;
          }
          hr {
            background-color: #dde7f2;
            width: calc(100% + 40px);
            margin: 30px 0;
            margin-left: -20px;
            margin-right: -20px;
          }
          ul {
            li {
              color: #b4bfca;
              font-size: $primaryParagraphFontSize;
              margin-bottom: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              span {
                color: $primaryHeadingColor;
                font-size: inherit;
                display: block;
                margin-bottom: 5px;
              }
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
          &:hover {
            -webkit-box-shadow: 0px 0px 15px 5px #f8f8fb;
            box-shadow: 0px 0px 15px 5px #f8f8fb;
          }
        }
      }
    }
    #signs {
      ul.list {
        li {
          background-color: #f8f8fb;
          border: 1px solid #dde7f2;
          padding: 15px;
          border-radius: 4px;
          margin-bottom: 30px;
          position: relative;
          min-height: 100px;
          cursor: pointer;
          .options {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            color: #b4bfca;
            font-size: 15px;
            img.default {
              filter: grayscale(100%);
              height: 100%;
              cursor: pointer;
              transition: 0.2s;
              flex-shrink: 0;
              margin-right: 5px;
              &:hover,
              &.current {
                filter: grayscale(0);
                background: #3bc870;
                width: 20px;
                height: 20px;
                object-fit: scale-down;
                border-radius: 50%;
                padding: 4px;
              }
              &.current {
                cursor: default;
              }
            }
          }
          .options-menu-button {
            position: absolute;
            top: 15px;
            right: 15px;
            min-width: initial;
            width: 40px;
            height: 40px;
            padding: 0;
            border-radius: 50%;
          }
          img.main {
            max-width: 100%;
            max-height: 300px;
            min-height: 50px;
            object-fit: cover;
            object-position: center;
            display: block;
            margin-top: 20px;
          }
          div {
            img {
              max-width: 100%;
              max-height: 150px;
            }
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    #notifications {
      table {
        margin-bottom: 40px;
        tbody {
          tr {
            td {
              cursor: default;
            }
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    #integrations {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          background-color: #f8f8fb;
          border: 1px solid #dde7f2;
          width: calc(100% / 3 - 23px);
          margin-right: 23px;
          margin-bottom: 23px;
          padding: 20px;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.2s;
          .top {
            margin-bottom: 20px;
            img {
              flex-shrink: 0;
              margin-right: auto;
              max-height: 40px;
              max-width: 200px;
              object-fit: contain;
            }
            button {
              margin-left: 10px;
            }
          }
          h3 {
            color: $primaryHeadingColor;
            font-family: "SourceSansPro SemiBold", sans-serif;
            font-size: 18px;
            margin-bottom: 5px;
          }
          p {
            color: $primaryParagraphColor;
            font-size: $primaryParagraphFontSize;
          }
          &:hover {
            -webkit-box-shadow: 0px 0px 15px 5px #f8f8fb;
            box-shadow: 0px 0px 15px 5px #f8f8fb;
          }
        }
      }
    }
    #templates {
      .MuiTabs-root {
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .templates-holder {
        justify-content: space-between;
        .templates-list {
          width: calc(100% - 500px);
          ul.list {
            display: flex;
            flex-wrap: wrap;
            overflow: auto;
            max-height: 550px;
            li {
              background-color: #fff;
              width: calc(100% / 3 - 23px);
              margin-right: 23px;
              margin-bottom: 40px;
              picture {
                display: block;
                width: 100%;
                height: fit-content;
                margin-bottom: 10px;
                border: 1px solid #dde7f2;
                border-radius: 5px;
                overflow: hidden;
                position: relative;
                > img {
                  width: 100%;
                  display: block;
                }
                .view {
                  pointer-events: none;
                  opacity: 0;
                  transition: 0.2s;
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  background-color: rgba(176, 180, 214, 0.3);
                  padding: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  span {
                    margin: 0 5px;
                    width: 37px;
                    height: 37px;
                    border-radius: 50%;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #61656c;
                    transition: 0.2s;
                    &:first-of-type {
                      img {
                        margin-right: -3px;
                      }
                    }
                    &:hover {
                      background-color: #53565c;
                      cursor: pointer;
                    }
                  }
                }
                &:hover {
                  .view {
                    opacity: 1;
                    pointer-events: all;
                  }
                }
              }
              p {
                text-align: center;
                color: $primaryParagraphColor;
                font-size: $primaryParagraphFontSize;
              }
              &.selected {
                picture {
                  border-color: #23c66d;
                }
              }
            }
          }
        }
        .current-template {
          width: 450px;
          margin-right: 50px;
          #my-pdf {
            margin-left: 0;
            canvas {
              border: 1px solid #dde7f2;
              border-radius: 5px;
            }
          }
        }
      }
    }
    #expenses {
      #head {
        margin-bottom: 10px;
      }
      ul.categories {
        display: flex;
        flex-direction: column;
        > li {
          display: flex;
          flex-direction: column;
        }
        li {
          .row,
          li {
            background-color: #f8f8fb;
            border: 1px solid #dde7f2;
            border-radius: 5px;
            padding: 5px;
            margin-bottom: 10px;
            position: relative;
            padding-left: 21px;
            cursor: pointer;
            .color {
              width: 11px;
              position: absolute;
              top: -1px;
              left: 0px;
              bottom: -1px;
              height: calc(100% + 2px);
              border-top-left-radius: inherit;
              border-bottom-left-radius: inherit;
            }
            b {
              color: $primaryHeadingColor;
              font-size: 15px;
              font-family: "SourceSansPro SemiBold", sans-serif;
              flex-shrink: 0;
              padding-right: 15px;
              span {
                color: $primaryParagraphColor;
                font-family: "SourceSansPro Regular", sans-serif;
                font-weight: normal;
              }
            }
            .options {
              margin-left: auto;
              display: flex;
              align-items: center;
              button.add {
                border-radius: 50%;
                min-width: auto;
                width: 31px;
                height: 31px;
                margin-right: 5px;
                color: #fff;
                span {
                  font-size: 25px;
                }
                &::before {
                  content: "";
                  width: 13px;
                  height: 2px;
                  position: absolute;
                  top: calc(50% - 2px / 2);
                  left: calc(50% - 14px / 2);
                  background-color: #fff;
                  background-image: none;
                }
                &::after {
                  content: "";
                  width: 2px;
                  height: 13px;
                  position: absolute;
                  top: calc(50% - 13px / 2);
                  left: calc(50% - 2px / 2);
                  background-color: #fff;
                  background-image: none;
                }
              }
            }
            &:hover {
              // background-color: #f4f4f4;
              border-color: #388ad0;
            }
          }
          li,
          &.child {
            margin-left: 35px;
            display: flex;
            align-items: center;
            position: relative;
            &::before {
              content: "";
              width: 21px;
              height: 22px;
              background-image: url("../../img/settings/icons/arrow.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              top: calc(50% - 22px / 2);
              right: calc(100% + 10px);
            }
          }
        }
      }
    }
  }
  #footer {
    margin-bottom: 0;
  }
}
