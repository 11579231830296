#templates {
    .filter {
        .col {
            max-width: 300px;
        }
    }
    #list {
        display: flex;
        gap: 10px;

        .template {
            display: flex;
            flex-direction: column;
            width: calc(100% / 8);

            img {
                border: 1px solid #eee;
                height: 240px;
                width: 100%;
                object-fit: contain;
                cursor: pointer;

                &.selected {
                    border: 1px solid #0e61dd;
                }
            }

            button {
                margin-top: 10px;
            }
        }
    }
}
