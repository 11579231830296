$primaryColor: #374470;
$primaryParagraphColor: #777777;

#login {
  padding: 40px;
  form {
    .logo {
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    h1 {
      text-align: center;
      font-weight: 100;
      font-family: "Rubik Light", sans-serif;
      color: $primaryColor;
      font-size: 30px;
      margin-bottom: 10px;
    }
    p {
      text-align: center;
      font-family: "Rubik Light", sans-serif;
      color: $primaryColor;
      font-size: 15px;
      margin-bottom: 30px;
    }
    .widgets {
      align-items: center;
      .row {
        align-items: center;
      }
      .forgotten-password {
        a {
          width: fit-content;
          margin-left: auto;
          color: $primaryColor;
          text-decoration: underline;
        }
      }
      .MuiButtonBase-root {
        margin-right: -5px;
      }
      .MuiFormControlLabel-label {
        color: $primaryColor;
        font-size: 15px;
      }
    }
    .col {
      position: relative;
      .view-pw {
        position: absolute;
        top: calc(50% - 10px / 2);
        right: 20px;
        width: 30px;
        height: 15px;
        cursor: pointer;
        z-index: 2;
        &::before {
          content: "";
          width: 26px;
          height: 2px;
          background-color: #d7d7e7;
          position: absolute;
          left: 2px;
          top: 7px;
          transform: rotate(-45deg);
        }
        &.on::before {
          display: none;
        }
      }
    }
    .MuiButton-root {
      background-color: #10cea9;
      margin-top: 40px;
      border-radius: 3px;
      min-height: 53px;
      color: #fff;
      box-shadow: none;
      span {
        font-family: "SourceSansPro SemiBold", sans-serif;
        font-size: 15px;
      }
      &:hover {
        background-color: #11c3a0;
      }
    }
    .register {
      padding: 10px 15px;
      border-radius: 25px;
      background-color: #fff;
      font-size: 15px;
      color: $primaryColor;
      text-decoration: none;
      margin: 0 auto;
      width: fit-content;
      display: block;
      -webkit-box-shadow: 0px 0px 15px 5px rgba(55, 68, 112, 0.15);
      box-shadow: 0px 0px 15px 5px rgba(55, 68, 112, 0.15);
      margin-top: 10px;
      font-family: "SourceSansPro SemiBold", sans-serif;
    }
    #password {
      padding-right: 65px;
    }
    &::after {
      content: "";
      width: 98px;
      height: 248px;
      background-image: url("../../img/login/person.png");
      position: absolute;
      left: calc(100% - 25px);
      bottom: -10px;
    }
  }
}

#register {
  overflow: hidden;
  position: relative;
  .wrapper {
    max-width: 1145px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    .info-part {
      width: calc(100% - 610px);
      padding-right: 20px;
      .first-step {
        h1,
        h2,
        h3,
        p,
        ul li {
          font-family: "Rubik Light", sans-serif;
          font-weight: 100;
          color: $primaryColor;
        }
        h1 {
          font-size: 57px;
          text-transform: uppercase;
          animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
        h2 {
          text-transform: uppercase;
          font-size: 30px;
          margin-bottom: 5px;
          animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.2s;
        }
        h3 {
          font-size: 24px;
          margin-bottom: 34px;
          animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
        }
        p {
          color: #7b83aa;
          font-size: 15px;
          line-height: 1.4;
          margin-bottom: 45px;
          animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.4s;
        }
        ul {
          animation: fadeIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.7s;
          padding-right: 80px;
          li {
            display: flex;
            align-items: center;
            font-size: 15px;
            line-height: 1.4;
            margin-bottom: 40px;
            img{
              flex-shrink: 0;
              margin-right: 15px;
              width: 59px;
            }
            .right-part{
              h4{
                font-weight: normal;
                font-size: 20px;
                margin-bottom: 5px;
              }
              p{
                animation: none;
                margin-bottom: 0;
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
      .second-step{
        h2{
          font-family: "Rubik Medium", sans-serif;
          font-weight: normal;
          color: $primaryColor;
          font-size: 30px;
          text-transform: uppercase;
          line-height: 1.1;
          margin-bottom: 25px;
          animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
        ul.data-list {
          position: relative;
          z-index: 3;
          width: 100%;
          width: fit-content;
          margin-bottom: 60px;
          li {
            font-size: 23px;
            display: flex;
            align-items: center;
            color: $primaryColor;
            width: 100%;
            margin-bottom: 5px;
            span {
              display: block;
              width: 140px;
              flex-shrink: 0;
              color: $primaryColor;
              // text-align: right;
              padding-right: 10px;
            }
            i {
              display: inline-block;
              width: 300px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-style: normal;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            &:nth-child(1){
              animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.2s;
            }
            &:nth-child(2){
              animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.3s;
            }
            &:nth-child(3){
              animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.4s;
            }
            &:nth-child(4){
              animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.5s;
            }
            &:nth-child(5){
              animation: slideInfo 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.6s;
            }
          }
        }
        ul.more{
          animation: fadeIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.8s;
          li{
            position: relative;
            padding-left: 30px;
            color: $primaryColor;
            margin-bottom: 19px;
            font-size: 15px;
            &::before{
              content: '';
              width: 22px;
              height: 22px;
              background-image: url('../../img/login/icons/check-filled.png');
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              position: absolute;
              left: 0;
              top: calc(50% - 22px / 2);
            }
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
      }

      @keyframes slideInfo {
        0% {
          transform: translateX(-100px);
          opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .steps-form {
      height: 570px;
      width: 560px;
      background-color: #fff;
      border: 1px solid #dde0f4;
      border-radius: 2px;
      padding: 30px;
      padding-bottom: 100px;
      position: relative;
      display: flex;
      flex-direction: column;
      button.back{
        // position: absolute;
        // left: 25px;
        // top: 20px;
        margin-right: 10px;
        span{
          text-transform: uppercase;
          display: flex;
          align-items: center;
          font-size: 13px;
          img{
            margin-right: 5px;
          }
        }
      }
      img.brand {
        width: 100px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      h2 {
        font-family: "Rubik Light", sans-serif;
        font-weight: normal;
        color: #212932;
        font-size: 33px;
        text-align: center;
        margin-bottom: 15px;
      }
      ul.steps {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: fit-content;
        position: relative;
        z-index: 1;
        margin-bottom: 15px;
        li {
          background-color: #cecfdc;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 145px;
          // z-index: 6;
          position: relative;
          cursor: pointer;
          &:last-of-type {
            margin-right: 0;
            &::before {
              display: none;
            }
          }
          &::after {
            content: "";
            width: 30px;
            height: 30px;
            background-color: rgba(206, 207, 220, 0.3);
            position: absolute;
            top: calc(50% - 30px / 2);
            left: calc(50% - 30px / 2);
            border-radius: 50%;
            z-index: 2;
            transform: scale(0.2);
            transition: 0.2s;
          }
          &.finished {
            background-color: #37cfb1;
            &::after {
              background-color: rgba(55, 207, 177, 0.3);
              transform: scale(1);
              opacity: 1;
            }
          }
          &:not(.current):hover::after {
            transform: scale(0.8);
          }
          &.current {
            cursor: default;
          }
          &::before {
            content: "";
            width: 100px;
            height: 1px;
            top: calc(50% - 1px / 2);
            left: calc(100% + 23px);
            background-color: rgba(206, 207, 220, 1);
            z-index: 1;
            position: absolute;
            pointer-events: none;
          }
        }
      }
      ul.screens {
        flex: 1;
        > li {
          position: relative;
          padding: 0 35px;
          padding-bottom: 20px;
          height: 100%;
          &.company-data {
            .holder {
              position: relative;
              z-index: 3;
              &.disabled {
                filter: blur(3px);
              }
            }
            p {
              color: #7b83aa;
              font-size: 15px;
              text-align: center;
              max-width: 400px;
              margin: 0 auto;
              margin-bottom: 15px;
            }
            .eik-input {
              max-width: 300px;
              margin: 0 auto;
              margin-bottom: 15px;
              position: relative;
              img.loader,
              img.check {
                position: absolute;
                z-index: 3;
              }
              img.loader {
                left: 5px;
                width: 30px;
                height: 30px;
                top: calc(50% - 30px / 2);
              }
              img.check {
                right: 10px;
                width: 18px;
                height: 13px;
                top: calc(50% - 13px / 2);
              }
              .MuiInputLabel-filled.MuiInputLabel-marginDense {
                transform: translate(105px, 17px) scale(1);
                transition: 0.2s;
              }
              .MuiInputLabel-shrink {
                transform: translate(115px, 7px) scale(0.75) !important;
                text-align: center;
              }
              input {
                text-align: center;
              }
              .dropdown {
                width: 430px;
              }
            }
            ul.data-list {
              position: relative;
              z-index: 3;
              width: 100%;
              width: fit-content;
              margin: 0 auto;
              margin-bottom: 15px;
              li {
                font-size: 15px;
                display: flex;
                align-items: center;
                color: $primaryColor;
                width: 100%;
                margin-bottom: 5px;
                span {
                  display: block;
                  width: 120px;
                  flex-shrink: 0;
                  color: $primaryParagraphColor;
                  text-align: right;
                  padding-right: 10px;
                }
                i {
                  display: inline-block;
                  width: 300px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-style: normal;
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
            button.edit-mode {
              position: relative;
              z-index: 3;
              background-color: #fff;
              border-radius: 20px;
              color: #0e61dd;
              font-size: 15px;
              height: 40px;
              padding: 0 25px;
              display: block;
              margin: 0 auto;
              &:hover {
                color: #0270d1;
              }
            }
            > .row {
              position: relative;
              z-index: 1;
            }
            &:not(.filled)::before {
              content: "";
              width: calc(100% + 60px);
              height: 300px;
              background-image: url("../../img/login/register-bottom-shape.png");
              background-position: bottom center;
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              left: -30px;
              right: -30px;
              bottom: 0;
              z-index: 2;
              pointer-events: none;
            }
          }
          &.auth-data {
            padding-top: 70px;
            form {
              border: none;
              padding: 0;
              .row {
                position: relative;
                z-index: 3;
                &.checkbox-holder {
                  padding-left: 10px;
                  margin-bottom: 20px;
                  border-bottom: 1px solid transparent;
                  .MuiCheckbox-root,
                  .MuiTypography-root {
                    color: $primaryColor;
                    font-size: 15px;
                    line-height: 1.2;
                    a {
                      color: inherit;
                      text-decoration: underline;
                    }
                  }
                  &.validate {
                    border-left-width: 0;
                    border-top-width: 0;
                    border-right-width: 0;
                    border-bottom: 1px solid #f44336;
                  }
                }
                .pw-col {
                  position: relative;
                  input {
                    padding-right: 50px;
                  }
                  .view-pw {
                    position: absolute;
                    top: calc(50% - 15px / 2);
                    right: 30px;
                    width: 30px;
                    height: 15px;
                    cursor: pointer;
                    z-index: 2;
                    &::before {
                      content: "";
                      width: 26px;
                      height: 2px;
                      background-color: #d7d7e7;
                      position: absolute;
                      left: 2px;
                      top: 7px;
                      transform: rotate(-45deg);
                    }
                    &.on::before {
                      display: none;
                    }
                  }
                  .password-indicator {
                    border: 1px solid #d1dff1;
                    border-radius: 3px;
                    background-color: #fff;
                    padding: 15px;
                    position: absolute;
                    right: 100%;
                    opacity: 0;
                    transition: 0.2s;
                    pointer-events: none;
                    top: 50%;
                    transform: translateY(-50%);
                    box-shadow: 0px 0px 80px 0px #d1dff1;
                    width: 235px;
                    b {
                      font-weight: normal;
                      font-size: 15px;
                      color: $primaryColor;
                      margin-bottom: 5px;
                      display: block;
                    }
                    .loader {
                      width: 100%;
                      height: 6px;
                      background-color: #bcbdce;
                      display: flex;
                      justify-content: flex-start;
                      border-radius: 3px;
                      position: relative;
                      margin-bottom: 15px;
                      &::before {
                        content: "";
                        width: 20%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        background-color: #f44336;
                        border-radius: inherit;
                        transition: 1s;
                      }
                      &.medium::before {
                        background-color: #f08e4b;
                        width: 70%;
                      }
                      &.strong::before {
                        background-color: #37cfb1;
                        width: 100%;
                      }
                    }
                    p {
                      font-size: 13px;
                      line-height: 1.1;
                      color: $primaryColor;
                    }
                    &.show {
                      right: calc(100% + 10px);
                      opacity: 1;
                      pointer-events: all;
                    }
                  }
                }
              }
              .login {
                padding: 10px 15px;
                border-radius: 25px;
                background-color: #fff;
                font-size: 15px;
                color: $primaryColor;
                text-decoration: none;
                margin: 0 auto;
                width: fit-content;
                display: block;
                -webkit-box-shadow: 0px 0px 15px 5px rgba(55, 68, 112, 0.05);
                box-shadow: 0px 0px 15px 5px rgba(55, 68, 112, 0.05);
                margin-top: 40px;
                font-family: "SourceSansPro SemiBold", sans-serif;
                position: relative;
                z-index: 1;
              }
            }
          }
          &::after {
            content: "";
            height: 100px;
            width: calc(100% + 60px);
            background-image: linear-gradient(to top, #e7f0ff, #fff);
            position: absolute;
            bottom: 0;
            left: -30px;
            right: -30px;
            pointer-events: none;
            z-index: 0;
          }
        }
      }
      .footer {
        height: 100px;
        padding: 0 70px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        z-index: 1;
        button.back{
          // position: absolute;
          // left: 55px;
          // top: 20px;
          margin-right: 10px;
          span{
            text-transform: uppercase;
            display: flex;
            align-items: center;
            font-size: 13px;
            img{
              margin-right: 5px;
            }
          }
        }
        button.save {
          background-color: #37cfb1;
          height: 52px;
          padding: 10px 30px;
          -webkit-box-shadow: 0px 0px 33px 5px rgba(168,168,168,0.2); 
          box-shadow: 0px 0px 33px 5px rgba(168,168,168,0.2);
          width: 230px;
          span {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 15px;
            img {
              margin-left: 5px;
            }
          }
          &:hover {
            background-color: #36c9ac;
          }
        }
        .progress {
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          width: 100%;
          height: 4px;
          background-color: #bcbdce;
          display: flex;
          justify-content: flex-start;
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
          .done {
            min-width: 40px;
            width: 0;
            transition: 1s;
            position: relative;
            background-color: #37cfb1;
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
            span {
              position: absolute;
              bottom: 100%;
              right: 0;
              padding-bottom: 3px;
              color: #37cfb1;
              font-size: 13px;
              font-family: "SourceSansPro SemiBold", sans-serif;
              padding-right: 5px;
            }
          }
        }
      }
      &::before {
        content: "";
        width: 93px;
        height: 243px;
        background-image: url("../../img/login/lady.png");
        position: absolute;
        bottom: 2px;
        left: -36px;
        z-index: 3;
      }
    }
    .final-screen{
      position: relative;
      padding: 40px 0;
      margin-top: -10px;
      margin-bottom: -10px;
      min-height: 100vh;
      .brand{
        height: 30px;
        display: block;
        margin: 0 auto;
        margin-bottom: 23px;
        animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
      h1{
        color: $primaryColor;
        font-size: 60px;
        text-align: center;
        font-family: "Rubik Medium", sans-serif;
        font-weight: normal;
        margin-bottom: 20px;
        animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
      p{
        text-align: center;
        color: #7b83aa;
        font-size: 15px;
        line-height: 1.6;
        padding: 0 200px;
        margin-bottom: 45px;
        animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
      button {
        background-color: #37cfb1;
        height: 60px;
        padding: 10px 90px;
        margin: 0 auto;
        display: block;
        margin-bottom: 55px;
        animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        span {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 17px;
          font-family: "Rubik Medium", sans-serif;
          img {
            margin-left: 5px;
          }
        }
        &:hover {
          background-color: #36c9ac;
        }
      }
      img.main-image{
        display: block;
        margin: 0 auto;
      }
    }
    ul.final-list{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      li{
        width: 100px;
        height: 90px;
        border-radius: 2px;
        padding: 5px;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        font-size: 15px;
        color: #a7abbb;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        img{
          margin-bottom: 5px;
        }
        &:nth-child(1) {
          top: -20px;
          left: 279px;
          animation: left-top-cloud 4s infinite;
        }
        &:nth-child(2) {
          // top: 159px;
          // left: 367px;
          top: 382px;
          left: 277px;
          animation: left-top-cloud 4.2s infinite;
        }
        &:nth-child(3) {
          top: 247px;
          left: 69px;
          animation: left-top-cloud 5s infinite;
        }
        &:nth-child(4) {
          left: -19px;
          top: 578px;
          animation: left-top-cloud 7s infinite;
        }
        &:nth-child(5) {
          left: 240px;
          top: 654px;
        }
        &:nth-child(6) {
          left: 50px;
          top: 800px;
          animation: left-top-cloud 4.1s infinite;
        }
        &:nth-child(7) {
          left: 400px;
          top: 880px;
          animation: left-top-cloud 4s infinite;
        }
        &:nth-child(8) {
          right: 22px;
          top: -39px;
          animation: left-top-cloud 4.6s infinite;
        }
        &:nth-child(9) {
          // right: 300px;
          // top: 43px;
          right: 194px;
          top: 91px;
          animation: left-top-cloud 5s infinite;
        }
        &:nth-child(10) {
          right: 227px;
          top: 275px;
          animation: left-top-cloud 6s infinite;
        }
        &:nth-child(11) {
          right: 40px;
          top: 390px;
          animation: left-top-cloud 5s infinite;
        }
        &:nth-child(12) {
          right: 237px;
          top: 597px;
          animation: left-top-cloud 4s infinite;
        }
        &:nth-child(13) {
          right: 168px;
          top: 825px;
          animation: left-top-cloud 4.3s infinite;
        }
        &:nth-child(14) {
          right: 494px;
          top: 892px;
          animation: left-top-cloud 5s infinite;
        }
      }
    }
    @keyframes left-top-cloud {
      0% {
          transform: translatey(0);
      }
      50% {
          transform: translatey(-10px);
      }
      100% {
          transform: translatey(0);
      }
  }
    &.finished{
      .info-part{
        animation: slideLeft 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
      .steps-form{
        animation: slideRight 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }

      @keyframes slideRight {
        0% {
          transform: translateX(0);
          opacity: 1;
        }
        100% {
          transform: translateX(500px);
          opacity: 0;
        }
      }
      @keyframes slideLeft {
        0% {
          transform: translateX(0);
          opacity: 1;
        }
        100% {
          transform: translateX(-500px);
          opacity: 0;
        }
      }
    }
  }
}

#register,
#login {
  min-height: 100vh;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../img/login/bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  position: relative;
  form {
    width: 100%;
    max-width: 530px;
    padding: 30px 70px;
    border-radius: 3px;
    border: 1px solid #dee9f7;
    background-color: #fff;
    position: relative;
  }
  .info {
    text-align: center;
    margin-top: 20px;
    font-size: 15px;
    font-family: "Roboto Regular", sans-serif;
    color: #acadb6;
  }
  .footer {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: auto;
    padding: 20px 0;
    font-size: 13px;
    font-family: "Roboto Regular", sans-serif;
    color: #acadb6;
  }
}
