.form-file-upload {
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;

    .input-file-upload {
        display: none;
    }

    label {
        padding: 35px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        border-style: dashed;
        border-color: #cbd5e1;
        background-color: #f8fafc;
        cursor: pointer;

        img {
            width: 120px;
        }

        p {
            margin: 5px 0;
            font-size: 1.5rem;
            font-weight: 100;
        }

        button {
            margin-top: 5px;
        }

        &.drag-active {
            background-color: #ffffff;
        }
    }

    .drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
}