.preview-fairnet-configurator {
    .heading {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            margin-right: 14px;
            font-size: 24px;
            font-weight: 600;
            color: #fff;
            background-color: #00a659;
        }

        .right {
            b {
                font-size: 16px;
                font-weight: 800;
                text-transform: uppercase;
                display: block;
                margin-bottom: 7px;
            }
        }
    }

    .preview-field {
        display: flex;
        border: 1px solid #e1e1e1;
        border-radius: 2px;
        background-color: #fbfbfb;
        padding: 15px;
        padding-right: 50px;
        margin-bottom: 20px;
        position: relative;

        ul {
            flex: 0.3;

            li {
                font-weight: 600;
                font-size: 17px;
                margin-bottom: 8px;
            }
        }

        .message {
            flex: 0.7;
            margin-left: 60px;

            b {
                font-weight: 600;
                font-size: 17px;
                margin-bottom: 8px;
                display: block;
            }

            p {
                font-weight: 100;
                line-height: 1.4;
                font-size: 17px;
            }
            
        }

        > .mid {
            flex: 0.5;
            h6 {
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 22px;
            }
            b {
                font-weight: 600;
                font-size: 17px;
                margin-bottom: 3px;
                display: block;
            }
            p {
                font-weight: 100;
                line-height: 1.4;
                font-size: 17px;
            }
        }

        > .right {
            flex: 0.5;
            display: flex;
            align-items: center;
            li {
                margin-right: 48px;
                b {
                    font-weight: 600;
                    font-size: 17px;
                    margin-bottom: 3px;
                    display: block;
                }
                p {
                    font-weight: 100;
                    font-size: 17px;
                }
            }
        }

        .row {
            .col {
                h6 {
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                ul {
                    li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        img {
                            width: 57px;
                            height: 50px;
                            -o-object-fit: contain;
                            object-fit: contain;
                            background-color: #eaeaea;
                            border-radius: 3px;
                            margin-right: 8px;
                            flex-shrink: 0;
                            border: 1px solid #ececec;
                        }

                        .right {
                            b {
                                font-weight: 600;
                                font-size: 17px;
                                margin-bottom: 3px;
                                display: block;
                            }
                            p {
                                font-weight: 100;
                                line-height: 1.4;
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }
}