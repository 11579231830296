* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  font-family: "SourceSansPro Regular", sans-serif;
}

@font-face {
  font-family: "Exo2 Bold";
  font-display: swap;
  src: url("../fonts/Exo2/Exo2-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Exo2 Medium";
  font-display: swap;
  src: url("../fonts/Exo2/Exo2-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik Light";
  font-display: swap;
  src: url("../fonts/Rubik/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik Medium";
  font-display: swap;
  src: url("../fonts/Rubik/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansPro Light";
  font-display: swap;
  src: url("../fonts/SourceSansPro/SourceSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansPro Regular";
  font-display: swap;
  src: url("../fonts/SourceSansPro/SourceSansPro-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "SourceSansPro SemiBold";
  font-display: swap;
  src: url("../fonts/SourceSansPro/SourceSansPro-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "SourceSansPro Bold";
  font-display: swap;
  src: url("../fonts/SourceSansPro/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  font-display: swap;
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

$primaryHeadingFontFamily: "SourceSansPro SemiBold", sans-serif;
$primaryParagraphFontSize: 13px;
$secondaryParagraphFontSize: 15px;
$popoverOptionsHoverBackgroundColor: #f0f7fc;
$bluishColor: #aeb7c9;

$primaryHeadingColor: #42424e;
$primaryParagraphColor: #777777;

a {
  text-decoration: none;
}

html {
  // overflow-y: overlay;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.clickable {
  cursor: pointer;
}

.print-button-layout {
  all: inherit;
  img {
    pointer-events: none;
  }
}

.client-holder {
  .open-client-config-modal-btn {
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
  }
  &:hover {
    .open-client-config-modal-btn {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.trade-objects-select {
  padding: 0 5px;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: -10px !important;
  border-top: 1px solid #dde6ed;
  .col:first-of-type {
    color: #777777;
    margin-right: 0;
    flex: 0.12 1;
    font-size: 13px;
    align-items: flex-start;
    display: flex;
    justify-content: center;
  }
  .col:last-of-type {
    padding-left: 10px;
    .MuiInput-underline::before,
    .MuiInput-underline:after {
      display: none;
    }
    .MuiInput-underline {
      .MuiSelect-root {
        border: 1px solid #dde6ed;
        padding: 2px 8px;
        border-radius: 4px;
        min-height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        b {
          font-size: 15px;
          font-weight: 400;
          color: #424242;
          line-height: 1.1;
        }
        p {
          font-size: 13px;
          color: #858595;
          line-height: 1.2;
        }
      }
    }
  }
}

.trade-objects-select-menu {
  .MuiMenu-list {
    padding-bottom: 0;
    .MuiListItem-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      b {
        font-size: 15px;
        font-weight: 400;
        color: #424242;
        line-height: 1.1;
      }
      p {
        font-size: 13px;
        color: #858595;
        line-height: 1.2;
      }
    }
    button {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      color: $primaryHeadingColor;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 58px;
      border-top: 1px solid #dde6ed;
      background-color: #fff;
      border-radius: 0;
      img {
        margin-right: 5px;
      }
    }
  }
}

button.open-client-config-modal-btn {
  margin: 0 5px !important;
  min-width: initial;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-image: linear-gradient(180deg, #0189ff 0%, #0e61dd 100%);
}
.client-config-modal {
  .MuiPopover-paper {
    width: 350px;
    .head {
      display: flex;
      align-items: center;
      background-color: #0f5fd8;
      padding: 7px 13px;
      min-height: 57px;
      img {
        margin-right: 5px;
      }
      .right {
        h3 {
          font-family: "SourceSansPro SemiBold", sans-serif;
          font-weight: 500;
          color: #fff;
          font-size: 16px;
          margin-bottom: 2px;
        }
        p {
          color: #8bb9ff;
          font-size: 13px;
        }
      }
    }
    ul.options-list {
      padding: 6px 0;
      li {
        display: flex;
        align-items: center;
        padding: 8px 18px;
        color: $primaryHeadingColor;
        img {
          width: 24px;
          object-fit: scale-down;
          margin-right: 8px;
        }
        &:hover {
          cursor: pointer;
          background-color: #f0f0f0;
        }
      }
    }
    .objects-container {
      padding: 15px 18px;
      border-top: 1px solid #dde6ed;
      h3 {
        font-family: "SourceSansPro SemiBold", sans-serif;
        font-weight: 500;
        color: #424242;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .holder {
        border: 1px solid #dde6ed;
        border-radius: 4px;
        overflow: hidden;
        ul {
          max-height: 120px;
          overflow: auto;
          border-bottom: 1px solid #dde6ed;
          li {
            padding: 3px 10px;
            display: flex;
            align-items: center;
            img {
              margin-right: 5px;
            }
            .right {
              b {
                font-weight: 400;
                color: $primaryHeadingColor;
                font-size: 15px;
                display: block;
              }
              p {
                font-size: 13px;
                color: #858595;
              }
            }
            &:hover,
            &.selected {
              background-color: #f5f5f5;
            }
            &:hover:not(.selected) {
              cursor: pointer;
            }
          }
        }
        button {
          color: $primaryHeadingColor;
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 58px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.MuiAlert-filledInfo {
  background-color: #0e61dd !important;
}

.add-articles-tabs,
.left-side-menu {
  padding-right: 15px;
  margin-right: 15px;
  width: 215px;
  height: 100%;
  border-right: 1px solid #edeef7;
  overflow: auto;
  padding: 23px 0;
  li {
    font-size: 15px;
    color: #aeb7c9;
    cursor: pointer;
    position: relative;
    padding: 10px;
    padding-left: 15px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
      width: 20px;
      object-fit: scale-down;
      display: block;
    }
    &::before,
    &::after {
      content: "";
      height: 0px;
      width: 2px;
      transition: 0.2s;
      position: absolute;
      left: 3px;
      background-color: #388ad0;
    }
    &::before {
      top: 50%;
    }
    &::after {
      bottom: 50%;
    }
    &.active {
      pointer-events: none;
    }
    &.active::before,
    &.active::after {
      height: calc(50% - 10px);
    }
    &:hover {
      background-color: #f8f8fb;
    }
  }
}

aside.with-submenues {
  overflow: auto;
  border-right: 1px solid #edeef7;
  padding: 15px 0;
  margin-left: -10px;
  margin-right: 10px;
  .heading {
    background-image: linear-gradient(
      to right,
      rgba(238, 241, 245, 1),
      rgba(255, 255, 255, 1)
    );

    padding: 5px 15px;
    b {
      color: $primaryHeadingColor;
      font-size: 13px;
      font-family: "SourceSansPro SemiBold", sans-serif;
    }
  }
  .left-side-menu {
    overflow: initial;
    height: initial;
    padding: 0;
    border: none;
  }
}

.event-calendar {
  height: 100%;
  display: flex;
  flex-direction: column;
  .calendar-head {
    border-top: 2px solid #bbcad3;
    border-bottom: 2px solid #bbcad3;
    height: 69px;
    display: flex;
    justify-content: space-between;
    background-color: #f4f6f8;
    .month-and-year {
      display: flex;
      align-items: center;
      border-right: 1px solid #d9e3e9;
      height: 100%;
      padding: 0 5px;
      width: fit-content;
      flex-shrink: 0;
      min-width: 220px;
      b {
        color: #606a82;
        font-size: 16px;
        font-family: "SourceSansPro Bold", sans-serif;
      }
      > button:not(.select-date) {
        width: 30px;
        height: 30px;
        min-width: initial;
        flex-shrink: 0;
      }
      button.select-date {
        margin: 0 5px;
        height: 30px;
        flex: 1;
        position: relative;
      }
      .MuiFormControl-root {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        button {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
    ul.periods {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex: 1;
      li {
        margin: 0 5px;
        flex-shrink: 0;
        button {
          padding: 0 20px;
          b {
            color: $primaryHeadingColor;
            font-family: "SourceSansPro SemiBold", sans-serif;
            line-height: 1;
          }
          &.active {
            background-color: #0189ff;
            cursor: default;
            b {
              color: #fff;
            }
          }
        }
      }
    }
    .calendar-type {
      width: fit-content;
      flex-shrink: 0;
      border-left: 1px solid #d9e3e9;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      min-width: 115px;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
      b {
        color: #aeb7c9;
        font-size: 16px;
        font-family: "SourceSansPro SemiBold", sans-serif;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
  ul.calendar-type-1 {
    overflow: auto;
    display: flex;
    width: 100%;
    flex: 1;
    > li {
      height: fit-content;
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 100px;
      overflow: hidden;
      .day-of-week {
        position: sticky;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 3;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e2eaef;
        border-right: 1px solid #e2eaef;
        flex-shrink: 0;
        height: 50px;
        background-color: #fff;
        b {
          font-family: "SourceSansPro SemiBold", sans-serif;
          text-transform: capitalize;
        }
        b,
        p {
          color: #606a82;
          font-size: 16px;
        }
      }
      ul.cell-container {
        height: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #e2eaef;
        width: 100%;
        li {
          border-bottom: 1px solid #e2eaef;
          height: 50px;
          position: relative;
          display: flex;
          flex-direction: column;
          button.options-menu-button {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
          .event {
            background-color: #84a0be;
            border: 1px solid #fff;
            position: absolute;
            top: 0;
            z-index: 2;
            padding: 5px;
            b {
              font-size: 14px;
              color: #fff;
              font-family: "SourceSansPro SemiBold", sans-serif;
              line-height: 1;
            }
            p {
              font-size: 11px;
              color: #fff;
              line-height: 1;
            }
            b,
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }
          }
        }
      }
      &.small {
        left: 0;
        max-width: 100px;
        position: sticky;
        width: 100px;
        z-index: 4;
        background-color: #fff;
        ul.small {
          li {
            align-items: center;
            border-bottom: 1px solid #e2eaef;
            border-right: 1px solid #e2eaef;
            display: flex;
            height: 50px;
            justify-content: center;
            text-align: center;
            b {
              color: #aeb7c9;
              font-size: 16px;
              font-family: "SourceSansPro SemiBold", sans-serif;
            }
          }
        }
      }
      &:last-of-type {
        .day-of-week {
          border-right: none;
        }
        ul.cell-container {
          border-right: none;
        }
      }
    }
  }
  ul.calendar-type-2 {
    overflow: auto;
    display: flex;
    width: 100%;
    flex: 1;
    > li {
      flex-shrink: 0;
      flex: 1;
      width: calc(100% / 7);
      .week-head {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-right: 1px solid #e2eaef;
        b {
          font-family: "SourceSansPro SemiBold", sans-serif;
          color: #606a82;
          font-size: 16px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #e2eaef;
          width: 100%;
          text-align: center;
        }
        p {
          color: #606a82;
          font-size: 16px;
          padding: 10px 0;
        }
      }
      > ul {
        > li {
          height: 50px;
          border-bottom: 1px solid #e2eaef;
          border-right: 1px solid #e2eaef;
          position: relative;
          button.options-menu-button {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
          > ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            > li {
              background-color: #84a0be;
              border: 1px solid #fff;
              font-size: 13px;
              padding: 5px;
              min-height: 30px;
              width: 100%;
              color: #fff;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              position: relative;
              z-index: 2;
              b {
                color: inherit;
                font-family: "SourceSansPro Bold", sans-serif;
                font-size: inherit;
              }
            }
          }
        }
      }
      &:last-of-type {
        .week-head {
          border-right: none;
        }
        > ul {
          > li {
            border-right: none;
          }
        }
      }
    }
  }

  .day-off {
    * {
      color: #ff4e77 !important;
    }
  }

  .add-new {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #e0ffdb;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    // pointer-events: none;
    color: #1a6b82;
    font-size: 15px;
    z-index: 3;
    cursor: pointer;
    img {
      margin-right: 2px;
    }
    &:hover{
      opacity: 1;
    }
  }
}

.pipelines {
  height: 100%;
  display: flex;
  flex-direction: column;
  .dnd-holder {
    display: flex;
    .dnd-status {
      margin-right: 10px;
      width: 300px;
      .status-head {
        background-color: #99c2ff;
        border-radius: 4px;
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        b,
        span {
          color: #fff;
          font-family: "SourceSansPro SemiBold", sans-serif;
        }
        b {
          padding-right: 10px;
          line-height: 1;
        }
        span {
          margin-left: auto;
        }
        button.add-item {
          width: 22px;
          height: 22px;
          min-width: initial;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-left: 5px;
        }
        .menu-wrapper {
          border-left: 1px solid #fff;
          padding-left: 5px;
          margin-left: 5px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          button.options-menu-button {
            min-width: initial;
            border-radius: 50%;
            width: 40px;
          }
        }
      }
      ul.dnd-wrapper {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #fff;
        transition: 0.2s;
        li.dnd-item {
          background-color: #f8f8fb;
          border-radius: 4px;
          padding: 10px;
          position: relative;
          .row {
            padding-right: 50px;
            .options-menu-button {
              position: absolute;
              right: 2px;
              top: 2px;
              min-width: initial;
              width: 40px;
              border-radius: 50%;
            }
            b {
              color: $primaryHeadingColor;
              font-family: "SourceSansPro SemiBold", sans-serif;
              display: block;
              margin-bottom: 10px;
            }
          }
          p {
            color: #606a82;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            img {
              margin-right: 5px;
              flex-shrink: 0;
            }
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              padding-right: 15px;
              margin-right: 10px;
              position: relative;
              font-family: "SourceSansPro SemiBold", sans-serif;
              display: flex;
              align-items: center;
              img {
                margin-right: 5px;
                flex-shrink: 0;
              }
              &:not(:last-of-type):after {
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #606a82;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.campaigns {
  ul.campaigns-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    > li {
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 15px;
      background-color: #f8f8fb;
      border-radius: 4px;
      width: calc(100% / 4 - 15px);
      position: relative;
      .options-menu-button {
        top: 5px;
        right: 5px;
        position: absolute;
        width: 40px;
        min-width: inherit;
        border-radius: 50%;
      }
      .row {
        .col {
          b,
          span.label {
            font-size: 13px;
            display: flex;
            align-items: center;
            img {
              margin-right: 2px;
            }
          }
          b {
            color: #606a82;
            text-transform: capitalize;
            span.status {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: #606a82;
              margin-right: 2px;
              display: block;
            }
          }
          span.label {
            color: #b4bfca;
          }
        }
      }
      hr {
        margin-top: 10px;
      }
      b {
        color: $primaryHeadingColor;
        font-family: "SourceSansPro Semibold", sans-serif;
        display: block;
      }
      > b {
        margin: 10px 0;
      }
    }
  }
  hr {
    margin-top: -5px;
  }
}

ul.campaingn-clients {
  display: flex;
  li {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #aeb7c9;
    border: 2px solid #f8f8fb;
    color: #fff;
    font-family: "SourceSansPro Semibold", sans-serif;
    font-size: 11px;
    &:not(:first-of-type) {
      margin-left: -8px;
    }
  }
}

.preview-article-home {
  flex: 1;
  .main {
    b {
      color: $primaryHeadingColor;
      font-size: 22px;
      font-family: "SourceSansPro SemiBold", sans-serif;
      font-weight: 600;
      display: block;
    }
    p {
      color: $primaryHeadingColor;
      font-size: 14px;
    }
  }
  table:not(.type-outer) {
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;
    // border-right: 1px solid #dee2e6;
    // border-left: 1px solid #dee2e6;
    tr {
      // border-color: #dee2e6;
      border-style: solid;
      td {
        font-size: 13px;
        border: 1px solid #dee2e6;
        span.discount {
          background-color: #ff4e77;
          font-size: 13px;
          padding: 1px 3px;
          border-radius: 4px;
          color: #fff;
          font-family: $primaryHeadingFontFamily;
          margin-left: 5px;
        }
      }
    }
    .th {
      background: #eee;
      padding: 5px 10px;
      text-align: right;
    }
    & > :not(caption) > * > * {
      border-width: 0 1px;
      padding: 5px 10px;
    }
    & > :not(caption) > * {
      border-width: 1px 0;
    }
  }
}

.head-with-heading {
  padding: 7px 20px;
  min-height: 55px;
  // border-bottom: 1px solid #dde6ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-family: $primaryHeadingFontFamily;
    color: $primaryHeadingColor;
    font-size: 25px !important;
    margin-right: 5px;
    margin-bottom: 0 !important;
    padding-bottom: 5px;
  }
}

.app-loader {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img.brand {
    width: 250px;
    position: relative;
    z-index: 2;
  }
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0e61dd !important;
}

.input-with-right-label {
  .MuiInputAdornment-positionEnd {
    background-color: #dde6ed;
    width: 50px;
    height: 100%;
    position: absolute;
    max-height: initial;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    justify-content: center;
    text-align: center;
    p {
      font-size: 14px;
      color: $primaryHeadingColor;
    }
  }
  .MuiInputBase-root,
  .MuiFormControl-root {
    border-radius: 4px;
    padding-right: 52px;
    input {
      min-width: 30px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #dde6ed !important;
    }
  }
  .MuiSelect-select {
    padding-top: 20px;
    padding-bottom: 1px;
  }
  &.normalize {
    .MuiOutlinedInput-input,
    .MuiInputBase-input,
    .MuiFormControl-root {
      padding: 15.5px 14px;
      &::before,
      &::after {
        display: none;
      }
    }
  }
  .MuiInputBase-root {
    &::before,
    &::after {
      display: none;
    }
  }

  .MuiFilledInput-root {
    border: 1px solid #e2e2e1 !important;
    overflow: hidden !important;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 4px !important;
    background-color: #fff !important;
  }
}

#verify-success {
  position: relative;
  padding: 40px 0;
  padding-bottom: 165px;
  min-height: 100vh;
  background-image: url("../img/verify/bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .brand {
    height: 30px;
    display: block;
    margin: 0 auto;
    margin-bottom: 95px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .main-image {
    display: block;
    margin: 0 auto;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    margin-bottom: 15px;
  }
  h1 {
    color: #374470;
    font-size: 60px;
    text-align: center;
    font-family: "Rubik Medium", sans-serif;
    font-weight: normal;
    margin-bottom: 20px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  p.description {
    text-align: center;
    color: #7b83aa;
    font-size: 15px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 70px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  button {
    background-color: #0e61dd;
    height: 60px;
    padding: 10px 90px;
    margin: 0 auto;
    display: block;
    margin-bottom: 55px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    position: relative;
    span {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 17px;
      font-family: "Rubik Medium", sans-serif;
      img {
        margin-left: 5px;
      }
    }
    &:hover {
      background-color: #0f5cce;
    }
    &::before {
      content: "";
      width: 61px;
      height: 263px;
      background-image: url("../img/verify/woman.png");
      position: absolute;
      right: calc(100% + 85px);
      top: -25px;
      pointer-events: none;
    }
  }

  .footer {
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    ul.links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      li {
        margin: 0 15px;
        a {
          font-size: 15px;
          font-family: "Roboto Regular", sans-serif;
          color: #374470;
        }
      }
    }
    p {
      text-align: center;
      font-size: 13px;
      font-family: "Roboto Regular", sans-serif;
      color: #acadb6;
    }
  }
}

#verify-fail {
  position: relative;
  padding: 40px 0;
  padding-bottom: 165px;
  min-height: 100vh;
  background-image: url("../img/login/bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .brand {
    height: 30px;
    display: block;
    margin: 0 auto;
    margin-bottom: 95px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .main-image {
    display: block;
    margin: 0 auto;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    margin-bottom: 15px;
  }
  h1 {
    color: #374470;
    font-size: 60px;
    text-align: center;
    font-family: "Rubik Medium", sans-serif;
    font-weight: normal;
    margin-bottom: 20px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  p.description {
    text-align: center;
    color: #7b83aa;
    font-size: 15px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 70px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .buttons {
    justify-content: center;
    button {
      background-color: #0e61dd;
      height: 60px;
      padding: 10px 90px;
      display: block;
      animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      position: relative;
      margin: 0 10px;
      margin-bottom: 55px;
      span {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 17px;
        font-family: "Rubik Medium", sans-serif;
        img {
          margin-left: 5px;
        }
      }
      &:hover:not(.secondary) {
        background-color: #0f5cce;
      }
      &.secondary {
        border: 1px solid #0e61dd;
        background-color: transparent;
        span {
          color: #0e61dd;
        }
      }
    }
  }
  .footer {
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    ul.links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      li {
        margin: 0 15px;
        a {
          font-size: 15px;
          font-family: "Roboto Regular", sans-serif;
          color: #374470;
        }
      }
    }
    p {
      text-align: center;
      font-size: 13px;
      font-family: "Roboto Regular", sans-serif;
      color: #acadb6;
    }
  }
}

#company-delete {
  position: relative;
  padding: 40px 0;
  padding-bottom: 165px;
  min-height: 100vh;
  background-image: url("../img/login/bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .brand {
    height: 30px;
    display: block;
    margin: 0 auto;
    margin-bottom: 95px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .main-image {
    display: block;
    margin: 0 auto;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    margin-bottom: 15px;
  }
  h1 {
    color: #374470;
    font-size: 60px;
    text-align: center;
    font-family: "Rubik Medium", sans-serif;
    font-weight: normal;
    margin-bottom: 20px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  p.description {
    text-align: center;
    color: #7b83aa;
    font-size: 15px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 70px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  button {
    background-color: #0e61dd;
    height: 60px;
    padding: 10px 90px;
    margin: 0 auto;
    display: block;
    margin-bottom: 55px;
    animation: fadeIn 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    position: relative;
    span {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 17px;
      font-family: "Rubik Medium", sans-serif;
      img {
        margin-left: 5px;
      }
    }
    &:hover {
      background-color: #0f5cce;
    }
  }
  .footer {
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    ul.links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      li {
        margin: 0 15px;
        a {
          font-size: 15px;
          font-family: "Roboto Regular", sans-serif;
          color: #374470;
        }
      }
    }
    p {
      text-align: center;
      font-size: 13px;
      font-family: "Roboto Regular", sans-serif;
      color: #acadb6;
    }
  }
}

.empty-list,
.no-records-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: bottom center;
  padding-bottom: 230px;
  margin: 0 -20px;
  margin-bottom: -30px;
  width: calc(100% + 40px);
  flex: 1;
  img.main-image {
    margin-bottom: 2px;
    height: 170px;
  }
  h2 {
    text-align: center;
    font-weight: 100;
    font-family: "Rubik Light", sans-serif;
    color: $primaryHeadingColor;
    font-size: 34px;
    margin-bottom: 10px;
  }
  p {
    text-align: center;
    font-family: "Rubik Light", sans-serif;
    color: $primaryHeadingColor;
    font-size: 18px;
    margin-bottom: 30px;
    max-width: 600px;
    line-height: 1.4;
  }
}

.empty-list {
  background-image: url("../img/documents/no-docs-bg.png"),
    linear-gradient(to top, #e9f5ff, #fff);
}

.no-records-found {
  padding-bottom: 270px;
  background-image: url("../img/app/no-records-bg.png");
}

//custom popover без списък на MUI
.popover-complexed {
  position: relative;
  .MuiPopover-paper {
    overflow: initial;
    min-width: 250px;
    .sign {
      position: absolute;
      left: 0;
      bottom: 100%;
      padding: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #fff;
      font-size: 15px;
    }
    .paid,
    .not-paid {
      padding: 10px;
      h3,
      h4 {
        color: $primaryHeadingColor;
        font-size: 16px;
        font-family: $primaryHeadingFontFamily;
        margin-bottom: 15px;
      }
      h4 {
        color: #657493;
        margin-bottom: 5px;
        margin-top: 15px;
        font-size: $primaryParagraphFontSize;
      }
      .MuiSelect-select {
        display: flex;
        align-items: center;
        padding-top: 6px;
        font-size: 13px;
        img {
          margin-right: 5px;
        }
      }
      .MuiButtonBase-root {
        margin: -10px;
        margin-top: 10px;
        width: calc(100% + 20px);
        border-top: 1px solid $popoverOptionsHoverBackgroundColor;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
          margin-left: -5px;
          flex-shrink: 0;
        }
      }
    }
    .paid {
      .sign {
        background-color: #3bc870;
      }
      ul {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        li {
          padding: 5px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            opacity: 0;
            pointer-events: none;
            margin-left: 10px;
            flex-shrink: 0;
            cursor: pointer;
          }
          &:hover {
            background-color: $popoverOptionsHoverBackgroundColor;
            img {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }
    .not-paid {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      .sign {
        background-color: #f8413a;
      }
      > .row {
        .col {
          width: calc(100% / 2);
          border-radius: 3px;
          padding: 5px;
          position: relative;
          margin-right: 0;
          span {
            color: $bluishColor;
            font-size: $primaryParagraphFontSize;
            margin-bottom: 5px;
            line-height: 1;
          }
          p {
            color: $primaryHeadingColor;
            font-size: $primaryParagraphFontSize;
            input {
              width: fit-content;
              background-color: transparent;
              border: none;
            }
          }
          .custom-date {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
            input[type="date"]::-webkit-calendar-picker-indicator {
              background: transparent;
              bottom: 0;
              color: transparent;
              cursor: pointer;
              height: auto;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: auto;
            }
          }
          &:hover {
            background-color: $popoverOptionsHoverBackgroundColor;
            cursor: pointer;
          }
        }
      }
      ul.payment {
        width: 100%;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% / 3);
          line-height: 1;
          text-align: center;
          color: $primaryHeadingColor;
          font-size: $primaryParagraphFontSize;
          border-radius: 3px;
          padding: 5px;
          &:hover,
          &.active {
            background-color: $popoverOptionsHoverBackgroundColor;
            cursor: pointer;
          }
          &.active {
            pointer-events: none;
          }
        }
      }
      .sum {
        margin-top: 20px;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        .row {
          align-items: center;
          position: relative;
          .col {
            width: 50%;
            flex-shrink: 0;
            flex: 1;
            margin-right: 0;
            &.red {
              color: #f8413a;
            }
            &.green {
              color: #3bc870;
            }
          }
          span {
            color: $bluishColor;
            font-size: $primaryParagraphFontSize;
            margin-bottom: 5px;
            line-height: 1;
          }
          p {
            color: $primaryHeadingColor;
            font-size: $primaryParagraphFontSize;
          }

          img {
            opacity: 0;
            pointer-events: none;
            margin-left: 10px;
            flex-shrink: 0;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
          &:not(:first-of-type) {
            padding: 5px 10px;
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: 0;
            width: calc(100% + 20px);
            &:hover {
              background-color: #f0f7fc;
              img {
                opacity: 1;
                pointer-events: all;
              }
            }
          }
        }
        .total {
          margin: -10px;
          margin-top: 10px;
          background-color: #f6f8fb;
          padding: 10px;
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        }
      }
    }
  }
}

//custom popover със списък na MUI
.custom-menu-options {
  .MuiPopover-paper {
    -webkit-box-shadow: 0px 0px 21px 0px #c7c7c7 !important;
    box-shadow: 0px 0px 21px 0px #c7c7c7 !important;
    // width: 250px !important;
    ul {
      li {
        font-size: 15px !important;
        display: flex;
        align-items: center;
        line-height: 1.2;
        &.print {
          padding: 0;
        }
        .print-button-layout {
          padding: 10px 15px;
          width: 100%;
          height: 100%;
        }
        > img,
        > .print-button-layout img {
          // width: 16px;
          width: 25px;
          height: 25px;
          object-fit: scale-down;
          margin-right: 10px;
        }
        &.current {
          color: #38bd6a;
        }
      }
    }
    .holder {
      display: flex;
      flex-wrap: wrap;
      max-width: 250px;
      margin-left: -16px;
      margin-right: -16px;
      padding: 0 16px;
      width: calc(100% + 32px);
      li {
        width: 50%;
        padding: 10px;
        border-radius: 3px;
        text-align: center;
        color: $primaryHeadingColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          margin-bottom: 2px;
          margin-right: 0;
          width: auto;
        }
        &:hover {
          background-color: $popoverOptionsHoverBackgroundColor;
        }
      }
    }
  }

  &.with-right-click {
    .MuiPopover-paper {
      left: 0 !important;
      top: 0 !important;
    }
  }

  &.no-shadow {
    .MuiPopover-paper {
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
}

.with-nested-lists {
  .MuiPopover-paper {
    overflow: initial;
    .MuiButtonBase-root {
      position: relative;
      overflow: initial;
      &.parent {
        padding-right: 30px;
        &::after {
          content: "";
          width: 5px;
          height: 9px;
          background-image: url("../img/customers/icons/next.png");
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      ul {
        position: absolute;
        left: 100%;
        top: 0;
        -webkit-box-shadow: 0px 0px 21px 0px #c7c7c7;
        box-shadow: 0px 0px 21px 0px #c7c7c7;
        background-color: #fff;
        opacity: 0;
        pointer-events: none;
        border-radius: 2px;
        max-width: 300px;
        width: fit-content;
        li {
          min-height: 37px;
          width: 100%;
          min-width: max-content;
          white-space: initial;
          letter-spacing: 1px;
        }
      }
      &:hover {
        ul {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}

.custom-menu {
  width: 21px;
  height: 21px;
  // padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  pointer-events: none;
  span {
    height: 5px;
    display: block;
    border-radius: 3px;
    background-color: $bluishColor;
    width: 100%;
    transition: 0.2s;
    &:nth-child(2) {
      margin: 4px 0;
      width: 65%;
    }
    &:nth-child(3) {
      width: 80%;
    }
  }
  &.active {
    span {
      width: 100%;
    }
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.success-message {
  background-color: #3bc870 !important;
  color: #fff !important;
}
.error-message {
  background-color: #ff134a !important;
  color: #fff !important;
}

#head,
#tabs-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  // border-bottom: 1px solid #dfe0eb;
  overflow: hidden;
  .title {
    font-size: 30px;
    color: $primaryHeadingColor;
  }

  .buttons {
    display: flex;
    align-items: center;
  }
}

#tabs-head {
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-right: -25px;
  margin-top: -20px;
  margin-bottom: 0;
  .right {
    margin-bottom: 5px;
  }
}

html {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

body {
  background-color: #edeef7;

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
}

.bold {
  font-family: $primaryHeadingFontFamily;
}

main,
.wrapper {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.tabs {
  display: flex;
  // border-bottom: 1px solid #dfe0eb;
  overflow: hidden;
  padding-right: 20px;
  // margin-bottom: -7px;
  margin-left: -10px;
  max-width: 1000px;
  > li {
    border: 1px solid transparent;
    border-bottom: none;
    border-radius: 5px;
    padding: 0px 3px;
    padding-right: 30px;
    // margin-right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    position: relative;
    height: 51px;
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      position: relative;
      z-index: 4;
      pointer-events: none;
      align-items: center;
      // direction: rtl;
      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        font-style: normal;
        background-color: #bababa;
        flex-shrink: 0;
        font-family: "Roboto Bold", sans-serif;
        pointer-events: none;
        font-size: 10px;
        color: #fff;
      }
    }
    ul.subtabs {
      display: flex;
      align-items: center;
      li {
        padding: 2px 5px;
        position: relative;
        z-index: 2;
        border: 1px solid transparent;
        border-radius: 5px;
        margin: 0 2px;
        height: fit-content;
        cursor: pointer;
        &.hidden {
          display: none;
        }
        &:not(.active):not(:nth-child(1))::before {
          // content: '';
          // width: 3px;
          // height: 3px;
          // background-color: #DFE0EB;
          // position: absolute;
          // // border-radius: 50%;
          // left: -5px;
          // top: calc(50% - 3px / 2);
        }
        // &.opening {
        //   -webkit-animation: swing-in-left-fwd 0.5s
        //     cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
        //   animation: swing-in-left-fwd 0.5s
        //     cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
        // }

        // &.closing {
        //   -webkit-animation: swing-in-left-fwd 0.5s
        //     cubic-bezier(0.175, 0.885, 0.32, 1.275) reverse both;
        //   animation: swing-in-left-fwd 0.5s
        //     cubic-bezier(0.175, 0.885, 0.32, 1.275) reverse both;
        // }
        // @-webkit-keyframes swing-in-left-fwd {
        //   0% {
        //     -webkit-transform: rotateY(100deg);
        //     transform: rotateY(100deg);
        //     -webkit-transform-origin: left;
        //     transform-origin: left;
        //     opacity: 0;
        //   }
        //   100% {
        //     -webkit-transform: rotateY(0);
        //     transform: rotateY(0);
        //     -webkit-transform-origin: left;
        //     transform-origin: left;
        //     opacity: 1;
        //   }
        // }
        // @keyframes swing-in-left-fwd {
        //   0% {
        //     -webkit-transform: rotateY(100deg);
        //     transform: rotateY(100deg);
        //     -webkit-transform-origin: left;
        //     transform-origin: left;
        //     opacity: 0;
        //   }
        //   100% {
        //     -webkit-transform: rotateY(0);
        //     transform: rotateY(0);
        //     -webkit-transform-origin: left;
        //     transform-origin: left;
        //     opacity: 1;
        //   }
        // }
      }
    }
    &.active.bordered {
      z-index: 3;
      cursor: default;
      background-color: #f5f6ff;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: none;
      margin-right: 10px;
      margin-left: 5px;
      min-width: 0;
      ul.subtabs {
        max-width: 100%;
        li {
          min-width: 0;
          padding: 0 10px;
          i {
            position: absolute;
            top: -7px;
            right: -7px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            color: #fff;
            font-size: 11px;
            background-color: #ff4e77;
            cursor: pointer;
            transform: scale(0);
            z-index: 2;
            img {
              pointer-events: none;
            }
          }
          &.active-subtab {
            border-color: #dfe0eb;
            background-color: #fff;
            border-bottom-color: #fff;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding: 10px;
            padding-right: 0;
            cursor: default;
            height: 39px;
            margin-right: 20px;
            // margin-right: 3px;
            &::after {
              content: "";
              width: 24px;
              height: 46px;
              background-image: url("../img/app/active-tab-small-shape.png");
              background-size: contain;
              background-position: top left;
              background-repeat: no-repeat;
              position: absolute;
              top: -1px;
              left: 100%;
              z-index: 1;
            }
            &::before {
              content: "";
              height: 14.99px;
              position: absolute;
              left: -1px;
              right: 0;
              background-color: #fff;
              width: 100%;
              border-left: inherit;
              top: calc(100% + 1px);
            }
            span {
              color: #0e61dd;
            }
            i.close {
              top: -5px;
              right: -15px;
            }
            &:first-of-type {
              margin-right: 20px;
            }
            &:last-of-type {
              margin-right: 3px;
            }
          }
          &:hover {
            z-index: 3;
          }
          &.active-subtab,
          &.indicate,
          &:hover {
            background-color: #fff;
            i {
              transform: scale(1);
              margin-bottom: -1px;
            }
          }
          &:first-of-type {
            flex-shrink: 0;
            min-width: auto;
          }
          &.last {
            margin-right: 0;
          }
        }
      }
      &::after {
        content: "";
        width: 29px;
        height: 51px;
        background-image: url("../img/app/active-tab-big-shape.png");
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(100% - 3px);
        z-index: 1;
      }
      &:first-of-type {
        margin-left: 10px;
      }
    }
    &.active:not(.bordered) {
      // margin-right: 0 !important;
      z-index: 3;
      ul {
        li {
          &.active-subtab {
            border-color: #dfe0eb;
            background-color: #fff !important;
            border-bottom-color: #fff;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding: 10px;
            padding-right: 0;
            cursor: default;
            // margin-right: 0 !important;
            height: 39px;
            &::after {
              // content: '';
              // width: 24px;
              // height: 46px;

              // background-size: contain;
              // background-position: top left;
              // background-repeat: no-repeat;
              // position: absolute;
              // top: -1px;
              // left: 100%;
              background-image: url("../img/app/active-tab-small-shape.png") !important;
              z-index: 1;
            }
            &::before {
              content: "";
              height: 14.99px;
              position: absolute;
              left: -1px;
              right: 0;
              background-color: #fff !important;
              width: 100%;
              border-left: inherit;
              top: calc(100% + 1px);
            }
            span {
              color: #0e61dd;
            }
            i {
              top: -5px;
              right: -15px;
            }
          }
        }
      }
    }
    &.bordered {
      border-color: #dfe0eb;
      background-color: #e4e6f2;
      ul.subtabs {
        li:last-of-type {
          margin-right: 0;
        }
      }
    }
    &:not(.active.bordered) {
      ul.subtabs {
        li {
          border-color: #dfe0eb;
          background-color: #e4e6f2;
          border-bottom-color: transparent;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 10px;
          padding-right: 0;
          height: 39px;
          &::after {
            content: "";
            width: 33px;
            height: 46px;
            background-image: url("../img/app/tab-small-shape.png");
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            left: calc(100% - 3px);
            z-index: 1;
          }
          &::before {
            content: "";
            height: 14.99px;
            position: absolute;
            left: -1px;
            right: 0;
            background-color: #e4e6f2;
            width: 100%;
            border-left: inherit;
            top: 100%;
          }
          &:hover {
            background-color: #fff;
            z-index: 4;
            &::after {
              content: "";
              width: 33px;
              height: 46px;
              background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAuCAMAAAAFiil6AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFdQTFRF3+Dr3+Dr3+Hs3N/qAAAA////+fn88/P35+fw3+Ds+/v85ebv3+Dr39/n7+/18fH239/v6+zy3+Hr/f3+4eLt39/q3d/p9/f64+Tt39/n3+Dr3d/q5+jwxwTPMQAAAB10Uk5T/8+fYAD/////3///vyD//xD/j///MID//0DvkP8G/Z59AAAAtklEQVR4nHXS0RaCIBCEYaw2Qw0tTcN6/+cMVAxhZm+/c/6LOatUcTpfwCm5ljcNSIm7qm7uEESMTmUDKVMJIG3RYZDHk0A/vDCIaQj0Q4dBzEhg0gTkbQnErQNMM4G4dQTzIRC1jhC1Evi3EqhmAvK1BPZWCnsrhb2VQWhlEFoZhFYOWyuHrZWD1JbA2gKwbg9g3R7B0kKwtBAsLQi+BcH/KgTfwuB+FYNrYXAtAmYkMBUEKv0DZ9cZdcYfHPUAAAAASUVORK5CYII=") !important;
              background-size: contain;
              background-position: top left;
              background-repeat: no-repeat;
              position: absolute;
              top: 0;
              bottom: 0;
              left: calc(100% - 3px);
              z-index: 1;
            }
            &::before {
              background-color: #fff !important;
            }
          }
        }
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

header {
  background-color: #fff;
  border-bottom: 1px solid #edeef7;
  position: relative;
  z-index: 91;
  .wrapper {
    display: flex;
    justify-content: space-between;
    height: 65px;
    .brand {
      // width: 200px;

      width: 400px;

      padding: 5px 0;
      height: 100%;
      position: relative;
      // cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h2 {
        color: #424242;
        font-size: 15px;
        line-height: 1;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        font-family: "SourceSansPro SemiBold", sans-serif;
        cursor: pointer;
        img {
          margin-left: 10px;
        }
        &.store {
          font-size: 13px;
          color: #858585;
          padding-top: 0;
        }
      }
      .logo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #576a81;
        font-family: "SourceSansPro SemiBold", sans-serif;
        font-size: 25px;
        margin-bottom: 5px;
        img {
          margin-right: 5px;
          width: 153px;
          // width: 30px;
        }
      }
      .companies-list {
        position: absolute;
        left: -10px;
        right: 0;
        top: calc(100% + 15px);
        width: 250px;
        -webkit-box-shadow: 0px 20px 18px 5px rgba(192, 195, 220, 0.4);
        box-shadow: 0px 20px 18px 5px rgba(192, 195, 220, 0.4);
        background-color: #fff;
        -webkit-animation: swing-in-top-fwd 0.5s
          cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
          both;
        .head {
          padding: 15px 10px;
          border-bottom: 1px solid #dde6ed;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h3 {
            font-family: $primaryHeadingFontFamily;
            color: $primaryHeadingColor;
            font-size: 18px;
            margin-right: 5px;
          }
        }
        ul.dropdown {
          li {
            display: block;
            color: $primaryHeadingColor;
            font-size: 15px;
            cursor: pointer;
            a {
              padding: 10px;
              display: block;
              &.active {
                background-color: rgb(248, 248, 248);
                cursor: default;
              }
              &:hover {
                background-color: rgb(248, 248, 248);
              }
            }
            ul {
              display: block;
              li {
                a {
                  padding-left: 20px;
                  &.active,
                  &:hover {
                    background-color: #efeeee;
                  }
                }
              }
            }
          }
          &::before {
            content: "";
            width: 20px;
            height: 10px;
            background-image: url("../img/header/icons/pointer-white.png");
            background-size: contain;
            background-position: bottom center;
            background-repeat: no-repeat;
            position: absolute;
            left: 15px;
            bottom: 100%;
          }
        }
        .bottom {
          padding: 10px;
          border-top: 1px solid #edeef7;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 250px;
          p {
            text-align: center;
            color: #a1aabf;
            font-size: 13px;
            margin: 10px 0;
          }
          button {
            display: block;
            width: 100%;
          }
        }
      }
      @-webkit-keyframes swing-in-top-fwd {
        0% {
          -webkit-transform: rotateX(-100deg);
          transform: rotateX(-100deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 1;
        }
      }
      @keyframes swing-in-top-fwd {
        0% {
          -webkit-transform: rotateX(-100deg);
          transform: rotateX(-100deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
          -webkit-transform-origin: top;
          transform-origin: top;
          opacity: 1;
        }
      }

      @media screen and (min-width: 1300px) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .logo {
          margin-bottom: 0;
        }
        h2 {
          padding: 5px 0;
          border-left: 1px solid #424242;
          padding-left: 10px;
          margin-left: 10px;
          margin-bottom: 0;
        }
        .companies-list {
          left: 155px;
        }
      }
    }
    ul.menu {
      display: flex;
      margin: 0 10px;
      height: 100%;
      li {
        width: 105px;
        height: 100%;
        a,
        span {
          font-size: 15px;
          color: $bluishColor;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 5px;
          position: relative;
          text-align: center;
          line-height: 1;
          img {
            // margin-bottom: 5px;
            height: 25px;
            display: block;
            object-fit: scale-down;
          }
          &::before,
          &::after {
            content: "";
            height: 3px;
            width: 0px;
            transition: 0.2s;
            position: absolute;
            bottom: -1px;
            background-color: #0e61dd;
          }
          &::before {
            right: 50%;
          }
          &::after {
            left: 50%;
          }
          &:hover {
            background-color: rgb(248, 248, 248);
            &::before,
            &::after {
              width: 40%;
            }
          }
          &.active {
            color: $primaryHeadingColor;
            img {
              filter: invert(24%) sepia(21%) saturate(1990%) hue-rotate(202deg)
                brightness(50%) contrast(88%);
            }
            &::before,
            &::after {
              width: 40%;
            }
          }
          ul {
            display: none;
            min-width: 143px;
            position: absolute;
            top: 100%;
            left: 0;
            padding: 0;
            margin: 0;
            background-color: #fff;
            z-index: 2;
            box-shadow: 0px 0px 18px 5px rgba(192, 195, 220, 0.4);
            li {
              width: 100%;
              a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                min-height: 45px;
                // text-align: center;
                padding: 5px 10px;
                &::before,
                &::after {
                  display: none;
                }
              }
            }
          }

          &:hover {
            ul {
              display: block;
            }
          }
        }
        ul {
          li {
            a {
              text-align: left;
            }
          }
        }
      }
    }
    .more {
      height: 100%;
      width: 400px;
      display: flex;
      justify-content: flex-end;
      .notifications {
        height: 100%;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        span.count {
          position: absolute;
          background-color: #ffc700;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          top: 15px;
          right: 15px;
          border: 2px solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          color: #fff;
          font-family: "Roboto Bold", sans-serif;
          pointer-events: none;
        }
        .notifications-list {
          position: absolute;
          top: calc(100% + 15px);
          border-radius: 5px;
          right: 0;
          background-color: #fff;
          width: 420px;
          -webkit-box-shadow: 0px 0px 18px 5px rgba(192, 195, 220, 0.4);
          box-shadow: 0px 0px 18px 5px rgba(192, 195, 220, 0.4);
          -webkit-animation: swing-in-top-fwd 0.5s
            cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
          animation: swing-in-top-fwd 0.5s
            cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
          .head {
            padding: 15px 10px;
            border-bottom: 1px solid #dde6ed;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
              font-family: $primaryHeadingFontFamily;
              color: $primaryHeadingColor;
              font-size: 18px;
              margin-right: 5px;
            }
            span.link {
              font-size: $primaryParagraphFontSize;
              color: #777777;
            }
          }
          > .no-data {
            display: flex;
            justify-content: center;
            padding: 100px 0;
          }
          > .scroll {
            height: 270px;
            overflow: auto;
            position: relative;
          }
          ul {
            width: 100%;
            // height: 100%;
            border-radius: inherit;
            // max-height: 270px;
            overflow: hidden;
            li {
              width: 100%;
              padding: 15px 10px;
              cursor: pointer;
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
                width: 45px;
                height: 45px;
                object-fit: contain;
                background-color: rgb(250, 250, 250);
                border-radius: 50%;
              }
              .right {
                h4 {
                  color: #777777;
                  font-size: 15px;
                }
                time {
                  font-size: $primaryParagraphFontSize;
                  color: #b4bfca;
                }
              }
              &:hover {
                background-color: #f8f8fb;
              }
              &.unread {
                background-color: rgb(250, 250, 250);
                .right {
                  h4 {
                    color: $primaryHeadingColor;
                  }
                }
              }
            }
          }
          &::before {
            content: "";
            width: 20px;
            height: 10px;
            background-image: url("../img/header/icons/pointer-white.png");
            background-size: contain;
            background-position: bottom center;
            background-repeat: no-repeat;
            position: absolute;
            right: 25px;
            bottom: 100%;
          }
        }
      }
      .user {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        cursor: pointer;
        img.profile-pic {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: #c0c3dc;
        }
        p {
          font-size: 15px;
          margin: 0 10px;
          color: $primaryHeadingColor;
          line-height: 1;
        }
        .user-list {
          position: absolute;
          left: -150px;
          top: calc(100% + 15px);
          background-color: #fff;
          width: 200px;
          -webkit-box-shadow: 0px 20px 18px 5px rgba(192, 195, 220, 0.4);
          box-shadow: 0px 20px 18px 5px rgba(192, 195, 220, 0.4);
          -webkit-animation: swing-in-top-fwd 0.5s
            cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
          animation: swing-in-top-fwd 0.5s
            cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
          .head {
            padding: 15px 10px;
            border-bottom: 1px solid #dde6ed;
            h3 {
              font-family: $primaryHeadingFontFamily;
              color: $primaryHeadingColor;
              font-size: 18px;
              margin-right: 5px;
            }
          }
          ul {
            li {
              a {
                padding: 10px;
                display: block;
                color: $primaryHeadingColor;
                font-size: 15px;
                &:hover {
                  background-color: rgb(248, 248, 248);
                }
              }
              &.logout {
                margin-top: 5px;
                a {
                  text-align: center;
                  border-top: 1px solid #c0c3dc;
                }
              }
            }
          }
          &::before {
            content: "";
            width: 20px;
            height: 10px;
            background-image: url("../img/header/icons/pointer-white.png");
            background-size: contain;
            background-position: bottom center;
            background-repeat: no-repeat;
            position: absolute;
            right: 25px;
            bottom: 100%;
          }
        }
      }
    }
  }
}

.period-new {
  background-image: url("../img/documents/filter/icons/calendar.png");
  background-position: 5px center;
  background-size: 31px 28px;
  background-repeat: no-repeat;
  padding-left: 40px !important;
  width: 225px !important;
  position: relative;
  // .period-options {
  //   border: 1px solid #e2e2e1;
  //   background-color: #fff;
  //   border-radius: 4px;
  //   background-color: #fff;
  //   width: 100%;
  //   position: absolute;
  //   top: calc(100% + 5px);
  //   left: 0;
  //   right: 0;
  //   // overflow: hidden;
  //   .rdrDateDisplayWrapper,
  //   .rdrDefinedRangesWrapper {
  //     display: none;
  //   }
  //   .rdrDateRangePickerWrapper {
  //     position: absolute;
  //     left: calc(100% + 10px);
  //     bottom: 0;
  //     z-index: 9;
  //     border: 1px solid #e2e2e1;
  //     border-radius: 4px;
  //   }
  //   ul.first-options {
  //     display: flex;
  //     padding: 10px 5px;
  //     border-bottom: 1px solid #e2e2e1;
  //     li {
  //       width: calc(100% / 2);
  //       font-size: 13px;
  //       color: $primaryHeadingColor;
  //       text-align: center;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       line-height: 1;
  //       padding: 10px 2px;
  //       border-radius: 2px;
  //       img {
  //         margin-bottom: 5px;
  //       }
  //       &:hover {
  //         background-color: #f0f7fc;
  //       }
  //     }
  //   }
  //   ul.second-options {
  //     padding: 10px 5px;
  //     display: flex;
  //     flex-direction: column;
  //     border-bottom: 1px solid #e2e2e1;
  //     li {
  //       font-size: 13px;
  //       display: flex;
  //       align-items: center;
  //       padding: 10px 5px;
  //       border-radius: 2px;
  //       color: $primaryHeadingColor;
  //       img {
  //         margin-right: 10px;
  //       }
  //       &:hover {
  //         background-color: #f0f7fc;
  //       }
  //     }
  //   }
  //   .custom-period {
  //     display: flex;
  //     align-items: center;
  //     padding: 10px 10px;
  //     font-size: 13px;
  //     color: $primaryHeadingColor;
  //     background-color: #fff9e2;
  //     cursor: pointer;
  //     position: relative;
  //     border-bottom-left-radius: inherit;
  //     border-bottom-right-radius: inherit;
  //     img {
  //       margin-right: 10px;
  //       pointer-events: none;
  //     }
  //     &:hover {
  //       background-color: #f9f3db;
  //     }
  //   }
  // }
  // .period-options {
  //   width: 370px;
  //   .year-related-new,
  //   .month-related-new li,
  //   .custom-period-new {
  //     padding: 15px 12px;
  //     margin-bottom: 0;
  //   }
  //   .year-related-new {
  //     color: #0e61dd;
  //     font-size: 14px;
  //     font-family: "SourceSansPro SemiBold", sans-serif;
  //     display: flex;
  //     align-items: center;
  //     height: 60px;
  //     cursor: pointer;
  //     border-bottom: 1px solid #e6eef4;
  //     span.icon {
  //       margin-right: 10px;
  //       font-family: "SourceSansPro SemiBold", sans-serif;
  //       font-size: 14px;
  //       background-size: cover;
  //       background-repeat: no-repeat;
  //       background-position: center;
  //       width: 35px !important;
  //       height: 35px !important;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       color: #0e61dd;
  //       padding-top: 6px;
  //       &.prev-year {
  //         background-image: url("../img/documents/filter/icons/prev-year-new.svg");
  //         padding-left: 5px;
  //       }
  //       &.current-year {
  //         background-image: url("../img/documents/filter/icons/current-year.svg");
  //       }
  //     }
  //   }
  //   ul.month-related-new {
  //     border-bottom: 1px solid #e6eef4;
  //     display: flex;
  //     li {
  //       flex: 1;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       img,
  //       span.icon {
  //         width: 68px !important;
  //         height: 68px !important;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //       }
  //       span.icon {
  //         text-transform: uppercase;
  //         font-family: "SourceSansPro SemiBold", sans-serif;
  //         font-size: 14px;
  //         background-size: cover;
  //         background-repeat: no-repeat;
  //         background-position: center;
  //         padding-top: 39px;
  //         color: #0e61dd;
  //         position: relative;
  //         &.prev-month {
  //           background-image: url("../img/documents/filter/icons/prev-month.svg");
  //         }
  //         &.current-month {
  //           background-image: url("../img/documents/filter/icons/current-month.svg");
  //           &::before {
  //             content: "";
  //             width: 11px;
  //             height: 11px;
  //             background-image: url("../img/documents/filter/icons/current-month-pointer.svg");
  //             background-position: center;
  //             background-repeat: no-repeat;
  //             background-size: contain;
  //             position: absolute;
  //             left: 50%;
  //             transform: translateX(-50%);
  //             bottom: 100%;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   .custom-period-new {
  //     cursor: default;
  //     b {
  //       color: $primaryHeadingColor;
  //       font-size: 14px;
  //       font-family: "SourceSansPro SemiBold", sans-serif;
  //       display: block;
  //       text-align: center;
  //       margin-bottom: 10px;
  //     }
  //     ul {
  //       display: flex;
  //       align-items: center;
  //       li.date-holder-new:not(.middle) {
  //         position: relative;
  //         background: #ffffff;
  //         border: 1px solid #dde6ed;
  //         border-radius: 4px;
  //         height: 47px;
  //         flex: 1;
  //         display: flex;
  //         align-items: center;
  //         justify-content: flex-start;
  //         text-align: center;
  //         color: $primaryHeadingColor;
  //         font-size: 14px;
  //         padding-left: 45px;
  //         input {
  //           position: absolute;
  //           left: 0;
  //           top: 0;
  //           width: 100%;
  //           height: 100%;
  //           opacity: 0;
  //           cursor: pointer;
  //           &::-webkit-calendar-picker-indicator {
  //             background: transparent;
  //             bottom: 0;
  //             color: transparent;
  //             cursor: pointer;
  //             height: auto;
  //             left: 0;
  //             position: absolute;
  //             right: 0;
  //             top: 0;
  //             width: auto;
  //           }
  //         }
  //         &::before {
  //           content: "";
  //           width: 35px;
  //           height: 35px;
  //           background-size: contain;
  //           background-repeat: no-repeat;
  //           background-position: center;
  //           position: absolute;
  //           left: 5px;
  //           top: 50%;
  //           transform: translateY(-50%);
  //         }
  //         &.start-date::before {
  //           background-image: url("../img/documents/filter/icons/start-date.svg");
  //         }
  //         &.end-date::before {
  //           background-image: url("../img/documents/filter/icons/end-date.svg");
  //         }
  //       }
  //       li.middle {
  //         padding: 0 5px;
  //         color: #424242;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  //   .active-new,
  //   .hover-new:hover {
  //     background: linear-gradient(
  //       277.48deg,
  //       #28a3ff 43.37%,
  //       #0b76f3 98.88%
  //     ) !important;
  //     color: #fff;
  //   }
  // }
  .period-options {
    border: 1px solid #dde6ed;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    width: 320px;
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    * {
      font-weight: normal;
    }
    .header {
      background-color: #e5e6ed;
      margin-bottom: 5px;
      min-height: 32px;
      padding: 2px 10px;
      display: flex;
      align-items: center;
      color: #506080;
      font-family: "SourceSansPro SemiBold", sans-serif;
      font-weight: 500;
    }
    img,
    span.icon {
      width: 35px !important;
      height: 35px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    span.icon {
      text-transform: uppercase;
      font-family: "SourceSansPro Bold", sans-serif;
      font-size: 14px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 10px;
      color: #0e61dd;
      &.prev-month {
        background-image: url("../img/documents/filter/icons/prev-month.png");
      }
      &.current-month {
        background-image: url("../img/documents/filter/icons/current-month.png");
      }
    }
    ul {
      li {
        min-height: 47px;
        display: flex;
        align-items: center;
        padding: 2px 10px;
        cursor: pointer;
        color: #0e61dd;
        font-size: 15px;
        &:hover,
        &.active-new {
          background-color: #0e61dd;
          color: #fff;
          img,
          span.icon {
            filter: brightness(0) saturate(100%) invert(100%) sepia(4%)
              saturate(15%) hue-rotate(147deg) brightness(101%) contrast(104%);
          }
          span.icon {
            color: #fff;
          }
        }
        &.active-new {
          position: relative;
          &::after {
            content: "";
            width: 13px;
            height: 10px;
            background-image: url("../img/documents/icons/check.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &:first-of-type {
        li {
          text-transform: capitalize;
        }
      }
    }
    .row {
      padding: 2px 10px;
      min-height: 47px;
      justify-content: space-between;
      .year-related-new {
        border: 1px solid #dde6ed;
        border-radius: 4px;
        min-height: 58px;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 2 - 2.5px);
        .right {
          font-size: 12px;
          color: #0e61dd;
          line-height: 1;
          i {
            font-style: normal;
            font-size: 19px;
          }
        }
        &:hover,
        &.active-new {
          background-color: #0e61dd;
          .right {
            *,
            & {
              color: #fff;
            }
          }
          img,
          span.icon {
            filter: brightness(0) saturate(100%) invert(100%) sepia(4%)
              saturate(15%) hue-rotate(147deg) brightness(101%) contrast(104%);
          }
          span {
            color: #fff;
          }
        }
      }
      .calendar-holder {
        display: flex;
        align-items: center;
        width: fit-content;
        i {
          padding-right: 5px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          opacity: 1;
          font-size: 15px;
          font-style: normal;
          color: #2c2c2c !important;
        }
        .date-holder-new {
          height: 40px;
          width: 120px;
          position: relative;
          border: 1px solid #dde6ed;
          border-radius: 4px;
          padding: 2px 10px;
          display: flex;
          align-items: center;
          color: #2c2c2c;
          font-size: 15px;
          img {
            width: 21px !important;
            height: 23px !important;
            object-fit: scale-down;
            object-position: bottom;
            margin-right: 2px;
          }
          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
            width: 100%;
            height: 100%;
            &::-webkit-calendar-picker-indicator {
              background: transparent;
              bottom: 0;
              color: transparent;
              cursor: pointer;
              height: auto;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: auto;
            }
          }
        }
      }
    }
    hr {
      height: 5px;
      border-top: 1px solid #edeef7;
      border-bottom: 1px solid #edeef7;
      background-color: transparent;
      margin: 5px 0;
    }
  }
}

.communication-holder{
  span.files-count {
    background-color: $primaryHeadingColor;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 11px;
    font-family: "SourceSansPro SemiBold", sans-serif;
  }
}

main {
  margin: 25px auto;
  > .container-fluid,
  > .store-moves {
    margin-bottom: -25px;
    display: flex;
    flex-direction: column;
    flex: 1;
    > .panel {
      flex: 1;
      display: flex;
      flex-direction: column;
      .no-records-found {
        flex: 1;
      }
    }
  }
}

hr {
  margin: 10px 0;
  display: block;
  border: none;
  background-color: #edeef7;
  height: 1px;
}

.tabs-mini {
  max-width: 250px;
  min-height: initial !important;
  .MuiTab-root {
    min-width: initial;
    min-height: initial;
    padding: 0 12px;
  }
  .MuiTabs-root {
    min-height: initial;
  }
  .Mui-disabled {
    display: none;
  }
}

img[alt="loading"] {
  height: 100px;
  display: block;
  margin: 0 auto;
}
.filter-holder {
  position: relative;
  padding-right: 150px;
  .pagination-mini {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.filter {
  margin-bottom: 10px !important;
  position: relative;
  z-index: 9;
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 16px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 14.5px;
    padding-bottom: 14.5px;
  }
  .MuiFormControl-marginNormal {
    margin: 0;
  }
  .rdrDayEndPreview,
  .rdrDayStartPreview,
  .rdrDayInPreview {
    border-radius: 0 !important;
  }
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    margin-left: -5px;
    margin-right: -5px;
    background-color: rgba(182, 214, 255, 0.15);
  }
  .rdrDayInPreview {
    border-left: none !important;
    border-right: none !important;
    margin-left: -5px;
    margin-right: -5px;
    background-color: rgba(182, 214, 255, 0.15);
  }
  .rdrEndEdge,
  .rdrInRange,
  .rdrEndEdge,
  .rdrStartEdge {
    border-radius: 0 !important;
  }
  span.applied {
    background-color: #0e61dd;
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 3px;
  }
  .custom-filter-select {
    height: 50px;
    border: 1px solid #e2e2e1;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    width: 190px;
    position: relative;
    padding-left: 20px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    .MuiCheckbox-colorSecondary.Mui-checked,
    .MuiRadio-colorPrimary.Mui-checked {
      color: #3bc870;
    }
    .option {
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      font-size: 15px;
      color: $primaryHeadingColor;
    }
    &.period {
      background-image: url("../img/documents/filter/icons/calendar.png");
      background-position: 5px center;
      background-size: 31px 28px;
      background-repeat: no-repeat;
      padding-left: 40px;
      width: 225px;
      .period-options {
        border: 1px solid #e2e2e1;
        background-color: #fff;
        border-radius: 4px;
        background-color: #fff;
        width: 100%;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        // overflow: hidden;
        .rdrDateDisplayWrapper,
        .rdrDefinedRangesWrapper {
          display: none;
        }
        .rdrDateRangePickerWrapper {
          position: absolute;
          left: calc(100% + 10px);
          bottom: 0;
          z-index: 9;
          border: 1px solid #e2e2e1;
          border-radius: 4px;
        }
        ul.first-options {
          display: flex;
          padding: 10px 5px;
          border-bottom: 1px solid #e2e2e1;
          li {
            width: calc(100% / 2);
            font-size: 13px;
            color: $primaryHeadingColor;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1;
            padding: 10px 2px;
            border-radius: 2px;
            img {
              margin-bottom: 5px;
            }
            &:hover {
              background-color: #f0f7fc;
            }
          }
        }
        ul.second-options {
          padding: 10px 5px;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #e2e2e1;
          li {
            font-size: 13px;
            display: flex;
            align-items: center;
            padding: 10px 5px;
            border-radius: 2px;
            color: $primaryHeadingColor;
            img {
              margin-right: 10px;
            }
            &:hover {
              background-color: #f0f7fc;
            }
          }
        }
        .custom-period {
          display: flex;
          align-items: center;
          padding: 10px 10px;
          font-size: 13px;
          color: $primaryHeadingColor;
          background-color: #fff9e2;
          cursor: pointer;
          position: relative;
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
          img {
            margin-right: 10px;
            pointer-events: none;
          }
          &:hover {
            background-color: #f9f3db;
          }
        }
      }
    }
    &.sent {
      width: 150px;
      .sent-options {
        border: 1px solid #e2e2e1;
        background-color: #fff;
        border-radius: 4px;
        background-color: #fff;
        width: 100%;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        overflow: hidden;
      }
      ul {
        padding: 10px 5px;
        li {
          padding: 10px 5px;
          font-size: 13px;
          color: $primaryHeadingColor;
          &:hover {
            background-color: #f0f7fc;
          }
        }
      }
    }
    &.paid {
      padding-left: 15px;
      .option {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
      .paid-options {
        border: 1px solid #e2e2e1;
        background-color: #fff;
        border-radius: 4px;
        background-color: #fff;
        min-width: 250px;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        overflow: hidden;
        cursor: default;
        ul.first-options {
          padding: 10px 5px;
          border-bottom: 1px solid #e2e2e1;
          li {
            padding: 10px 5px;
            display: flex;
            align-items: center;
            font-size: 13px;
            color: $primaryHeadingColor;
            border-radius: 2px;
            cursor: pointer;
            img {
              margin-right: 5px;
            }
            &:hover {
              background-color: #f0f7fc;
            }
          }
        }
        ul.second-options {
          display: flex;
          flex-wrap: wrap;
          padding: 10px 5px;
          li {
            width: calc(100% / 2);
            font-size: 13px;
            color: $primaryHeadingColor;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1;
            padding: 10px 2px;
            border-radius: 2px;
            cursor: pointer;
            img {
              margin-bottom: 5px;
              height: 29px;
              object-fit: scale-down;
            }
            &:hover,
            &.active {
              background-color: #f0f7fc;
            }
          }
          &.extra {
            padding-top: 0;
            margin-top: -10px;
          }
        }
        span.more {
          background-color: #fafafa;
          border-top: 1px solid #e2e2e1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 13px;
          color: $primaryHeadingColor;
          img {
            margin-left: 5px;
          }
          &:hover {
            background-color: #f4f2f2;
          }
        }
      }
    }
    &.doc-type {
      padding-left: 15px;
      width: 210px;
      .option {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
      .doc-type-options {
        border: 1px solid #e2e2e1;
        background-color: #fff;
        border-radius: 4px;
        background-color: #fff;
        min-width: 250px;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        overflow: hidden;
        cursor: default;
        ul.all,
        ul.accounting {
          border-bottom: 1px solid #e2e2e1;
          padding: 10px 5px;
          li {
            .MuiFormControlLabel-root {
              margin: 0 !important;
              padding: 10px 5px;
              width: 100%;
            }
            .MuiTypography-root {
              font-size: 13px;
              color: $primaryHeadingColor;
              letter-spacing: initial;
              font-weight: initial;
              font-family: "SourceSansPro Regular", sans-serif;
            }
            &:hover {
              background-color: #f0f7fc;
            }
          }
        }
        ul.first-options {
          padding: 10px 5px;
          border-bottom: 1px solid #e2e2e1;
          li {
            display: flex;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            .MuiFormControlLabel-root {
              margin: 0 !important;
              padding: 10px 5px;
              width: 100%;
            }
            .MuiTypography-root {
              font-size: 13px;
              color: $primaryHeadingColor;
              letter-spacing: initial;
              font-weight: initial;
              font-family: "SourceSansPro Regular", sans-serif;
            }
            img {
              margin-right: 5px;
            }
            &:hover {
              background-color: #f0f7fc;
            }
          }
        }
      }
    }
    &::after {
      content: "";
      background-image: url("../img/documents/filter/icons/down.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 11px;
      height: 5px;
      position: absolute;
      right: 5px;
      top: calc(50% - 5px / 2);
    }
  }
  .col:not(.search) {
    flex: initial !important;
    width: fit-content !important;
  }
  .search {
    width: 300px !important;
    flex: initial !important;
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  transition: 0.3s;
  display: none;
  align-items: center;
  justify-content: center;
  &.income-overlay,
  &.expense-overlay {
    .popup-primary {
      border-radius: 6px;
      // overflow: hidden;
      max-height: initial;
      .income-head {
        padding: 27px 20px;
        border-radius: inherit;
        padding-right: 50%;
        position: relative;
        h2 {
          color: #fff;
          font-size: 25px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          margin-bottom: 5px;
        }
        p {
          color: #d4e6fa;
          font-size: 15px;
        }
      }
      .income-body {
        padding: 10px 20px;
        hr {
          margin: 20px 0;
        }
        input[type="date"] {
          color: #1d518f;
          font-size: 13px;
          padding-left: 25px;
          background-size: 15px auto;
          & ~ fieldset {
            border: none;
          }
        }
        .row {
          .col {
            position: relative;
            input[name="category"] {
              min-width: 350px;
              height: 23px;
            }
            input[name="amount"] {
              padding-right: 45px;
              // height: 23px;
              height: 19px;

              font-size: 18px;
              text-align: right;
              color: #1d518f;
            }
            button.currency {
              width: 31px;
              height: 34px;
              background: #f0f7fc;
              border-radius: 4px;
              min-width: initial;
              color: #1d518f;
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translateY(-50%);
            }
            &.shorten:not(.not-shorten) {
              .MuiInputLabel-filled {
                transform: translate(0px, 5px) scale(1);
              }
              .MuiFormLabel-root {
                font-size: 15px;
              }
              .MuiInputBase-root {
                height: 30px;
                padding: 0;
                border: none;
                textarea,
                input {
                  cursor: pointer;
                }
              }
            }
            .payment-methods {
              color: rgba(0, 0, 0, 0.54);
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-size: 15px;
              cursor: pointer;
              display: flex;
              align-items: center;
            }
            .selected-method {
              height: auto;
              width: 25px;
              object-fit: scale-down;
              object-position: center;
              cursor: pointer;
              margin-right: 5px;
            }
            .uploaded-files {
              max-height: 120px;
              overflow: auto;
              margin-top: 5px;
              width: 275px;
            }
          }
        }
        .product-input {
          .category-dropdown {
            position: absolute;
            top: 45px;
            left: 0;
            width: 600px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            // box-shadow: 0 0 10px #ccc;
            padding: 20px 15px;
            z-index: 10;
            max-height: 420px;
            overflow-y: auto;
            display: none;
            &.show {
              display: block;
            }

            .row {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              .col {
                margin-right: 30px;
                .heading {
                  padding: 0 5px;
                  .current-path {
                    display: block;
                    font-size: 10px;
                  }
                }

                .ul {
                  margin-top: 10px;
                  max-height: 100px;
                  // min-height: 140px;
                  overflow-y: auto;
                  padding-right: 10px;

                  &.no-results {
                    display: flex;
                    justify-content: center;
                    height: 100vh;
                    align-items: center;
                    p {
                      margin: 40px 0;
                      font-size: 13px;
                    }
                  }
                  ul {
                    li {
                      font-size: 15px;
                      padding: 4px 5px;
                      padding-right: 20px;
                      margin-bottom: 2px;
                      border-radius: 4px;
                      cursor: pointer;
                      position: relative;
                      h6 {
                        color: $primaryHeadingColor;
                        font-size: 15px;
                        font-family: "SourceSansPro SemiBold";
                        font-weight: normal;
                      }
                      span {
                        color: #aeaeae;
                        font-size: 13px;
                        font-family: "SourceSansPro Regular";
                      }
                      &:last-child {
                        margin-bottom: 0;
                      }

                      &:hover,
                      &.selected {
                        background-color: #e0edf6;
                      }
                      .direct-add {
                        display: flex;
                        align-items: center;
                      }
                      .arrow {
                        display: none;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translate(0, -50%);
                      }

                      &:hover {
                        .arrow {
                          display: block;
                        }
                      }
                    }
                  }
                  &.categories {
                    ul {
                      li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0;
                        img.folder {
                          margin: 0 2px;
                        }
                      }
                    }
                  }
                }
              }

              .col-2 {
                width: calc(100% / 2 - 30px);
                .head {
                  padding: 0;
                  h6 {
                    display: flex;
                    align-items: center;
                    font-family: "SourceSansPro Bold", sans-serif;
                    font-size: 15px;
                    color: $primaryHeadingColor;
                    img {
                      margin-right: 5px;
                    }
                  }
                  span {
                    color: $primaryHeadingColor;
                    font-size: 10px;
                  }
                }
                &:nth-child(2) {
                  margin-right: 0;
                  width: calc(100% / 2);
                }
                &:last-of-type {
                  margin-right: 0;
                }
              }

              .col-3 {
                width: calc(100% / 3 - 30px);
              }

              .load-more {
                font-size: 12px;
                background: #eee;
                padding: 2px 5px;
                margin-top: 5px;
              }

              .back-button {
                margin-top: 10px;
                background-color: $primaryHeadingColor;
                padding: 2px 5px;
                padding-left: 30px;
                border-radius: 4px;
                color: #fff;
                font-size: 12px;
                cursor: pointer;
                position: relative;

                .arrow {
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translate(0, -50%);
                }

                &:hover {
                  background-color: #555;
                }
              }
            }

            .divider {
              border: 1px solid #eee;
              margin: 15px 0;
              &.big {
                border-width: 2px;
                margin-left: -15px;
                margin-right: -15px;
                width: calc(100% + 30px);
                border-color: #dde6ed;
              }
            }

            .products-found {
              ul {
                max-height: 100px;
                overflow: auto;
                li {
                  padding: 4px 5px;
                  padding-right: 20px;
                  border-radius: 4px;
                  cursor: pointer;
                  h6 {
                    color: $primaryHeadingColor;
                    font-size: 15px;
                    font-family: "SourceSansPro SemiBold";
                    font-weight: normal;
                  }
                  span {
                    color: #aeaeae;
                    font-size: 13px;
                    font-family: "SourceSansPro Regular";
                  }
                  &:hover,
                  &.selected,
                  &.category-found-selected {
                    background-color: #e0edf6;
                  }
                  &.category-found-selected {
                    min-height: 47px;
                    padding-right: 40px;
                    position: relative;
                    &::after {
                      content: "";
                      background-image: url("../img/documents/icons/enter.png");
                      background-position: center;
                      background-size: contain;
                      background-repeat: no-repeat;
                      width: 20px;
                      height: 20px;
                      position: absolute;
                      top: calc(50% - 20px / 2);
                      right: 10px;
                      -webkit-animation: heartbeat 1.5s ease-in-out infinite
                        both;
                      animation: heartbeat 1.5s ease-in-out infinite both;
                    }
                    @-webkit-keyframes heartbeat {
                      from {
                        -webkit-transform: scale(1);
                        transform: scale(1);
                        -webkit-transform-origin: center center;
                        transform-origin: center center;
                        -webkit-animation-timing-function: ease-out;
                        animation-timing-function: ease-out;
                      }
                      10% {
                        -webkit-transform: scale(0.91);
                        transform: scale(0.91);
                        -webkit-animation-timing-function: ease-in;
                        animation-timing-function: ease-in;
                      }
                      17% {
                        -webkit-transform: scale(0.98);
                        transform: scale(0.98);
                        -webkit-animation-timing-function: ease-out;
                        animation-timing-function: ease-out;
                      }
                      33% {
                        -webkit-transform: scale(0.87);
                        transform: scale(0.87);
                        -webkit-animation-timing-function: ease-in;
                        animation-timing-function: ease-in;
                      }
                      45% {
                        -webkit-transform: scale(1);
                        transform: scale(1);
                        -webkit-animation-timing-function: ease-out;
                        animation-timing-function: ease-out;
                      }
                    }
                    @keyframes heartbeat {
                      from {
                        -webkit-transform: scale(1);
                        transform: scale(1);
                        -webkit-transform-origin: center center;
                        transform-origin: center center;
                        -webkit-animation-timing-function: ease-out;
                        animation-timing-function: ease-out;
                      }
                      10% {
                        -webkit-transform: scale(0.91);
                        transform: scale(0.91);
                        -webkit-animation-timing-function: ease-in;
                        animation-timing-function: ease-in;
                      }
                      17% {
                        -webkit-transform: scale(0.98);
                        transform: scale(0.98);
                        -webkit-animation-timing-function: ease-out;
                        animation-timing-function: ease-out;
                      }
                      33% {
                        -webkit-transform: scale(0.87);
                        transform: scale(0.87);
                        -webkit-animation-timing-function: ease-in;
                        animation-timing-function: ease-in;
                      }
                      45% {
                        -webkit-transform: scale(1);
                        transform: scale(1);
                        -webkit-animation-timing-function: ease-out;
                        animation-timing-function: ease-out;
                      }
                    }
                  }
                }
              }
            }
            .add-to-categories {
              // padding: 15px 10px;
              h3 {
                color: $primaryHeadingColor;
                font-size: 15px;
                font-family: "SourceSansPro SemiBold";
                font-weight: normal;
                margin-bottom: 10px;
              }
              input {
                border: 1px solid #dde6ed;
                border-radius: 3px;
                width: 100%;
                height: 40px;
                color: #b4bfca;
                padding: 0 15px;
                padding-left: 30px;
                margin-bottom: 10px;
                background-image: url("../img/documents/icons/search.png");
                background-position: 10px center;
                background-repeat: no-repeat;
                &::placeholder {
                  color: #b4bfca;
                }
              }
              ul {
                max-height: 100px;
                overflow: auto;
                li {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0;
                  font-size: 15px;
                  padding: 0px 5px;
                  border-radius: 4px;
                  cursor: pointer;
                  position: relative;
                  color: $primaryHeadingColor;
                  img {
                    flex-shrink: 0;
                  }
                  .direct-add {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    min-height: 37px;
                  }
                  img.folder {
                    margin: 0 2px;
                  }
                  button.nested-add {
                    opacity: 0;
                    pointer-events: none;
                    flex-shrink: 0;
                    border-radius: 50%;
                    padding: 0;
                    min-width: auto !important;
                    width: 29px;
                    height: 29px;
                    margin-left: 5px;
                    img {
                      max-width: 100%;
                    }
                  }
                  &:hover {
                    button.nested-add {
                      opacity: 1;
                      pointer-events: all;
                    }
                  }
                  &:hover,
                  &.selected {
                    background-color: #e0edf6;
                  }
                }
              }
              .no-data {
                margin: 40px 0;
                font-size: 15px;
                text-align: center;
              }

              button.add-category {
                span {
                  color: $primaryHeadingColor;
                  font-size: 15px;
                  display: flex;
                  align-items: center;
                  img {
                    margin-right: 5px;
                  }
                }
              }
            }

            /* width */
            ::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
              background: #fff;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: #dde6ed;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: #e0edf6;
            }
          }
          .initial-add {
            position: absolute;
            top: 45px;
            left: 0;
            width: 100%;
            border: 1px solid #dde6ed;
            border-radius: 4px;
            // box-shadow: 0 0 10px #ccc;
            padding: 20px 15px;
            z-index: 10;
            max-height: 420px;
            overflow-y: auto;
            display: none;
            align-items: center;
            background-color: #f8faff;
            cursor: pointer;
            img {
              flex-shrink: 0;
              margin-right: 10px;
            }
            .right {
              h3 {
                color: $primaryHeadingColor;
                font-size: 15px;
                font-family: "SourceSansPro SemiBold", sans-serif;
                margin-bottom: 10px;
              }
              p {
                font-size: 13px;
                color: $primaryParagraphColor;
              }
            }
            &:hover {
              background-color: #f1f4fb;
            }
            &.show {
              display: flex;
            }
          }
        }
      }
    }
  }
  &.income-overlay {
    .popup-primary {
      .income-head {
        background-image: linear-gradient(to top, #97b7d4, #82a3ca);
        &::after {
          content: "";
          width: 305px;
          height: 126px;
          background-image: url("../img/finances/incomes/cash.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          right: 15px;
          top: 15px;
        }
      }
    }
  }
  &.expense-overlay {
    .popup-primary {
      .income-head {
        background-image: linear-gradient(to top, #eaced7, #e8c9d3);
        h2 {
          color: #936b6e;
        }
        p {
          color: #b68c99;
        }
        &::after {
          content: "";
          width: 328px;
          height: 122px;
          background-image: url("../img/finances/incomes/bill.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          right: 0;
          top: 20px;
        }
      }
    }
  }
  &.scrollable {
    padding: 15px 0 !important;
    .popup-primary {
      margin: auto !important;
    }
  }
}

.visible {
  display: flex;
}

button.select-unit {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  height: 100%;
  min-width: initial;
  span {
    color: $primaryHeadingColor;
    font-size: 14px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  li {
    cursor: pointer;
    border: 1px solid #dde6ed;
    border-right: none;
    a {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #626262;
      font-size: 15px;
      border-radius: 2px;
      cursor: pointer;
    }
    a:hover {
      background-color: #f4f9fd;
    }
    &:last-of-type {
      border-right: 1px solid #dde6ed;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 1;
  }
  .active {
    cursor: default;
    a {
      color: white;
      background-color: #3f51b5;
      pointer-events: none;
      cursor: default;
    }
    // a:hover {
    //   background-color: #3f51b5;
    // }
  }
}

.pagination-mini {
  color: $primaryHeadingColor;
  font-size: 15px;
  display: flex;
  align-items: center;
  button.items-per-page {
    background-color: #d8e6f0;
    height: auto;
    width: fit-content;
    min-width: initial;
    padding: 0px 7px;
    margin: 0 5px !important;
    display: block;
  }
  button.pages {
    width: 29px;
    height: 29px;
    min-width: initial;
    border: 1px solid #d7e1e9;
    background-color: #f7f7fc;
    border-radius: 0;
    margin-left: 5px !important;
    img {
      width: 5px;
    }
    &.prev-page {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    &.next-page {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    &[disabled] {
      opacity: 0.4 !important;
    }
  }
}

.pagination-options {
  color: $primaryHeadingColor;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background-color: #d8e6f0;
    height: auto;
    width: fit-content;
    min-width: initial;
    padding: 0px 7px;
    margin: 0 5px !important;
    display: block;
    span {
      color: $primaryHeadingColor;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiMenuItem-root {
  font-size: 15px !important;
}

//tables
table {
  &.type-doc {
    width: 100%;
    border-collapse: collapse;
    thead {
      position: relative;
      tr {
        th {
          color: $primaryHeadingColor;
          font-size: $primaryParagraphFontSize;
          text-transform: uppercase;
          // border-bottom: 1px solid #808080;
          padding: 8px;
          padding-bottom: 25px;
          text-align: left;
          font-family: $primaryHeadingFontFamily;
        }
      }
      &::before {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        background-color: #808080;
      }
    }

    tbody {
      tr {
        td {
          padding: 5px 7px;
        }

        td:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        td:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        &:nth-child(even) td {
          background: #edf2f5;
        }
      }
    }

    td {
      input,
      .MuiFormControl-root {
        background-color: #fff;
        border-radius: 4px;
        .MuiOutlinedInput-notchedOutline {
          border-color: #dde6ed;
        }
        .MuiOutlinedInput-root.Mui-error {
          .MuiOutlinedInput-notchedOutline {
            border-color: #f44336 !important;
          }
          .MuiInputAdornment-root {
            background-color: #f44336 !important;
            p {
              color: #fff !important;
            }
          }
        }
      }
    }
    th.option,
    td.option {
      // width: 5%;
      width: 58px;
    }
    th.number,
    td.number {
      // width: 3%;
      width: 35px;
    }

    th.product,
    td.product {
      // width: 43%;
      // width: 100%;
    }

    th.quantity,
    td.quantity {
      // width: 7%;
      width: 92px;
    }

    th.unit,
    td.unit {
      // width: 5%;
      width: 100px;
    }

    th.single-price,
    td.single-price {
      // width: 11%;
      width: 132px;
    }

    th.discount,
    td.discount {
      // width: 7%;
      width: 86px;
    }

    th.price,
    td.price {
      // width: 10%;
      width: 125px;
    }

    // th:nth-child(9),
    // td:nth-child(9) {
    //   width: 100px;
    //   text-align: center;
    // }
  }
  &.type-outer {
    border: none;
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        th {
          color: $primaryHeadingColor;
          font-size: $primaryParagraphFontSize;
          text-transform: uppercase;
          padding: 8px;
          text-align: left;
          background-color: #e5e6ed;
          font-family: $primaryHeadingFontFamily;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 8px;
          &.product-with-cat {
            line-height: 1;
            span {
              color: #c6c6c6;
              font-size: 11px;
            }
          }
          &.date {
            width: 100px;
          }
          .MuiButtonBase-root {
            span {
              pointer-events: none;
            }
          }
          &.price {
            min-width: 120px;
          }
        }
        &.small-padding {
          td {
            padding: 0 8px;
            .MuiCheckbox-root {
              padding: 0 9px !important;
            }
            button.omb,
            button.menu-button {
              height: 35px;
              width: 35px;
              min-width: 35px;
            }
          }
        }
        &:nth-child(even) {
          td {
            background-color: rgb(251, 251, 251);
          }
        }
        &:hover,
        &.active {
          td {
            background-color: rgb(243, 243, 243);
            cursor: pointer;
          }
        }
      }
    }
  }
  &.with-head-padding {
    thead {
      tr {
        th {
          padding: 20px 8px;
        }
      }
    }
  }

  .editing {
    background: #f6f6f6;
  }
}

.scrollable {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

// grid
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  // gap: 0 20px;
  &.checkbox-row {
    align-items: center;
    span.MuiButtonBase-root {
      padding: 0;
      margin-right: 5px;
    }
    > span.label {
      color: $primaryHeadingColor;
      cursor: pointer;
      line-height: 1.1;
    }
  }
  .col {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
    width: calc(100% - 20px);
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;
  &.col-2 {
    width: calc(50% - 20px);
  }
  &.small {
    flex: 0.5;
  }
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.center {
  justify-content: center;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.align-end {
  align-items: end;
}

.heading {
  color: $primaryHeadingColor;
  font-size: 16px;
  font-family: $primaryHeadingFontFamily;
}

.of-list {
  background-color: #e5e6ed;
  padding: 8px;
}

// form, input
input {
  // background-color: #fff !important;
  border-radius: 4px;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  // margin-bottom: 12px;
  margin-right: 12px;

  label {
    display: flex;
    align-items: center;
  }

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #2581fe;
  }

  .text {
    margin-left: 10px;
  }

  /* On mouse-over, add background color */
  &:hover input ~ .checkmark {
    background-color: #f0f0f0;
  }

  /* When the radio button is checked, add a background */
  input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #2581fe;
  }
}
.numbers-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px #ccc;
  z-index: 10;
  h3 {
    padding: 10px;
    font-weight: 700;
    font-family: "Roboto SemiBold", sans-serif;
    color: $primaryHeadingColor;
    font-size: 15px;
  }
  ul {
    max-height: 170px;
    overflow: auto;
    li {
      padding: 10px;
      img {
        position: absolute;
        right: 0;
        width: 25px;
        object-fit: scale-down;
        margin-right: 0;
      }
    }
  }
  .bottom {
    padding: 10px;
    margin-top: 10px;
    border-top: 1px solid #e6eef4;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.active-doc {
  flex: 1;
  display: flex;
  #documents {
    flex: 1;
  }
}
.MuiOutlinedInput-root {
  box-shadow: none !important;
  border: none !important;
}
.central-styled {
  .custom-date {
    display: flex;
    align-items: center;
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
    label {
      margin-right: 5px;
    }

    input[type="date"] {
      background-image: url("../img/app/icons/calendar.png");
      position: relative;
      max-width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      // padding-right: 36px;
      background-repeat: no-repeat;
      // background-position: calc(100% - 10px) 50%;
      background-position: 10px 50%;
      padding-left: 38px;

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        // display: none;
        // -webkit-appearance: none;
        opacity: 0;
      }

      &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
    }

    &.right {
      input[type="date"] {
        padding-left: 7px;
        padding-right: 36px;
        background-position: calc(100% - 10px) 50%;
      }
    }
  }
}

.panel {
  margin-top: 16px;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  &:first-child {
    margin-top: 0;
  }

  > .heading {
    margin-bottom: 15px;
  }

  > .row {
    > .col-2:nth-child(1) {
      margin-right: 4px;
    }
    > .col-2:nth-child(2) {
      margin-left: 4px;
    }
  }
}

#footer {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #bab8c6;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin: 0 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.validate {
  border: 1px solid #f44336 !important;
}

button {
  cursor: pointer;
  border-radius: 4px;
  border: none;
  margin: 0 5px;
  height: 40px;
  font-size: 15px;
  span {
    text-transform: initial;
    font-size: 15px;
  }
  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
  &.omb {
    min-width: 40px;
    border-radius: 50%;
    padding: 0;
    .payment-indicator {
      position: relative;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
      &.not-paid::before {
        content: "";
        position: absolute;
        left: 1px;
        top: 1px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 50%;
        // border: 1px solid #a8a8b2;
        box-shadow: 0px 1px 11.2px 0px rgba(78, 91, 112, 0.43);
        z-index: 2;
      }
    }
    canvas {
      pointer-events: none;
    }
    span:not(.MuiTouchRipple-root) {
      pointer-events: all !important;
      padding: 0;
      height: 100%;
      width: 100%;
      i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &.with-dropdown {
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 30px;
      border-left: 1px solid #fff;
      margin-right: -15px;
      margin-left: 15px;
      transition: 0.2s;
      img {
        margin-right: 0;
        pointer-events: none;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

button.normal {
  background-repeat: no-repeat;
  background-position: 23px center;
  background-color: #fff;
  padding: 10px 15px;
  box-shadow: 0 0 5px #eee;
  color: #0e61dd;
  box-shadow: 0 0 20px rgb(224, 224, 224);
}

button.add {
  position: relative;
  background: linear-gradient(269.83deg, #2a9395 -1.16%, #115578 101.78%);
  color: #fff;
  padding-left: 38px;
  padding-right: 15px;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 16px / 2);
    left: 13px;
    width: 16px;
    height: 16px;
    background-image: url("../img/app/icons/add-white.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &::before {
    background-size: 15px;
  }
  &.income {
    background: linear-gradient(269.83deg, #75bb73 -1.16%, #4c9f67 101.78%);
    &::before {
      background-image: url("../img/dashboard/icons/income.png");
    }
  }
  &.expense {
    background: linear-gradient(269.83deg, #e86464 -1.16%, #e83d4b 101.78%);
    &::before {
      background-image: url("../img/dashboard/icons/expense.png");
    }
  }
}

button.add.small {
  padding: 0;
  &::before {
    left: calc(50% - 16px / 2);
  }
}

button.accounting {
  position: relative;
  background-color: #a5c5ec;
  color: #fff;
  padding-left: 40px;
  margin: 0 10px;
  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 20px / 2);
    left: 13px;
    width: 20px;
    height: 20px;
    background-image: url("../img/app/icons/accounting.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    background-color: #a5bedc;
  }
}

button.export {
  background-color: #f4f4f5;
  border: 1px solid #d2dbe4;
  height: 50px;
  span:not(.MuiTouchRipple-root) {
    pointer-events: all !important;
  }
  .content {
    line-height: 1.2;
    color: #454653;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: none;
    position: relative;
    padding-left: 35px;
    b {
      font-family: "Rubik Medium", sans-serif;
      font-weight: normal;
      font-size: 15px;
      color: inherit;
    }
    span {
      color: inherit;
      font-family: "Rubik Light", sans-serif;
      font-size: 13px;
    }
    &::before {
      content: "";
      width: 20px;
      height: 100%;
      position: absolute;
      left: 5px;
      background-image: url("../img/app/icons/export-colored.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  i {
    border-left: 1px solid #d2dbe4;
    width: 40px;
    height: 50px;
    position: relative;
    z-index: 2;
    img {
      margin-right: 5px;
      filter: invert(26%) sepia(29%) saturate(268%) hue-rotate(198deg)
        brightness(47%) contrast(88%);
    }
  }
  &.active {
    background-color: #7a8b9f;
    .content {
      color: #fff;
    }
    i {
      border-left-color: #fff;
      img {
        filter: initial;
      }
    }
  }
  &.export-small {
    height: 25px;
    padding: 6px 5px;
    .content {
      padding-left: 30px;
    }
    i {
      height: 25px;
      width: 34px;
      img {
        margin-right: 8px;
      }
    }
  }
}
.additinal-options-menu,
.additinal-pagination-menu {
  .MuiMenu-list {
    // border: 1px solid #D4DDE4;
    .MuiListItem-root {
      padding: 10px;
      &.print {
        padding: 0;
        .print-button-layout {
          padding: 10px;
          background-color: transparent;
        }
      }
    }
    img {
      margin-right: 10px;
      width: 25px;
    }
  }
}

.additinal-pagination-menu {
  .MuiMenu-paper {
    border: 1px solid #e6eef4;
    max-width: 175px;
    .MuiList-root {
      padding-bottom: 0;
      h3 {
        padding: 10px;
        font-weight: 700;
        font-family: "Roboto SemiBold", sans-serif;
        color: $primaryHeadingColor;
        font-size: 15px;
      }
      li {
        img {
          position: absolute;
          right: 0;
          object-fit: scale-down;
          margin-right: 0;
        }
      }
      .bottom {
        padding: 10px;
        margin-top: 10px;
        border-top: 1px solid #e6eef4;
        width: 100%;
        display: flex;
        align-items: center;
        input {
          border: 1px solid #e6eef4;
          border-radius: 3px;
          height: 30px;
          width: 100%;
          padding: 0 5px;
          padding-right: 65px;
          &::placeholder {
            color: #b4bfca;
          }
        }
        button {
          height: 24px;
          position: absolute;
          right: 13px;
          background-color: #a7bad1;
          border-radius: 3px;
          width: 57px;
          padding: 0 2px;
          min-width: initial;
          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.additional-info-tooltip {
  padding: 0;
  .head {
    background-color: #d4dde4;
    display: block;
    padding: 10px;
    color: $primaryHeadingColor;
    font-size: 13px;
    font-family: "Roboto Medium", sans-serif;
    font-weight: normal;
    width: fit-content;
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -8px;
    width: calc(100% + 16px);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .body {
    background-color: #fff;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: -8px;
    width: calc(100% + 16px);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #d4dde4;
    padding: 10px;
    li {
      color: #7a8b9f;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      span {
        color: $primaryHeadingColor;
        width: 50%;
        display: block;
        padding-left: 5px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

button.config {
  position: relative;
  background-color: transparent;
  color: #fff;
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  &:hover {
    background-color: rgb(240, 240, 240);
  }
  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 20px / 2);
    left: calc(50% - 20px / 2);
    width: 20px;
    height: 20px;
    background-image: url("../img/app/icons/settings.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

button.remove {
  background-color: #ff426e;
  color: #fff;
  font-size: 15px;
  width: fit-content;
  padding: 0 20px;
  padding-left: 40px;
  background-image: url("../img/app/icons/delete-white.png");
  background-repeat: no-repeat;
  background-position: 15px center;
  &:hover {
    background-color: #e03860;
  }
}

button.options.small {
  background-color: #fff;
  background-image: url("../img/app/icons/options.png");
  background-repeat: no-repeat;
  background-position: center;
  // background-size: 16px;
  min-width: initial;
  padding: 20px;
  &:hover {
    background-color: #d0e8ff;
  }
}

button.remove.small {
  background-color: #fff;
  background-image: url("../img/app/icons/delete.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  min-width: initial;
  padding: 20px;
  &:hover {
    background-color: #ffc6d3;
  }
}

button.print.small {
  background-image: url("../img/app/icons/print.png");
  background-size: 16px;
  padding: 0;
}

button.link.small {
  background-image: url("../img/app/icons/link.png");
  background-size: 16px;
  padding: 0;
}

button.send.small {
  background-position: center;
  background-size: 16px;
}

button.make-paid {
  background-image: url("../img/app/icons/paid.png");
  background-repeat: no-repeat;
  background-position: 30px center;
  background-color: #23c66d;
  padding: 10px 50px;
  padding-right: 35px;
  color: #fff;
  font-weight: 500;
  &:hover {
    background-color: #12ce69;
  }
}

button.send {
  background-image: url("../img/app/icons/mail.png");
  padding: 10px 55px;
  padding-right: 30px;
}

button.edit {
  background-image: url("../img/app/icons/edit-colored.png");
  padding: 10px 45px;
  padding-right: 30px;
}

button.download {
  background-image: url("../img/app/icons/download.png");
  padding: 10px 55px;
  padding-right: 30px;
}

button.print {
  background-image: url("../img/app/icons/print.png");
  padding: 10px 55px;
  padding-right: 30px;
}

button.change-password {
  background-image: url("../img/app/icons/change-password.png");
  padding: 10px 50px !important;
  padding-right: 30px !important;
  background-repeat: no-repeat;
}

button.link {
  background-image: url("../img/app/icons/link.png");
  padding: 10px 55px;
  padding-right: 30px;
  text-decoration: none !important;
}

button.cancel,
button.save {
  padding: 10px 45px;
  text-transform: initial;
  font-weight: 500;
}

button.cancel {
  background-color: #fff;
  color: $primaryHeadingColor;
  border: 1px solid #aeb7c9;
  &:hover {
    background-color: #fafafa;
  }
}

button.save,
button.save.small {
  background-color: #0e61dd;
  color: #fff;
  &.with-dropdown {
    i {
      margin-right: -44px;
      margin-left: 44px;
    }
  }
  &:hover {
    background-color: #257cf5;
  }
}

button.medium {
  padding: 0 10px;
}

button.ready {
  background-color: #23c66d;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  background-image: url("../img/app/icons/check-white.png");
  padding-left: 35px;
  &:hover {
    background-color: #21c069;
  }
}

button.send-mail {
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  background-image: url("../img/documents/icons/send.png");
  padding-left: 35px;
  padding-right: 15px;
}

button.small {
  width: 40px;
  height: 40px;
  padding: 5px;
  box-shadow: 0 0 5px #eee;
  background-color: #fff;
  min-width: 40px;
}

.MuiTab-wrapper {
  color: #aeb7c9;
  font-family: "SourceSansPro SemiBold", sans-serif;
}
.MuiTab-root {
  min-width: 120px !important;
  &.Mui-selected {
    .MuiTab-wrapper {
      color: $primaryHeadingColor;
    }
  }
}

.link {
  color: #0e61dd;
  cursor: pointer;
  font-size: 15px;
  box-shadow: initial !important;
  &:hover {
    text-decoration: underline;
  }
}

.copy {
  align-items: center;
  input {
    height: 40px;
    border: none;
    background-color: #fff;
    padding: 0 5px;
    font-size: 15px;
    min-width: 200px;
    border: 1px solid #dde7f2;
    border-radius: 3px;
  }
  button {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    min-width: 30px;
    margin-left: 10px;
    background-color: #0e61dd;
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 16px / 2);
      left: calc(50% - 15px / 2);
      width: 16px;
      height: 16px;
      background-image: url("../img/app/icons/copy.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      background-color: #257cf6;
    }
  }
}

.uploaded-files {
  margin-bottom: 10px;
  width: 100%;
  li {
    margin-bottom: 5px;
    width: 100%;
    padding: 5px;
    align-items: center;
    a {
      font-size: 15px;
      color: #0e61dd;
      margin-right: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
    div {
      margin-left: auto;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:nth-child(even) {
      background-color: #f1f1f1;
    }
  }
}
.upload {
  padding: 10px;
  border-radius: 5px;
  border: 1px dashed #b4bfca;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    // margin-bottom: 5px;
  }
  h6,
  h5 {
    font-size: 18px;
    color: $primaryHeadingColor;
    text-align: center;
    font-weight: normal;
  }
  p {
    color: #b4bfca !important;
    font-size: 13px !important;
    text-align: center;
  }
  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.MuiInputBase-root {
  .MuiSvgIcon-root {
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}

.MuiTooltip-tooltip {
  background-color: #576a81 !important;
  font-size: 11px !important;
}

.customer-input {
  width: 100%;
  position: relative;
  .direct-add {
    position: absolute;
    right: 4px;
    top: 4px;
    button {
      margin-top: 0 !important;
      height: 100%;
      width: 10px;
      padding: 0;
      width: 42px;
      min-width: inherit;
      height: 42px;
      border-radius: 4px;
      span {
        font-size: 0;
      }
    }
  }
  &.with-direct-add {
    input {
      padding-right: 85px;
    }
    .direct-add {
      button::after {
        content: "";
        background-image: url("../img/documents/icons/enter.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        top: calc(50% - 20px / 2);
        right: calc(100% + 10px);
        -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
        animation: heartbeat 1.5s ease-in-out infinite both;
      }
    }
    @-webkit-keyframes heartbeat {
      from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }
    @keyframes heartbeat {
      from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }
  }
  .eu,
  img {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
  }

  .eu {
    background-image: url("../img/app/icons/eu.png");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    // top: 60px;
    left: 0;
    width: 800px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px #ccc;
    padding: 20px 15px;
    z-index: 10;
    max-height: 480px;
    overflow-y: auto;
    display: none;
    &.show {
      display: block;
    }
    &.half {
      width: 400px;
    }
    .row {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .col {
        width: 100%;
        margin-right: 30px;
        flex: 1 !important;
        &:last-child {
          margin-right: 0;
        }

        .heading {
          padding: 0 5px;
          margin-bottom: 10px;
        }

        .ul {
          max-height: 150px;
          overflow-y: auto;
          padding-right: 10px;

          ul {
            li {
              font-size: 14px;
              padding: 6px 5px;
              padding-right: 20px;
              margin-bottom: 2px;
              border-radius: 4px;
              cursor: pointer;
              position: relative;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover,
              &.selected {
                background-color: #e0edf6;
              }

              .arrow {
                display: none;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translate(0, -50%);
              }

              &:hover {
                .arrow {
                  display: block;
                }
              }
            }
          }
        }
      }

      .col-2 {
        width: calc(100% / 2 - 30px);
        &:nth-child(2) {
          margin-right: 0;
          width: calc(100% / 2);
        }
      }
    }

    .divider {
      border: 1px solid #eee;
      margin: 15px 0;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dde6ed;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #e0edf6;
    }
  }
}

.company-field-container {
  position: relative;
  img.check {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  .found-companies-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 800px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px #ccc;
    padding: 20px 15px;
    z-index: 10;
    max-height: 202px;
    overflow-y: auto;
    display: none;
    &.centered-label {
      left: 50%;
      transform: translateX(-50%);
    }
    &.show {
      display: block;
    }
    &.small {
      width: 400px;
    }
    .row {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      max-height: 160px;
      .col {
        width: 100%;
        margin-right: 30px;
        flex: 1 !important;
        &:last-child {
          margin-right: 0;
        }

        .heading {
          padding: 0 5px;
          margin-bottom: 10px;
        }

        ul {
          max-height: 150px;
          overflow-y: auto;
          padding-right: 10px;

          li {
            font-size: 14px;
            padding: 6px 5px;
            padding-right: 20px;
            margin-bottom: 2px;
            border-radius: 4px;
            cursor: pointer;
            position: relative;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover,
            &.selected {
              background-color: #e0edf6;
            }

            .arrow {
              display: none;
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translate(0, -50%);
            }

            &:hover {
              .arrow {
                display: block;
              }
            }
          }
        }
      }

      .col-2 {
        width: calc(100% / 2 - 30px);
        &:nth-child(2) {
          margin-right: 0;
          width: calc(100% / 2);
        }
      }
    }

    .divider {
      border: 1px solid #eee;
      margin: 15px 0;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dde6ed;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #e0edf6;
    }
  }
  .with-chech {
    input {
      padding-right: 35px;
    }
    &.centered-label {
      input {
        padding-left: 35px;
      }
    }
  }
  .centered-label {
    .MuiInputLabel-filled.MuiInputLabel-marginDense {
      transform: translate(-50%, 17px) scale(1);
      transition: 0.2s;
      left: 50%;
      transform-origin: center;
    }
    .MuiInputLabel-shrink {
      transform: translate(-50%, 7px) scale(0.75) !important;
      text-align: center;
      left: 50%;
    }
    input {
      text-align: center;
    }
  }
}

.MuiFormControl-root {
  .error-info {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    background-size: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
  }
}

.MuiFilledInput-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

.MuiFormControl-marginDense {
  margin: 0 !important;
}

.MuiInputBase-input.Mui-disabled {
  cursor: default;
}

.multiple-actions {
  padding: 10px;
  border-radius: 8px;
  background-color: #62666d;
  position: fixed;
  z-index: 8;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  color: #aeb7c9;
  animation: show-multiple-actions 0.3s ease-in-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "SourceSansPro SemiBold", sans-serif;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
    min-height: 50px;
    .selected-count {
      font-family: "SourceSansPro SemiBold", sans-serif;
      span {
        background-color: #767a83;
        padding: 10px;
        border-radius: 3px;
        font-family: "SourceSansPro SemiBold", sans-serif;
        margin-left: 10px;
      }
    }
    button {
      background-color: #a7bad1;
      border-radius: 3px;
      span {
        color: #fff;
        font-family: "SourceSansPro SemiBold", sans-serif;
        i {
          padding: 2px 5px;
          border-radius: 3px;
          background-color: #b9cce4;
          font-family: "SourceSansPro SemiBold", sans-serif;
          font-style: normal;
          margin-left: 10px;
        }
      }
    }
  }
  ul {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #989898;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    li {
      margin-right: 5px;
      button {
        border-radius: 50%;
        padding: 10px;
        line-height: 1;
        border-radius: 6px;
        height: auto;
        span {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;
          font-family: "SourceSansPro Regular", sans-serif;
          font-weight: normal;
          img {
            max-width: 50px;
            height: 25px;
            object-fit: scale-down;
            object-position: center;
            margin-bottom: 3px;
          }
        }
        &:hover {
          background-color: #767a83;
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@keyframes show-multiple-actions {
  0% {
    opacity: 0;
    bottom: 0;
  }
  100% {
    opacity: 1;
    bottom: 25px;
  }
}

.test {
  * {
    font-family: "Arial";
  }

  @page {
    margin: 30px 30px;
  }

  body {
    font-size: 11px;
    font-family: "Arial";
    font-weight: 700;
  }

  h1 {
    text-align: center;
  }

  .table {
    border: 1px solid #777;
    border-collapse: collapse;
  }

  .br {
    border-right: 1px solid #777;
  }

  .head {
    margin-top: 20px;
  }

  .head td {
    padding: 1px 4px;
    border-bottom: 1px solid #777;
  }

  .head td:nth-child(1) {
    width: 20%;
    text-align: right;
    border-right: 1px solid #777;
  }

  .head td:nth-child(2) {
    font-weight: bold;
    text-align: left;
  }

  h1 {
    font-weight: bold;
    letter-spacing: 6px;
    margin: 5px auto 0 auto;
    font-size: 22px;
  }

  h2 {
    font-weight: 600;
    letter-spacing: 6px;
    margin: 0 auto;
    font-size: 22px;
    text-align: center;
  }

  h3 {
    font-weight: bold;
    letter-spacing: 2px;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
  }

  .body {
    margin-top: 5px;
  }

  .body th {
    font-weight: bold;
    text-align: left;
  }

  .body th,
  .body td {
    border-right: 1px solid #777;
    border-bottom: 1px solid #777;
    padding: 1px 4px;
  }

  .body th:nth-child(1),
  .body td:nth-child(1) {
    width: 5%;
  }

  .body th:nth-child(2),
  .body td:nth-child(2) {
    width: 55%;
  }

  .body th:nth-child(3),
  .body td:nth-child(3) {
    width: 5%;
    text-align: center;
  }

  .body th:nth-child(4),
  .body td:nth-child(4) {
    width: 5%;
    text-align: right;
  }

  .body th:nth-child(5),
  .body td:nth-child(5) {
    width: 15%;
  }

  .body th:nth-child(6),
  .body td:nth-child(6) {
    width: 15%;
  }

  .body th:nth-child(7),
  .body td:nth-child(7) {
    width: 20%;
  }

  .bottom {
    margin-top: 10px;
  }

  .bottom td {
    border-right: 1px solid #777;
    border-bottom: 1px solid #777;
    padding: 1px 4px;
  }

  .bottom td:nth-child(1) {
    width: 40%;
  }

  .bottom td:nth-child(2) {
    width: 20%;
  }

  .bottom td:nth-child(3) {
    width: 40%;
  }
}

.new-bill,
.container-fluid,
.store-loads,
.store-moves,
.store-out-modal {
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-right: -25px;
  margin-top: -25px;
  margin-bottom: 0;
  .client {
    max-width: 400px;
    .panel {
      padding: 10px 20px;
      .initial-client {
        font-size: 13px;
        color: $primaryParagraphColor;
        align-items: center;
        max-width: 310px;
        margin: auto;
      }
    }
    &.fields-shown {
      .panel {
        padding: 10px;
        > .col {
          width: 100%;
          > .row {
            align-items: center;
            padding: 0 5px;
            margin-bottom: 0;
            .MuiInputBase-root {
              padding: 0;
              background-color: transparent;
              height: 27px;
              input {
                padding: 1px 5px;
                border: none;
                height: 100%;
                font-size: 13px;
              }
              &,
              fieldset {
                border: 1px solid transparent;
                box-shadow: none;
              }
              &.Mui-focused,
              &:hover {
                // border: 1px solid lightgray;
              }
            }
            .col {
              font-size: 13px;
              &:nth-child(odd) {
                color: $primaryParagraphColor;
                margin-right: 0;
                flex: 0.12;
              }
              &:nth-child(even) {
                color: $primaryHeadingColor;
                flex: 0.85;
              }
            }
            &.selected {
              background-color: #e4f2ff !important;
              .col {
                color: #000;
              }
            }

            &.double {
              div:nth-child(1)
                > div
                > div:nth-child(2)
                > div
                > div.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense.MuiFormControl-fullWidth
                > div {
                padding-right: 7px;
              }
              .MuiFormControl-root .error-info {
                right: 4px;
              }
              > .col {
                flex: 1;
                .row {
                  .col {
                    &:nth-child(odd) {
                      flex: 0.8 !important;
                      margin-right: 0;
                    }
                    &:nth-child(even) {
                      flex: 0.58 !important;
                      max-width: 196px;
                      input {
                        width: 70px;
                        padding-left: 0;
                      }
                    }
                  }
                  .eu,
                  img {
                    right: inherit;
                    left: calc(100% + 5px);
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                  }
                }
                &:nth-child(1) {
                  margin-right: 0;
                  flex: 0.8;
                  .col:not(.companies-col) {
                    &:nth-child(1) {
                      flex: 0.32 !important;
                      min-width: 38px;
                      justify-content: center;
                    }
                    &:nth-child(2) {
                      // border-right: 1px solid $primaryParagraphColor;
                      // padding-right: 10px;
                      padding-right: 0;
                      margin-left: -5px;
                      position: relative;
                      &::after {
                        content: "";
                        right: 0;
                        width: 1px;
                        height: calc(100% - 10px);
                        background-color: $primaryParagraphColor;
                        position: absolute;
                        top: 5px;
                      }
                    }
                  }
                }
                &:nth-child(2) {
                  .col {
                    justify-content: center;
                    &:nth-child(1) {
                      flex: initial !important;
                      width: calc(100% - 78px);
                      margin-right: 0;
                      margin-left: -10px;
                    }
                    &:nth-child(2) {
                      width: calc(100% + 5px);
                      flex: initial !important;
                      .customer-input {
                        width: calc(100% - 35px);
                      }
                    }
                  }
                }
              }
            }
            &:nth-child(even) {
              background-color: #f8f8f8;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
